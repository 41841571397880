import React, { useEffect } from 'react';
import { useSegment } from 'react-segment-hooks';
import { useParams } from 'react-router-dom';

import { nanoid } from 'nanoid';
import { useHistory } from 'react-router';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Well from '../Components/Well';
import InsightsCardView from '../Components/InsightsCardView';
import Loader from '../Components/Loader';
import Button from '../Components/Button';
import { useFetchInsights, useCreateInsight } from '../Hooks/useInsights';
import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyStateText,
  EmptyState,
  EmptyStateContainer,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import insightsEmptyImage from '../assets/empty-states/insights.png';

export default function Insights(): JSX.Element {
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const analytics = useSegment();
  const history = useHistory();

  useEffect(() => {
    analytics.page({
      name: 'Insights',
      properties: {
        projectId: dashboardId,
      },
    });
  }, []);

  const { loading, data } = useFetchInsights(dashboardId);

  const [createInsightMutation] = useCreateInsight();

  async function handleCreate() {
    const clientId = nanoid(10);
    await createInsightMutation(dashboardId, clientId);
    history.push(`/projects/${dashboardId}/insights/${clientId}`);
  }

  return (
    <div className={'flex-col h-full'}>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>Insights</h1>
          </div>
          <Button onClick={handleCreate} type={'primary'}>
            New Insight
          </Button>
        </div>
      </SecondaryToolbar>
      {!data?.length && (
        <EmptyStateContainer>
          <EmptyState>
            <HeroImage src={insightsEmptyImage}></HeroImage>
            <EmptyStateTitle>Create insights to share findings</EmptyStateTitle>
            <EmptyStateText>
              Insights are a space to document emerging insights and share research findings.
            </EmptyStateText>
            <EmptyStateButton onClick={handleCreate}>
              <EmptyStatePlusIcon />
              New insight
            </EmptyStateButton>
          </EmptyState>
        </EmptyStateContainer>
      )}
      {Boolean(data?.length) && (
        <div className={'flex flex-auto justify-center'}>
          <div className={'flex-col h-full w-full pb-20'}>
            <Well wellKey="insights-well">
              Insights are a simple place to draft your research findings. Use insights to answer
              “what does it mean?” and “why does it matter?”
            </Well>
            {loading && <Loader />}
            {!loading && (
              <InsightsCardView insights={data} baseUrl={`/projects/${dashboardId}/insights`} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
