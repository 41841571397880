import React, { PropsWithChildren, ReactText, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { DefaultLayout } from './Layouts';
import { Home } from './Pages';
import {
  withAuthenticationRequired,
  useAuth0,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import { Loader, Modal, UpdateBoard } from './Components';
import { useIntercom } from 'react-use-intercom';
import Notes from './Pages/Notes';
import Zoom from './Pages/Zoom';
import { toast } from 'react-toastify';
import HelpIcon from './Icons/HelpIcon';
import Transcript from './Pages/Transcript';
import SidebarLayout from './Layouts/SidebarLayout';
import NotFoundPage from './Pages/NotFoundPage';
import Insights from './Pages/Insights';
import Insight from './Pages/Insight';
import Info from './Pages/Info';
import CopyBoard from './Components/CopyBoard';
import Data from './Pages/Data';
import Participants from './Pages/Participants';
import Participant from './Pages/Participant';
import { useSegment } from 'react-segment-hooks';
import * as FullStory from '@fullstory/browser';
import Onboarding from './Pages/Onboarding';
import { useHistory } from 'react-router';
import Integrations from './Pages/Integrations';
import Document from './Pages/Document';
import Paywall from './Pages/Paywall';
import Users from './Pages/Users';
import SettingsLayout from './Layouts/SettingsLayout';
import GeneralSettings from './Pages/GeneralSettings';
import ProfileSettings from './Pages/ProfileSettings';

function RequireOnboarding({ children }: PropsWithChildren<any>) {
  const { user } = useAuth0();
  const history = useHistory();
  const onboardingStatus = localStorage.getItem('onboardingStatus');

  const onboarded =
    user &&
    user['https://notably.ai/claims/user_id'] &&
    user['https://notably.ai/claims/org_id'] &&
    user['https://notably.ai/claims/user_invited'] !== true &&
    !onboardingStatus;

  if (!onboarded) {
    if (onboardingStatus === 'step_1_complete') {
      localStorage.setItem('onboardingStatus', 'pending_step_2');
    }
    history.push('/onboarding');
  }

  return children;
}

function getQueryParams() {
  const { search } = window.location;

  return new URLSearchParams(search);
}

function App(): JSX.Element {
  const { isLoading, user } = useAuth0();
  const { boot } = useIntercom();
  const analytics = useSegment();

  useEffect(() => {
    if (user) {
      let company;

      const userId = user['https://notably.ai/claims/user_id'] || user.id || user.user_id;

      // drop intercom init after tested segment
      if (user['https://notably.ai/claims/org_id']) {
        company = {
          companyId: user['https://notably.ai/claims/org_id'],
          name: user.email?.split('@')[1],
        };
      }

      const intercomUser = {
        hideDefaultLauncher: true,
        customLauncherSelector: '#intercomIcon',
        userId,
        name: user.name,
        email: user.email,
        avatar: {
          type: 'avatar',
          imageUrl: user.picture,
        },
        company,
      };

      boot(intercomUser);

      analytics.identify({
        userId,
        traits: {
          name: user.name,
          email: user.email,
          avatar: {
            type: 'avatar',
            imageUrl: user.picture,
          },
          company,
        },
      });

      FullStory.identify(userId, {
        displayName: user.name,
        email: user.email,
      });
    }
  }, [user]);

  const [offlineToast, setOfflineToast] = useState<ReactText>('');

  const setOnline = () => {
    toast.dismiss(offlineToast);
    toast.success('Notably is back online!', {
      autoClose: 5000,
    });
  };
  const setOffline = () => {
    setOfflineToast(
      toast.warning(
        "We're sorry. Notably is currently offline. Any changes made will not be saved. Please refresh to try again soon or reach out to us if this problem continues."
      )
    );
  };

  // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <a className="fixed z-100 right-3.5 bottom-3.5" id="intercomIcon" href="#">
        <HelpIcon />
      </a>
      <Switch>
        <Route exact path="/zoom" component={Zoom} />
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/onboarding" component={Onboarding} />
        <Route>
          <RequireOnboarding>
            <DefaultLayout>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/participants" component={Participants} />
                <Route exact path="/participants/:participantId([0-9]+)" component={Participant} />
                <Route
                  path="/projects/:dashboardId/edit"
                  render={(props) => (
                    <Modal
                      title={'Update the board'}
                      isOpen={true}
                      style={{ overlay: { zIndex: 1000 } }}
                    >
                      <UpdateBoard {...props.match.params} />
                    </Modal>
                  )}
                />
                <Route
                  path="/projects/:dashboardId/copy"
                  render={(props) => (
                    <Modal
                      title={'Copy Project'}
                      isOpen={true}
                      style={{ overlay: { zIndex: 1000 } }}
                    >
                      <CopyBoard {...props.match.params} />
                    </Modal>
                  )}
                />
                <Route path="/projects/:dashboardId/notes" component={Notes} />
                <Route path="/projects/:dashboardId">
                  <SidebarLayout>
                    <Switch>
                      <Route
                        exact
                        path="/projects/:dashboardId/data/docs/:documentId([0-9]+)"
                        component={Document}
                      />
                      <Route
                        exact
                        path="/projects/:dashboardId/data/:transcriptId([0-9]+)"
                        component={Transcript}
                      />
                      <Route path="/projects/:dashboardId/data" component={Data} />
                      <Route exact path="/projects/:dashboardId/insights" component={Insights} />
                      <Route
                        exact
                        path="/projects/:dashboardId/insights/:clientId"
                        component={Insight}
                      />
                      <Route exact path="/projects/:dashboardId/info" component={Info} />
                      <Redirect to="/404" />
                    </Switch>
                  </SidebarLayout>
                </Route>
                <Route path="/settings">
                  <SettingsLayout>
                    <Switch>
                      <Route exact path="/settings" component={Integrations} />
                      <Route exact path="/settings/integrations" component={Integrations} />
                      <Route exact path="/settings/users" component={Users} />
                      <Route exact path="/settings/plans" component={Paywall} />
                      <Route exact path="/settings/profile" component={ProfileSettings} />
                      <Route exact path="/settings/general" component={GeneralSettings} />
                      <Route exact path="/plans" component={Paywall} />
                    </Switch>
                  </SettingsLayout>
                </Route>
                <Redirect from="/signup" to="/" />
                <Redirect to="/404" />
              </Switch>
            </DefaultLayout>
          </RequireOnboarding>
        </Route>
        <Redirect to="/404" />
      </Switch>
    </>
  );
}

const isSignup = window.location.href.indexOf('/signup') !== -1;
const query = getQueryParams();
const email = query.get('email');
const redirectUrl =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '');

const authOptions: WithAuthenticationRequiredOptions = {
  loginOptions: {
    screen_hint: isSignup ? 'signup' : 'login',
    redirect_uri: isSignup ? redirectUrl + '/onboarding' : redirectUrl,
  },
};

if (email && authOptions.loginOptions) {
  authOptions.loginOptions.login_hint = email;
}

export default withAuthenticationRequired(App, authOptions);
