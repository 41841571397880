import React, { PropsWithChildren } from 'react';

const sizeMap = {
  xs:
    'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2 ',
  sm:
    'inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2 ',
  md:
    'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2 ',
  lg:
    'inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2 ',
  xl:
    'inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2',
};

const typeMap = {
  primary: 'bg-secondary-purple-dark focus:ring-secondary-purple-dark text-white',
  secondary: 'bg-white text-secondary-purple-dark focus:ring-secondary-purple-dark border-gray-200',
};

const activeClasses = 'outline-none ring-2 ring-offset-2 opacity-75 ring-secondary-purple-dark';

type ButtonProps = {
  size?: 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  icon?: JSX.Element;
  className?: string;
  disabled?: boolean;
  type?: 'primary' | 'secondary';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
};

export default function Button({
  icon,
  size = 'sm',
  type = 'primary',
  children,
  className,
  disabled = false,
  active = false,
  onClick,
  onMouseDown,
}: PropsWithChildren<ButtonProps>): JSX.Element {
  return (
    <button
      type={'submit'}
      disabled={disabled}
      className={[sizeMap[size], className, typeMap[type], active ? activeClasses : '']
        .filter(Boolean)
        .join(' ')}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {icon}
      {children}
    </button>
  );
}
