import React from 'react';
import ReactModal from 'react-modal';
import { XIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router';

ReactModal.setAppElement('#root');

ReactModal.defaultStyles.content = {
  outline: 'none',
  border: '0',
  borderRadius: '4px',
  bottom: 'auto',
  minHeight: '10rem',
  left: '50%',
  padding: '2rem',
  position: 'fixed',
  right: 'auto',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  minWidth: '20rem',
  width: '80%',
  maxWidth: '60rem',
};

ReactModal.defaultStyles.overlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(26, 12, 41, 0.40)',
  zIndex: 100,
};

interface Props extends ReactModal.Props {
  title: string;
  onClose?: () => void;
}

export default function Modal({
  title,
  children,
  onClose,
  ...rest
}: React.PropsWithChildren<Props>): JSX.Element {
  const history = useHistory();

  if (onClose === undefined) {
    onClose = () => history.goBack();
  }
  return (
    <ReactModal onRequestClose={onClose} {...rest}>
      <div className="bg-white shadow-md rounded px-5 pt-5 pb-5">
        <div className="font-body flex flex-row font-semibold text-lg pb-3 justify-between">
          <div>{title}</div>
          <div>
            <button onClick={() => onClose?.()}>
              <XIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        {children}
      </div>
    </ReactModal>
  );
}
