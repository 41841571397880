import { useQuery, gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { CURRENT_ORG } from '../GraphQL/queries';
import { UPDATE_ORGANIZATION } from '../GraphQL/mutations';

export function checkOrganizationLimits(
  org: any,
  history: any,
  whatToCheck = ['projects', 'storage', 'duration']
) {
  const {
    id,
    projectsLimitExceeded,
    durationLimitExceeded,
    fileLimitExceeded,
    projectsLimitTotal,
    projectsCount,
  } = org;
  if (id == 1) {
    return true;
  }
  if (
    whatToCheck.includes('projects') &&
    (projectsLimitExceeded || parseInt(projectsLimitTotal) == parseInt(projectsCount))
  ) {
    toast.info("You've reached the limit of projects on your current plan. Please upgrade");
    history.push('/settings/plans');
    return false;
  }

  if (whatToCheck.includes('duration') && durationLimitExceeded) {
    toast.info(
      "You've reached the limit of transcription hours on your current plan. Please upgrade."
    );
    history.push('/settings/plans');
    return false;
  }

  if (whatToCheck.includes('storage') && fileLimitExceeded) {
    toast.info("You've reached the storage limit on your current plan. Please upgrade.");
    history.push('/settings/plans');
    return false;
  }

  return true;
}

export function useOrganization() {
  const { data, loading } = useQuery(CURRENT_ORG);

  return [loading, data?.currentOrganization];
}

export const useUpdateOrganization = (): [
  (input: { logo?: string | null; name?: string | null }) => Promise<any>
] => {
  const [updateMutation] = useMutation(UPDATE_ORGANIZATION);

  return [
    async (input: { logo?: string | null; name?: string | null }) => {
      return updateMutation({
        variables: {
          input: { ...input },
        },
        refetchQueries: [{ query: CURRENT_ORG }],
      });
    },
  ];
};
