import React, { ChangeEvent, useState } from 'react';
import { FilterIcon, SearchIcon, EyeIcon, ArrowLeftIcon } from '@heroicons/react/outline';
import { DropDownMenu } from './DropDownMenu';
import { Menu } from '@headlessui/react';
import MultiSelect, { OptionType } from './MultiSelect';
import { Theme } from '../Models';
import SecondaryToolbar from './SecondaryToolbar';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ChevronDownIcon } from '@heroicons/react/solid';
interface Props {
  tags: string[];
  themes: Theme[];
  participants: { id: any; name: string }[];
  dashboardId: string;
  onSearchChange?: (query: string) => void;
  onTagChange?: (tags: string[]) => void;
  onThemeChange?: (themes: number[]) => void;
  onGroupByChange?: (value: string[]) => void;
  onParticipantChange?: (participants: any[]) => void;
}

const multiselectProps = {
  components: {},
  isCreatable: false,
};

const StyledChevronIcon = styled(ChevronDownIcon)`
  height: 12px;
`;

const DropDownTitle = styled.div`
  margin: 0 4px;
`;

const Capitalized = styled.span`
  text-transform: capitalize;
`;

export default function SearchToolBar({
  dashboardId,
  onSearchChange,
  onThemeChange,
  onTagChange,
  onParticipantChange,
  onGroupByChange,
  tags,
  themes,
  participants,
}: Props): JSX.Element {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onSearchChange) {
      onSearchChange(event.target.value);
    }
  };

  const handleThemeChange = (values: OptionType[]) => {
    setSelectedThemes(values);
    if (onThemeChange) {
      onThemeChange(values.map((x) => parseInt(x.value)));
    }
  };

  const handleTagChange = (values: OptionType[]) => {
    setSelectedTags(values);
    if (onTagChange) {
      onTagChange(values.map((x) => x.value));
    }
  };

  const handleParticipantChange = (values: OptionType[]) => {
    setSelectedParticipants(values);
    if (onParticipantChange) {
      onParticipantChange(values.map((x) => x.value));
    }
  };

  const handleGroupBy = (value: string[]) => {
    setSelectedGroupBy(value);
    if (onGroupByChange) {
      onGroupByChange(value);
    }
  };

  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
  const [selectedGroupBy, setSelectedGroupBy] = useState<string[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<OptionType[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<OptionType[]>([]);

  return (
    <SecondaryToolbar>
      <div className={'flex w-full justify-between'}>
        <div className={'w-30 p-3'}>
          <Link to={'/projects/' + dashboardId + '/info'}>
            <ArrowLeftIcon className={'w-6 h-6'} />
          </Link>
        </div>
        <div className={'flex'}>
          <DropDownMenu
            button={
              <Menu.Button className="-ml-px bg-white relative inline-flex items-center px-3 py-3 text-sm font-medium text-secondary-purple-dark hover:cursor-default rounded-sm-md focus:outline-none">
                <FilterIcon className="h-5 w-5 text-secondary-purple-dark" aria-hidden="true" />
                <DropDownTitle>Filter</DropDownTitle>
                <StyledChevronIcon />
              </Menu.Button>
            }
          >
            <Menu.Item>
              <MultiSelect
                {...multiselectProps}
                contrastPlaceholder
                noOptionsMessage={() => 'No themes'}
                placeholder={'All themes'}
                onChange={(value) => handleThemeChange(value as OptionType[])}
                value={selectedThemes}
                options={themes.map((x: Theme) => ({
                  value: x.id,
                  color: x.color,
                  label: `${x.name} (${x.notes?.length ?? 0})`,
                }))}
              />
            </Menu.Item>
            <Menu.Item>
              <MultiSelect
                {...multiselectProps}
                contrastPlaceholder
                placeholder={'All tags'}
                noOptionsMessage={() => 'No tags'}
                onChange={(value) => handleTagChange(value as OptionType[])}
                value={selectedTags}
                options={tags.map((x) => ({ value: x, label: x }))}
              />
            </Menu.Item>
            <Menu.Item>
              <MultiSelect
                {...multiselectProps}
                contrastPlaceholder
                placeholder={'All participants'}
                noOptionsMessage={() => 'No participants'}
                onChange={(value) => handleParticipantChange(value as OptionType[])}
                value={selectedParticipants}
                options={participants.map((x) => ({ value: x.id, label: x.name }))}
              />
            </Menu.Item>
          </DropDownMenu>
          <DropDownMenu
            button={
              <Menu.Button className="-ml-px bg-white relative inline-flex items-center px-3 py-3 text-sm font-medium text-secondary-purple-dark hover:cursor-default rounded-sm-md focus:outline-none">
                <EyeIcon className="h-5 w-5 text-secondary-purple-dark" aria-hidden="true" />
                <DropDownTitle>
                  Color by
                  <Capitalized>{selectedGroupBy[0] && `: ${selectedGroupBy[0]}`}</Capitalized>
                </DropDownTitle>
                <StyledChevronIcon />
              </Menu.Button>
            }
          >
            {['theme', 'sentiment'].map((x, i) => {
              return (
                <Menu.Item key={i}>
                  <a
                    href="#"
                    onClick={() => handleGroupBy([x])}
                    className="flex text-secondary-purple-dark font-semibold hover:bg-primary-purple-hover hover:cursor-default rounded-sm-md m-1.5 p-1.5 text-sm"
                  >
                    {x[0].toUpperCase() + x.substr(1)}
                  </a>
                </Menu.Item>
              );
            })}
            <Menu.Item>
              <MultiSelect
                {...multiselectProps}
                contrastPlaceholder
                isMulti={false}
                placeholder={'Tag'}
                noOptionsMessage={() => 'No tags'}
                onChange={(value) => handleGroupBy(['tag', (value as OptionType).value])}
                value={
                  selectedGroupBy.length == 2 && selectedGroupBy[0] === 'tag'
                    ? { value: selectedGroupBy[1], label: selectedGroupBy[1] }
                    : null
                }
                options={tags.map((x) => ({ value: x, label: x }))}
              />
            </Menu.Item>
            <Menu.Item>
              <MultiSelect
                {...multiselectProps}
                contrastPlaceholder
                isMulti={false}
                placeholder={'Participant'}
                noOptionsMessage={() => 'No participants'}
                onChange={(value) => handleGroupBy(['participant', (value as OptionType).value])}
                value={
                  selectedGroupBy.length && selectedGroupBy[0] === 'participant'
                    ? {
                        value: selectedGroupBy[1],
                        label: participants.find((el) => el.id === selectedGroupBy[1])?.name,
                      }
                    : null
                }
                options={participants.map((x) => ({ value: x.id, label: x.name }))}
              />
            </Menu.Item>
          </DropDownMenu>
          <div className="bg-white relative flex">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5 text-secondary-purple-dark" />
            </div>
            <input
              onChange={onChange}
              type="text"
              name="search"
              id="search"
              className="focus:outline-none block w-64 pl-10 sm:text-sm"
              placeholder="Search notes..."
            />
          </div>
        </div>
      </div>
    </SecondaryToolbar>
  );
}
