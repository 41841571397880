import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import projectsEmptyImage from '../assets/empty-states/projects.png';
import { getDashboards_dashboards } from '../GraphQL/__generated__/getDashboards';

import {
  TrashIcon,
  PencilIcon,
  DocumentDuplicateIcon,
  PlusIcon,
  DotsHorizontalIcon,
} from '@heroicons/react/outline';
import { Loader } from '../Components';
import { DropDownMenu } from '../Components/DropDownMenu';
import useBoards from '../Hooks/useBoards';
import { toast } from 'react-toastify';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Button from '../Components/Button';
import Well from '../Components/Well';
import Avatar from '../Components/Avatar';
import { useSegment } from 'react-segment-hooks';
import { MenuRow, TableSimple, TableCell, TableRow } from '../Components/TableSimple';
import { checkOrganizationLimits, useOrganization } from '../Hooks/useOrganization';
import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyStateText,
  EmptyState,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import AccountChecklist from '../Components/AccountChecklist';
import FocusToolTip from '../Components/FocusToolTip';
import CreateProjectToolTip from '../Components/CreateProjectToolTip';
import SampleProjectToolTip from '../Components/SampleProjectToolTip';
import { defaultProjectIcons } from '../Consts/projectIcons';
import useUsers from '../Hooks/useUsers';
import colors from '../Consts/colors';
import useOnboardingProgress from '../Hooks/useOnboardingProgress';

const tooltipLocalstorageKey = 'showOnboardingTooltip';
const onboardingTooltipCreationTime = new Date('2022-02-24T16:30Z');

function Home(): JSX.Element {
  const history = useHistory();

  const analytics = useSegment();

  useEffect(() => {
    analytics.page({
      name: 'Home',
    });
  }, []);

  const { createBoard, deleteBoard, fetchBoards, updateBoard } = useBoards();

  const [loading, dashboards] = fetchBoards();

  const { currentUser, fetchUsers } = useUsers();
  const [, user] = currentUser();
  const [, users] = fetchUsers();

  const [, org] = useOrganization();

  const hasDismissedOnboardingTooltip =
    window.localStorage.getItem(tooltipLocalstorageKey) === '-1';
  const userNeedsOnboarding = new Date(user?.createdAt) > onboardingTooltipCreationTime;
  const [sampleProjectTooltipIsOpen, setSampleProjectTooltipIsOpen] = useState(false);
  const [createProjectTooltipIsOpen, setCreateProjectTooltipIsOpen] = useState(false);
  const onboardingProgress = useOnboardingProgress({
    dashboards,
    users,
    org,
  });
  useEffect(() => {
    setSampleProjectTooltipIsOpen(
      !onboardingProgress.hasOpenedSampleProject &&
        !hasDismissedOnboardingTooltip &&
        userNeedsOnboarding
    );
    setCreateProjectTooltipIsOpen(
      onboardingProgress.hasOpenedSampleProject &&
        !onboardingProgress.hasCreatedProject &&
        !hasDismissedOnboardingTooltip &&
        userNeedsOnboarding
    );
  }, [
    hasDismissedOnboardingTooltip,
    onboardingProgress.hasCreatedProject,
    onboardingProgress.hasOpenedSampleProject,
    userNeedsOnboarding,
  ]);

  const handleDashboardClick = (dashboard: getDashboards_dashboards) => async (
    e: React.MouseEvent
  ) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/projects/${dashboard.id}/info`);
    if (dashboard.isSample && !dashboard.isVisited) {
      await updateBoard(dashboard.id, {
        isVisited: true,
      });
    }
  };

  const handleClick = (path: string) => async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(path);
  };

  const handleCreateBoard = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();

    if (!checkOrganizationLimits(org, history, ['projects'])) {
      return false;
    }

    const dashboard = await createBoard('');

    analytics.track({
      event: 'CreateProject',
      properties: {
        id: dashboard.id,
      },
    });

    history.push(`/projects/${dashboard.id}/info`);
  };

  const handleDelete = (dashboardId: number) => async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await deleteBoard(dashboardId);

    analytics.track({
      event: 'DeleteProject',
      properties: {
        id: dashboardId,
      },
    });

    toast.success('The project has been successfully deleted', {
      autoClose: 5000,
    });

    history.push(`/`);
  };

  if (loading) {
    return <Loader />;
  }

  if (!dashboards?.length) {
    return (
      <EmptyState>
        <HeroImage src={projectsEmptyImage} wide></HeroImage>
        <EmptyStateTitle>Create a new project</EmptyStateTitle>
        <EmptyStateText>
          Projects are how you organize all of your research in Notably.
        </EmptyStateText>
        <EmptyStateButton onClick={handleCreateBoard}>
          <EmptyStatePlusIcon />
          New project
        </EmptyStateButton>
      </EmptyState>
    );
  }

  return (
    <div className="flex h-full justify-center">
      <div className="w-2/3">
        <Well wellKey="project-well">
          Notably is a single place to collect, analyze, and share customer research. This is your
          research homepage where all of your projects live.
        </Well>
        <SecondaryToolbar>
          <div className="flex w-full flex-row pb-2 pt-10 px-4 justify-between">
            <div>
              <h1 className={'text-xl mt-2'}>Projects</h1>
            </div>
            <FocusToolTip
              arrowColor={colors.text.purple}
              place="bottom"
              isOpen={createProjectTooltipIsOpen}
              content={
                <CreateProjectToolTip
                  onCreate={(e) => {
                    window.localStorage.setItem(tooltipLocalstorageKey, '-1');
                    handleCreateBoard(e);
                  }}
                  onClose={() => {
                    window.localStorage.setItem(tooltipLocalstorageKey, '-1');
                    setCreateProjectTooltipIsOpen(false);
                  }}
                />
              }
            >
              <Button onClick={handleCreateBoard}>
                <PlusIcon className="text-white w-4 h-4" />
                <div className="pl-2">Create Project</div>
              </Button>
            </FocusToolTip>
          </div>
        </SecondaryToolbar>

        <TableSimple>
          <div
            style={{
              border: '1px solid #D7D3DC',
              boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '3px',
            }}
          >
            {dashboards?.map((dashboard, index: number) => {
              const icon = defaultProjectIcons.find((icon) => icon.name === dashboard.cover);
              return (
                <FocusToolTip
                  arrowColor={colors.text.purple}
                  key={index}
                  place="bottom"
                  isOpen={sampleProjectTooltipIsOpen && dashboard.isSample}
                  content={
                    <SampleProjectToolTip
                      onCheckItOut={async () => {
                        await updateBoard(dashboard.id, {
                          isVisited: true,
                        });
                        history.push(`/projects/${dashboard.id}/info`);
                      }}
                      onClose={() => {
                        window.localStorage.setItem(tooltipLocalstorageKey, '-1');
                        setSampleProjectTooltipIsOpen(false);
                      }}
                    />
                  }
                >
                  <div
                    key={index}
                    style={{
                      boxShadow: '0px 1px 0px #D7D3DC',
                      backgroundColor: 'white',
                      marginBottom: '1px',
                    }}
                  >
                    <TableRow onClick={handleDashboardClick(dashboard)}>
                      <TableCell>
                        {icon && (
                          <img
                            src={icon?.url}
                            alt="cover image"
                            style={{
                              width: 24,
                              height: 24,
                              objectFit: 'cover',
                            }}
                          />
                        )}
                        <span className="pl-2">{dashboard.name}</span>
                      </TableCell>
                      <TableCell>
                        <div className={'text-gray-500 mr-3 w-44 overflow-hidden text-ellipsis'}>
                          {moment(dashboard.updatedAt).format('MMM Do, YYYY')}
                        </div>
                        <div className={'w-44 flex flex-row'}>
                          <Avatar user={dashboard.userByCreatedBy} />
                          <div
                            className={
                              'ml-2 text-ellipsis flex-1 whitespace-nowrap overflow-hidden'
                            }
                          >
                            {dashboard.userByCreatedBy?.name}
                          </div>
                        </div>
                        <div className={'w-30'}>
                          <DropDownMenu
                            icon={<DotsHorizontalIcon className={'mt-1 mx-2 w-4 h-4'} />}
                          >
                            <Menu.Item>
                              <MenuRow
                                href="#"
                                onClick={handleClick(`/projects/${dashboard.id}/edit`)}
                              >
                                <PencilIcon className="mr-3 h-5 w-5" />
                                Rename
                              </MenuRow>
                            </Menu.Item>
                            <Menu.Item>
                              <MenuRow
                                href="#"
                                onClick={handleClick(`/projects/${dashboard.id}/copy`)}
                              >
                                <DocumentDuplicateIcon className="mr-3 h-5 w-5" />
                                Copy
                              </MenuRow>
                            </Menu.Item>
                            <Menu.Item>
                              <MenuRow href="#" onClick={handleDelete(dashboard.id)}>
                                <TrashIcon className="mr-3 h-5 w-5" />
                                Delete
                              </MenuRow>
                            </Menu.Item>
                          </DropDownMenu>
                        </div>
                      </TableCell>
                    </TableRow>
                  </div>
                </FocusToolTip>
              );
            })}
          </div>
        </TableSimple>
      </div>
      {userNeedsOnboarding && (
        <AccountChecklist
          dashboards={dashboards}
          org={org}
          onboardingProgress={onboardingProgress}
        />
      )}
    </div>
  );
}

export default Home;
