import { useMutation, useQuery } from '@apollo/client';

import { FETCH_DOCUMENTS, FETCH_DOCUMENT } from '../GraphQL/queries';
import { CREATE_DOCUMENT, UPDATE_DOCUMENT, DELETE_DOCUMENT } from '../GraphQL/mutations';

export const useFetchDocuments = (dashboardId: string): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_DOCUMENTS, {
    variables: { dashboardId },
  });
  return [loading, data?.documents || []];
}

export const useFetchDocument = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_DOCUMENT, {
    variables: { id },
  });
  return [loading, data?.document];
}

export const useCreateDocument = (): [(dashboardId: string) => any] => {
  const [createMutation] = useMutation(CREATE_DOCUMENT);

  return [async (dashboardId: string) => {
    const res = await createMutation({
      variables: {
        input: {
          dashboardId,
        },
      },
      refetchQueries: [
        {
          query: FETCH_DOCUMENTS,
          variables: { dashboardId },
        },
      ],
    });
    return res?.data?.createDocument?.document;
  }];
}

export const useUpdateDocument = (): [(id: string, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_DOCUMENT);

  return [(id: string, input: any) => {
    return updateMutation({
      variables: {
        id,
        input: { ...input, updatedAt: new Date().toISOString() },
      },
      refetchQueries: [
        {
          query: FETCH_DOCUMENT,
          variables: { id },
        },
      ]
    })
  }];
}

export const useDeleteDocument = (): [(dashboardId: string, id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_DOCUMENT);

  return [(dashboardId: string, id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: FETCH_DOCUMENTS,
          variables: { dashboardId },
        },
      ]
    })
  }];
}

export default {
  useFetchDocuments,
  useFetchDocument,
  useCreateDocument,
  useUpdateDocument,
  useDeleteDocument
}

