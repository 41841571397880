import { ID } from './index';

export interface Sticky {
  id: ID;
  dashboardId: string;
  text: string;
  color: string;
  x: number;
  y: number;
  isGrouped: boolean;
  sentimentScore?: number;
  theme: Theme;
  document?: { id: string; name: string };
  transcription?: { id: string; name: string; file?: { id: string; type: string } };
  tagsByItemId: [];
  participant?: { id: string; name: string };
  participantId?: string;
  userByCreatedBy: { name: string; picture: string };
  createdAt: string;
  options: string | null;
}

export interface StickyOptions {
  timeOffset: number;
}

export interface Theme {
  id: ID;
  notes: Sticky[];
  x: number;
  y: number;
  name: string;
  color: string;
  userByCreatedBy: any;
  createdAt: Date;
}

export interface Tag {
  id?: ID;
  name: string;
  color: string;
}

export type ParticipantBase = {
  id: string;
  name: string;
};

export type DataSourceBase = {
  __typename: string;
  id: string;
  name: string;
  file?: {
    type: string;
  };
};

// Starting width of the sticky
export const STICKY_WIDTH = 200;
// starting width of space between the sticky in a theme/group
export const STICKY_SPACE = 10;
// Starting font of the sticky
export const STICKY_FONT = 14;
// Default spacing step for padding/margin/etc.
export const DEFAULT_STEP = 8;
// Default color for a new sticky
export const DEFAULT_STICKY_COLOR = '#FAF3CD';
// Default scale to show text on stickies
export const DEFAULT_SHOW_TEXT_SCALE = 0.3;
