import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthorizedApolloProvider from './Components/ApolloProvider';
import { ErrorBoundary } from './Components';
import { IntercomProvider } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SegmentProvider } from 'react-segment-hooks';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import defaultTheme from './Consts/defaultTheme';

Sentry.init({
  dsn: 'https://87c5612a47bd463cbe899c140f18038b@o813126.ingest.sentry.io/5805848',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
});

FullStory.init({
  orgId: '131N18',
  devMode: window.location.host === 'localhost',
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Sentry.ErrorBoundary>
        <SegmentProvider apiKey="QoG4UOa8CM9ENFEdftSsRHqAw1HZ1b8P">
          <IntercomProvider appId="ez1svbxn">
            <BrowserRouter>
              <Auth0Provider
                domain={'auth.notably.ai'}
                audience={'http://notably-dev.herokuapp.com/graphql'}
                responseType={'token id_token'}
                clientId={'8KVYfyySTU106qtEbsNsFub6XKenfV3H'}
                redirectUri={window.location.origin}
              >
                <AuthorizedApolloProvider>
                  <ThemeProvider theme={defaultTheme}>
                    <App />
                  </ThemeProvider>
                </AuthorizedApolloProvider>
              </Auth0Provider>
            </BrowserRouter>
          </IntercomProvider>
        </SegmentProvider>
      </Sentry.ErrorBoundary>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
