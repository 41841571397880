import React, { MouseEventHandler } from 'react';
import { Popover } from '@headlessui/react';
import { PhotographIcon, TrashIcon } from '@heroicons/react/outline';

import Button from '../Button';
import { defaultProjectIcons } from '../../Consts/projectIcons';
import { CoverProps } from '../../Models';

type ProjectIconPopoverProps = {
  classNames?: string;
  title: string;
  position: string;
  onIconClick: (icon: CoverProps) => MouseEventHandler<HTMLDivElement>;
  removeIcon: () => void;
  selectedIcon: CoverProps | null;
};

const ProjectIconPopover = ({
  classNames,
  title,
  position,
  onIconClick,
  removeIcon,
  selectedIcon,
}: ProjectIconPopoverProps): JSX.Element => {
  return (
    <Popover className="relative">
      <Popover.Button className="">
        {selectedIcon ? (
          <img
            src={selectedIcon.url}
            style={{
              width: 72,
              height: 72,
              objectFit: 'cover',
            }}
          />
        ) : (
          <Button className={classNames} type="secondary">
            <PhotographIcon className="w-4 h-4 mr-1" />
            {title}
          </Button>
        )}
      </Popover.Button>
      <Popover.Panel
        className={
          'absolute bg-white rounded-sm-md p-2 z-[4]' +
          (position === 'right' ? ' right-0' : ' left-0') +
          (selectedIcon ? ' -mt-3 ml-1' : '')
        }
        style={{
          width: '275px',
          boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #D7D3DC',
        }}
      >
        <div style={{ borderBottom: '1px solid #D7D3DC' }} className="grid grid-cols-4 gap-1 pb-2">
          {defaultProjectIcons.map((icon, index) => {
            return (
              <div
                key={`cover-image${index}`}
                className="hover:cursor-pointer"
                onClick={onIconClick(icon)}
              >
                <img
                  src={icon.url}
                  alt="cover image"
                  style={{
                    width: 62,
                    height: 62,
                    objectFit: 'cover',
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center pt-2">
          <Button className={classNames} type="secondary" onClick={removeIcon}>
            <TrashIcon className="w-4 h-4 mr-1" />
            Remove
          </Button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default ProjectIconPopover;
