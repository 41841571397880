import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

import SecondaryToolbar from '../Components/SecondaryToolbar';
import Well from '../Components/Well';
import Editor from '../Components/Editor';
import Loader from '../Components/Loader';
import useProjectInfo from '../Hooks/useProjectInfo';
import { PROJECT_INFO_TEMPLATE } from '../Consts';
import ProjectIconPopover from '../Components/Popovers/ProjectIconPopover';
import { CoverProps } from '../Models';
import useBoards from '../Hooks/useBoards';
import { defaultProjectIcons } from '../Consts/projectIcons';

export default function Info(): JSX.Element | null {
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const { fetchProjectInfo, upsertProjectInfo } = useProjectInfo(dashboardId);
  const { updateBoard, fetchBoard } = useBoards();
  const { loading, projectInfo } = fetchProjectInfo();
  const projectDescription = projectInfo ? projectInfo.description : PROJECT_INFO_TEMPLATE;

  const [loadingDashboard, dashboard] = fetchBoard(parseInt(dashboardId) ?? 0);
  const [selectedIcon, setSelectedIcon] = useState<CoverProps | null>(null);

  useEffect(() => {
    const currDefaultIcon = defaultProjectIcons.find((icon) => icon.name === dashboard?.cover);
    if (!loadingDashboard && currDefaultIcon) {
      setSelectedIcon(currDefaultIcon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDashboard]);

  if (loading || loadingDashboard) {
    return (
      <div className="flex w-100 items-center justify-center pt-40">
        <ReactLoading type={'spin'} color={'#382152'} height={'50px'} width={'50px'} />
      </div>
    );
  }

  const onImageClick = (icon: CoverProps) => () => {
    setSelectedIcon(icon);
    updateBoard(parseInt(dashboardId), { cover: icon.name });
  };

  const removeIcon = () => {
    setSelectedIcon(null);
    updateBoard(parseInt(dashboardId), { cover: null });
  };

  return (
    <div className={'flex-col h-full'}>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>Info</h1>
          </div>
        </div>
      </SecondaryToolbar>
      <div className={'info-editor flex flex-auto justify-center'}>
        <div className={'flex-col h-full w-full pb-20'}>
          <Well wellKey="info-well">
            Info is a simple space to add documentation for your research project. This might
            include content such as the research plan, details about the target audience, or the
            interview guide.
          </Well>
          <div style={{ maxWidth: '800px' }} className="flex flex-col py-5 m-auto">
            <ProjectIconPopover
              title={'Add Icon'}
              position="left"
              onIconClick={onImageClick}
              removeIcon={removeIcon}
              selectedIcon={selectedIcon}
            />
            <ProjectNameInput
              defaultValue={dashboard?.name}
              onBlur={(e) => updateBoard(parseInt(dashboardId), { name: e.target.value })}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              placeholder="Project Name"
              className={'text-3xl my-6 w-full font-medium'}
              autoFocus={!dashboard?.name}
            ></ProjectNameInput>
          </div>
          {loading && <Loader />}
          {!loading && (
            <Editor
              className={'prose-sm'}
              onSave={(content) => {
                upsertProjectInfo({ description: content });
              }}
              content={projectDescription}
              placeholder="Start typing project info"
            />
          )}
        </div>
      </div>
    </div>
  );
}

const ProjectNameInput = styled(TextareaAutosize)`
  outline: none;
  transition: 0.3s ease 0s;
`;
