import React, { ChangeEvent, ReactText, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import WorkspaceSettingsForm from '../Components/WorkspaceSettingsForm';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import { onboardingPages } from '../Consts/onboardingPages';
import useFiles from '../Hooks/useFiles';
import axios from 'axios';
import { useOrganization, useUpdateOrganization } from '../Hooks/useOrganization';

const GeneralSettings = (): JSX.Element => {
  const [, setValue] = useState('');
  const currentPage = {
    setValue: setValue,
    ...onboardingPages[1],
  };

  const [updateOrg] = useUpdateOrganization();

  const [, org] = useOrganization();

  const [selectedLogo, setSelectedLogo] = useState<null | string>(org?.logo || null);
  const [uploading, setUploading] = useState(false);
  const toastRef = useRef<ReactText | null>(null);

  const { uploadFileToS3 } = useFiles();
  const cancel = axios.CancelToken.source();

  const onLogoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (uploading) {
      return;
    }

    if (e.target.files == null || e.target.files.length == 0) {
      return;
    }

    setUploading(true);

    const file = e.target.files[0];
    if (!file.name.endsWith('.png') && !file.name.endsWith('.gif')) {
      toastRef.current = toast(<>Only .png and .gif files are supported!</>, {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    try {
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        isPublic: true,
      });

      setSelectedLogo(result.url);
      updateOrg({ logo: result.url });

      if (!result.ok) {
        throw new Error('Error uploading file');
      }
    } catch {
      if (axios.isCancel(e)) {
        return;
      }

      toastRef.current = toast(<>Unexpected error while uploading file.</>, {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    setUploading(false);
  };

  const onRemoveLogo = () => {
    setSelectedLogo(null);
    updateOrg({ logo: null });
  };

  const handleUpdateCompanyName = async (name: string) => {
    await updateOrg({ name: name });
  };

  return (
    <div>
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <h1 className={'text-l font-medium mt-1'}>Settings / General</h1>
        </div>
      </SecondaryToolbar>
      <div className="flex flex-col justify-center">
        <div className="w-full ml-52 sm:w-500">
          <div className="bg-white py-10">
            <WorkspaceSettingsForm
              currentPage={currentPage}
              loading={false}
              onLogoUpload={onLogoUpload}
              onRemoveLogo={onRemoveLogo}
              selectedLogo={selectedLogo}
              companyName={org?.name || undefined}
              handleUpdateCompanyName={handleUpdateCompanyName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
