import { toast } from 'react-toastify';
import useTranscripts from './useTranscripts';
import { useHistory } from 'react-router';
import React, { ReactText, useRef } from 'react';
import useFiles from './useFiles';
import axios from 'axios';
import { XIcon } from '@heroicons/react/outline';
import { getFileType, isVideoOrAudio } from '../Utils/fileTypes';

export default function useUpload(dashboardId: string) {
  const { srtToDraft, createTranscript } = useTranscripts();

  const { createFile, uploadFileToS3 } = useFiles();

  const history = useHistory();

  const toastRef = useRef<ReactText | null>(null);

  function handleTranscriptUpload(file: File) {
    const toastId = toast.loading(<>Uploading transcript please wait...</>);

    if (!file.name.endsWith('.srt') && !file.name.endsWith('.vtt')) {
      toast.update(toastId, {
        render: <>Only .srt and .vtt files are supported!</>,
        type: 'error',
        isLoading: false,
        autoClose: 1000,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      if (!event.target?.result) {
        return;
      }
      createTranscript(dashboardId, {
        name: file.name.split('/').pop(),
        text: JSON.stringify(srtToDraft(event.target.result as string)),
      }).then((transcript) => {
        toast.update(toastId, {
          render: <>Successfully uploaded transcript!</>,
          type: 'success',
          isLoading: false,
          autoClose: 1000,
        });

        history.push(`/projects/${dashboardId}/data/${transcript.id}`);
      });
    };

    reader.readAsText(file);
  }

  const cancel = axios.CancelToken.source();

  const CloseButton = ({ closeToast }: { closeToast: () => void }) => (
    <button
      onClick={(e) => {
        e.preventDefault();
        const response = confirm('Are you sure you want to cancel the file upload?');
        if (!response) {
          return;
        }
        closeToast();
        cancel.cancel();
      }}
    >
      <XIcon className={'w-6 h-6'} />
    </button>
  );

  async function handleFileUpload(file: File) {
    toastRef.current = toast(<>Uploading file please wait...</>, {
      progress: 0,
      hideProgressBar: false,
      closeButton: CloseButton,
      isLoading: true,
      progressClassName: '',
    });

    if (!isVideoOrAudio(file.name)) {
      toast.update(toastRef.current, {
        render: <>Only .mp4, .mp3 and .m4a are supported!</>,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    function humanFileSize(size: number) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    function updateProgress(event: ProgressEvent) {
      if (event.lengthComputable && event.type == 'progress') {
        toast.update(toastRef.current as string, {
          render: (
            <>
              Uploading file <b>{file.name}</b>: {humanFileSize(event.loaded)} of{' '}
              {humanFileSize(event.total)}
              <br />
              <span className={'text-xs'}>
                You can switch the tab while file is uploading. Navigating to another page inside
                this tab will cancel upload process.
              </span>
            </>
          ),
          progress: event.loaded / event.total,
        });
      }
    }

    try {
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        onProgress: updateProgress,
      });

      if (!result.ok) {
        throw new Error('Error uploading file');
      }

      const res = await createFile(dashboardId, {
        name: file.name,
        type: getFileType(file.name),
        mimeType: file.type,
        status: 'PROCESSING',
        s3VideoPath: result.path,
      });

      if (res?.id) {
        await createTranscript(dashboardId, {
          name: file.name.split('/').pop(),
          fileId: res?.id,
        });
      }

      toast.update(toastRef.current, {
        render: <>Successfully uploaded file!</>,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      toast.update(toastRef.current, {
        render: <>Unexpected error while uploading file.</>,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }
  }

  return {
    handleTranscriptUpload,
    handleFileUpload,
  };
}
