import { gql } from '@apollo/client/core';

export const THEME_CORE_FIELDS = gql`
  fragment ThemeCoreFields on Theme {
    id
    color
    dashboardId
    userByCreatedBy {
      id
      name
      avatar
    }
    createdAt
    name
    x
    y
  }
`;

export const NOTE_CORE_FIELDS = gql`
  fragment NoteCoreFields on Note {
    id
    text
    x
    y
    color
    dashboardId
    url
    sentimentScore
    participantId
    options
    document {
      id
      name
    }
    transcription {
      id
      name
      file {
        type
        id
      }
    }
    tagsByItemId {
      id
      name
    }
    userByCreatedBy {
      name
    }
    createdAt
  }
`;

export const BOARD_CORE_FIELDS = gql`
  fragment BoardCoreFields on Dashboard {
    id
    name
    createdAt
    updatedAt
    createdBy
    insights
    isVisited
    isSample
    cover
    userByCreatedBy {
      id
      name
      email
      picture
    }
  }
`;

export const TRANSCRIPT_CORE_FIELDS = gql`
  fragment TranscriptionCoreFields on Transcription {
    id
    name
    text
    createdAt
    fileId
    tags
    userByCreatedBy {
      id
      name
      email
      picture
    }
  }
`;

export const FILE_CORE_FIELDS = gql`
  fragment FileCoreFields on File {
    id
    name
    type
    mimeType
    metadata
    status
    statusText
    createdAt
    size
    duration
    userByCreatedBy {
      id
      name
      email
      picture
    }
  }
`;

export const PROJECT_INFO_CORE_FIELDS = gql`
  fragment ProjectInfoCoreFields on ProjectInfo {
    id
    description
    updatedAt
    createdAt
    dashboardId
    userByCreatedBy {
      id
      name
    }
  }
`;

export const PROJECT_INSIGHT_CORE_FIELDS = gql`
  fragment ProjectInsightCoreFields on ProjectInsight {
    id
    clientId
    content
    title
    cover
    updatedAt
    createdAt
    dashboardId
    userByCreatedBy {
      id
      name
      picture
    }
  }
`;

export const DOCUMENT_CORE_FIELDS = gql`
  fragment DocumentCoreFields on Document {
    id
    content
    name
    updatedAt
    createdAt
    dashboardId
    tags
    userByCreatedBy {
      id
      name
      picture
    }
  }
`;

export const PARTICIPANT_CORE_FIELDS = gql`
  fragment ParticipantCoreFields on Participant {
    id
    name
    company
    role
    email
    phone
    lastParticipated
    description
    updatedAt
    createdAt
    userByCreatedBy {
      id
      name
      picture
    }
  }
`;

export const USER_CORE_FIELDS = gql`
  fragment UserCoreFields on User {
    id
    name
    email
    role
    picture
    inviteAccepted
  }
`;
