import { useMutation, useQuery } from '@apollo/client';
import { nanoid } from 'nanoid';

import { INSIGHT_TEMPLATE } from '../Consts/templateTexts';
import { FETCH_PROJECT_INSIGHTS, FETCH_PROJECT_INSIGHT_BY_CLIENT_ID } from '../GraphQL/queries';
import { CREATE_PROJECT_INSIGHT, UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID, DELETE_INSIGHT_BY_CLIENT_ID } from '../GraphQL/mutations';

export const useFetchInsights = (dashboardId: string): { loading: boolean, data: any } => {
  const { loading, data } = useQuery(FETCH_PROJECT_INSIGHTS, {
    variables: { dashboardId },
  });
  return { loading, data: data?.projectInsights }
}

export const useFetchInsightByClientId = (clientId: string) => {
  const { loading, data } = useQuery(FETCH_PROJECT_INSIGHT_BY_CLIENT_ID, {
    variables: { clientId },
  });
  return { loading, data: data?.projectInsightByClientId }
}

export const useCreateInsight = (): Array<(dashboardId: string, clientId?: string) => void> => {
  const [createInsightMutation] = useMutation(CREATE_PROJECT_INSIGHT);

  return [(dashboardId: string, clientId?: string) => {
    return createInsightMutation({
      variables: {
        input: {
          dashboardId,
          clientId: clientId || nanoid(10),
          content: INSIGHT_TEMPLATE
        },
      },
      refetchQueries: [
        {
          query: FETCH_PROJECT_INSIGHTS,
          variables: { dashboardId },
        },
      ],
    })
  }];
}

export const useUpdateInsightByClientId = (): Array<(clientId: string, input: any) => void> => {
  const [updateInsightMutation] = useMutation(UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID);

  return [(clientId: string, input: any) => {
    return updateInsightMutation({
      variables: {
        clientId,
        input: { ...input, updatedAt: new Date().toISOString() },
      },
      refetchQueries: [
        {
          query: FETCH_PROJECT_INSIGHT_BY_CLIENT_ID,
          variables: { clientId },
        },
      ]
    })
  }];
}

export const useDeleteInsight = (): Array<(dashboardId: string, clientId: string) => void> => {
  const [deleteInsightMutation] = useMutation(DELETE_INSIGHT_BY_CLIENT_ID);

  return [(dashboardId: string, clientId: string) => {
    return deleteInsightMutation({
      variables: {
        clientId,
      },
      refetchQueries: [
        {
          query: FETCH_PROJECT_INSIGHTS,
          variables: { dashboardId },
        },
      ]
    })
  }];
}

export default {
  useFetchInsights,
  useFetchInsightByClientId,
  useCreateInsight,
  useUpdateInsightByClientId,
  useDeleteInsight
}

