import { Reference, useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_TRANSCRIPT, DELETE_TRANSCRIPT, UPDATE_TRANSCRIPT } from '../GraphQL/mutations';
import { FETCH_FILES, FETCH_TRANSCRIPT, FETCH_TRANSCRIPTS } from '../GraphQL/queries';
import { ID } from '../Models';
import { genKey, RawDraftContentState } from 'draft-js';
import { Node, NodeCue, parseSync } from 'subtitle';

export default function useTranscripts() {
  const [createTranscriptMutation] = useMutation(CREATE_TRANSCRIPT);
  const [updateTranscriptMutation] = useMutation(UPDATE_TRANSCRIPT);
  const [deleteTranscriptMutation] = useMutation(DELETE_TRANSCRIPT);
  const [fetchTranscriptsQuery, { loading, data, called }] = useLazyQuery(FETCH_TRANSCRIPTS);
  const [
    fetchTranscriptQuery,
    { loading: loadingOne, data: dataOne, called: calledOne, refetch },
  ] = useLazyQuery(FETCH_TRANSCRIPT);

  async function createTranscript(dashboardId: ID, input: any) {
    const {
      data: {
        createTranscription: { transcription },
      },
    } = await createTranscriptMutation({
      variables: {
        input: {
          ...input,
          dashboardId,
        },
      },
      refetchQueries: [
        {
          query: FETCH_FILES,
          variables: { dashboardId },
        },
        {
          query: FETCH_TRANSCRIPTS,
          variables: { dashboardId },
        },
      ],
    });
    return transcription;
  }

  async function updateTranscript(id: ID, input: any) {
    await updateTranscriptMutation({
      variables: {
        id,
        input,
      },
      refetchQueries: [
        {
          query: FETCH_TRANSCRIPT,
          variables: { id },
        },
      ],
    });
  }

  function fetchTranscripts(dashboardId: ID) {
    if (!called) {
      fetchTranscriptsQuery({
        variables: {
          dashboardId,
        },
      });
    }
    return [loading, data?.transcriptions];
  }

  function fetchTranscript(id: ID) {
    if (!calledOne) {
      fetchTranscriptQuery({
        variables: {
          id,
        },
      });
    }
    return [loadingOne, dataOne?.transcription, refetch];
  }

  function srtToDraft(str: string): RawDraftContentState {
    function isNodeCue(x: Node): x is NodeCue {
      return x.type === 'cue';
    }

    return parseSync(str)
      .filter(isNodeCue)
      .reduce(
        (state, { data: { text, start, end } }) => {
          if (!state) {
            state = {
              blocks: [],
              entityMap: {},
            };
          }

          let personName = 'Unknown Speaker',
            actualText = text;

          if (text.indexOf(':') > 0) {
            [personName, actualText] = text.split(':');

            const keyPerson = genKey();
            const keyCue = genKey();

            const block = {
              key: genKey(),
              type: 'atomic',
              text: actualText,
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [
                // {
                //   key: keyPerson,
                //   offset: 0,
                //   length: personName.length,
                // },
                // {
                //   key: keyCue,
                //   offset: 0,
                //   length: actualText.length,
                // },
              ],
              data: {
                start,
                end,
                participantName: personName,
              },
            };

            state.blocks.push(block);
            state.entityMap[keyPerson] = {
              type: 'PARTICIPANT',
              mutability: 'IMMUTABLE',
              data: {},
            };

            state.entityMap[keyCue] = {
              type: 'CUE',
              mutability: 'MUTABLE',
              data: {
                timeStart: start,
                timeEnd: end,
                participantName: personName,
              },
            };
          } else {
            if (state.blocks.length === 0) {
              state.blocks.push({
                key: genKey(),
                text: '',
                type: 'atomic',
                inlineStyleRanges: [],
                entityRanges: [],
                depth: 0,
                data: {
                  start,
                  end,
                  participantName: personName,
                },
              });
            }
            const existingBlock = state.blocks[state.blocks.length - 1];

            const keyCue = genKey();

            state.blocks[state.blocks.length - 1] = {
              ...existingBlock,
              text: existingBlock.text.trim() + ' ' + text.trim(),
              entityRanges: [
                ...existingBlock.entityRanges,
                // {
                //   key: keyCue,
                //   offset: existingBlock.text.length,
                //   length: existingBlock.text.length + text.length,
                // },
              ],
              data: {
                ...existingBlock.data,
                end: end,
              },
            };

            state.entityMap[keyCue] = {
              mutability: 'MUTABLE',
              type: 'LINK',
              data: {
                startTime: start,
                endTime: end,
                participantName: existingBlock.data?.participantName,
              },
            };
          }

          return state;
        },
        { blocks: [], entityMap: {} } as RawDraftContentState
      );
  }

  async function deleteTranscript(id: ID) {
    await deleteTranscriptMutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            transcriptions(existingRefs, { readField }) {
              return existingRefs.filter((ref: Reference) => id !== readField('id', ref));
            },
          },
        });
      },
    });
  }

  return {
    fetchTranscript,
    createTranscript,
    updateTranscript,
    fetchTranscripts,
    srtToDraft,
    deleteTranscript,
  };
}
