import { default as CoverImage1 } from '../assets/default-cover-photos/cover-image-1.png';
import { default as CoverImage2 } from '../assets/default-cover-photos/cover-image-2.png';
import { default as CoverImage3 } from '../assets/default-cover-photos/cover-image-3.png';
import { default as CoverImage4 } from '../assets/default-cover-photos/cover-image-4.png';
import { default as CoverImage5 } from '../assets/default-cover-photos/cover-image-5.png';
import { default as CoverImage6 } from '../assets/default-cover-photos/cover-image-6.png';
import { default as CoverImage7 } from '../assets/default-cover-photos/cover-image-7.png';
import { default as CoverImage8 } from '../assets/default-cover-photos/cover-image-8.png';

export const defaultCoverImages = [
  { name: 'DefaultCover1', url: CoverImage1, position: '50% 50%' },
  { name: 'DefaultCover2', url: CoverImage2, position: '50% 50%' },
  { name: 'DefaultCover3', url: CoverImage3, position: '50% 50%' },
  { name: 'DefaultCover4', url: CoverImage4, position: '50% 50%' },
  { name: 'DefaultCover5', url: CoverImage5, position: '50% 50%' },
  { name: 'DefaultCover6', url: CoverImage6, position: '50% 50%' },
  { name: 'DefaultCover7', url: CoverImage7, position: 'top right' },
  { name: 'DefaultCover8', url: CoverImage8, position: '50% 50%' },
];
