import React from 'react';
import styled from 'styled-components';
import { useUpdateParticipant } from '../Hooks/useParticipants';
import TextareaAutosize from 'react-textarea-autosize';
import { PersonIcon, CompanyBuildingIcon, IdIcon, CalendarIcon, MailIcon } from '../Icons';
import { PhoneIcon } from '@heroicons/react/outline';
import moment from 'moment';

type Props = {
  participant: any,
}


const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const IconContainer = styled.div`
  width: 24px;
  margin-right: 12px;
`

const DetailLabel = styled.label`
  width: 120px;
  font-weight: 600;
  font-size: 14px;
`

const DetailInput = styled.input`
  border: none;
  border-bottom: 1px solid transparent;
  appearance: none;
  transition: border 0.3s ease 0s;
  font-weight: 500;
  font-size: 14px;
  min-width: 192px;
  &:focus {
    outline: none;
    border-bottom: 1px solid rgba(56, 33, 82, 0.4);
  }
  &::placeholder {
    color: rgba(56, 33, 82, 0.4);
  }
`

const DetailText = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const DescriptionInput = styled(TextareaAutosize)`
  outline: none;
  width: 100%;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  &::placeholder {
    color: rgba(56, 33, 82, 0.4);
  }
`;


const ParticipantEditor = ({ participant }: Props): JSX.Element => {
  const [updateParticipant] = useUpdateParticipant();
  return (
    <>
      <DetailsRow>
        <IconContainer><PersonIcon /></IconContainer>
        <DetailLabel>Name</DetailLabel>
        <DetailInput
          autoFocus={!participant.name}
          type="text"
          placeholder="participant name"
          size={participant.name?.length}
          defaultValue={participant.name}
          onBlur={(e) => updateParticipant(participant.id, { name: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
        />
      </DetailsRow>
      <DetailsRow>
        <IconContainer><CompanyBuildingIcon /></IconContainer>
        <DetailLabel>Company</DetailLabel>
        <DetailInput
          type="text"
          placeholder="company name"
          defaultValue={participant.company}
          size={participant.company?.length}
          onBlur={(e) => updateParticipant(participant.id, { company: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
        />
      </DetailsRow>

      <DetailsRow>
        <IconContainer><IdIcon /></IconContainer>
        <DetailLabel>Role</DetailLabel>
        <DetailInput
          type="text"
          placeholder="role"
          defaultValue={participant.role}
          size={participant.role?.length}
          onBlur={(e) => updateParticipant(participant.id, { role: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
        />
      </DetailsRow>

      <DetailsRow>
        <IconContainer><MailIcon /></IconContainer>
        <DetailLabel>Email</DetailLabel>
        <DetailInput
          type="email"
          placeholder="person@example.com"
          defaultValue={participant.email}
          size={participant.email?.length}
          onBlur={(e) => updateParticipant(participant.id, { email: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
        />
      </DetailsRow>

      <DetailsRow>
        <IconContainer><PhoneIcon /></IconContainer>
        <DetailLabel>Phone</DetailLabel>
        <DetailInput
          type="tel"
          placeholder="999-999-9999"
          defaultValue={participant.phone}
          size={participant.phone?.length}
          onBlur={(e) => updateParticipant(participant.id, { phone: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
        />
      </DetailsRow>

      <DetailsRow>
        <IconContainer><CalendarIcon /></IconContainer>
        <DetailLabel>Date added</DetailLabel>
        <DetailText>{moment(participant.createdAt).format('MM/DD/YYYY')}</DetailText>
      </DetailsRow>

      <DetailsRow>
        <IconContainer><CalendarIcon /></IconContainer>
        <DetailLabel>Last updated</DetailLabel>
        <DetailText>{moment(participant.updatedAt).format('MM/DD/YYYY')}</DetailText>
      </DetailsRow>

      <DescriptionInput
        defaultValue={participant.description}
        onBlur={(e) => updateParticipant(participant.id, { description: e.target.value })}
        placeholder="add description"
      />
    </>
  )
}


export default ParticipantEditor
