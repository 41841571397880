import React, { useEffect, useState } from 'react';
import { useCloudRecordings } from '../Hooks/useZoom';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Loader from './Loader';
import moment from 'moment';
import { DownloadIcon } from '@heroicons/react/outline';
import Button from './Button';
import useFiles from '../Hooks/useFiles';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import useTranscripts from '../Hooks/useTranscripts';
import Input from './Input';
import { checkOrganizationLimits, useOrganization } from '../Hooks/useOrganization';

function minDate(a: Date | null, b: Date | null) {
  return new Date(Math.min(a?.getTime() ?? 0, b?.getTime() ?? 0));
}

function toDateStr(a: Date | null) {
  return a?.toISOString().split('T').shift() ?? '';
}

function ZoomCloudRecordingsListView(): JSX.Element {
  const today = new Date();
  const history = useHistory();
  const { importFile } = useFiles();
  const { createTranscript, srtToDraft } = useTranscripts();
  const [importing, setImporting] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(
    moment(today).subtract(1, 'month').toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(today);
  const [maxDate, setMaxDate] = useState<Date | null>(today);

  const [, org] = useOrganization();

  useEffect(() => {
    const startDateAddMonth = moment(startDate).add(1, 'month').toDate();

    setMaxDate(minDate(startDateAddMonth, today));

    setEndDate(minDate(startDateAddMonth, endDate));
  }, [startDate]);

  const { dashboardId } = useParams<{ dashboardId: string }>();

  const [loading, result, fetchMore] = useCloudRecordings(toDateStr(startDate), toDateStr(endDate));

  async function handleImport(record: any) {
    if (!checkOrganizationLimits(org, history, ['storage', 'duration'])) {
      return false;
    }

    setImporting(true);
    const status = record.transcript ? 'READY' : 'PROCESSING';

    const result = await importFile(dashboardId, record.topic, record.videoDownloadUrl, status);
    if (result.created) {
      await createTranscript(dashboardId, {
        name: record.topic,
        fileId: result.file.id,
        text: record.transcript ? JSON.stringify(srtToDraft(record.transcript)) : null,
      });
    }
    setImporting(false);
    history.push(`/projects/${dashboardId}/data`);
  }

  async function handleScroll(e: any) {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && result?.nextPageToken) {
      await fetchMore(result?.nextPageToken);
    }
  }

  const computedLoading = loading || importing;

  return (
    <>
      <div className={'flex'}>
        <div className={'m-1 w-full'}>
          <label className="block text-sm font-medium">Date From</label>
          <DatePicker
            customInput={<Input />}
            selected={startDate}
            onChange={(date) => setStartDate(date as Date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
          />
        </div>
        <div className={'m-1 w-full'}>
          <label className="block text-sm font-medium">Date To</label>
          <DatePicker
            customInput={<Input />}
            selected={endDate}
            onChange={(date) => setEndDate(date as Date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={maxDate}
          />
        </div>
      </div>
      {computedLoading && <Loader />}
      {!computedLoading && result?.recordings.length === 0 && (
        <div className={'text-center p-3'}>
          🧐 Hmm, we could not find any{' '}
          <a
            className={'underline'}
            href={'https://zoom.us/recording'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            Zoom cloud recordings
          </a>{' '}
          for this date range.
          <br />
          Try searching for a different 30-day date range.
          <br />
          <br />
          Make sure your Zoom account is connected in{' '}
          <a className={'underline'} href={'/settings'}>
            Settings
          </a>
          .
        </div>
      )}
      {!computedLoading && result?.recordings.length > 0 && (
        <div
          onScroll={handleScroll}
          className="bg-white grid grid-cols-2 overflow-hidden overflow-y-auto"
          style={{
            minHeight: '300px',
            maxHeight: '500px',
          }}
        >
          {result?.recordings.map((record: any, index: number) => (
            <div
              key={index}
              className={
                'shadow-md m-1 hover:bg-gray-50 hover:cursor-pointer flex-col py-2 px-3 justify-between'
              }
            >
              <div>
                <video src={record.videoDownloadUrl} controls />
              </div>
              <div className={'flex mt-4 justify-between'}>
                <div>
                  <div className="text-ellipsis overflow-hidden">{record.topic}</div>
                  <div className={'text-gray-500 text-sm overflow-hidden text-ellipsis'}>
                    {moment(record.startTime).format('MMM Do YYYY')}
                  </div>
                </div>
                <div>
                  <Button type={'secondary'} onClick={() => handleImport(record)}>
                    <DownloadIcon className={'w-4 h-4 mr-2'} />
                    Import
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default ZoomCloudRecordingsListView;
