import React, { ChangeEvent, MouseEvent } from 'react';
import Button from './Button';
import ReactLoading from 'react-loading';
import UserPhotoPopover from './Popovers/UserPhotoPopover';
import { CoverProps } from '../Models';
import { defaultUserImages } from '../Consts/userImages';
import NotablyIcon from '../Icons/NotablyIcon';

type OnboardingStep2Props = {
  loading: boolean;
  onImageClick: (image: CoverProps) => () => void;
  onLogoUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  selectedImage: CoverProps | null;
  selectedLogo: string | null;
  updateCurrentPage?: (e: MouseEvent<HTMLElement>) => void;
};

const OnboardingStep2 = ({
  loading,
  onImageClick,
  onLogoUpload,
  selectedImage,
  selectedLogo,
  updateCurrentPage,
}: OnboardingStep2Props): JSX.Element => {
  return (
    <div>
      <div>
        <div className="text-sm font-semibold">{`Step 2 of 2`}</div>
        <div className="text-xl font-semibold py-2 font-secondary">Personalize your workspace</div>
      </div>
      <div className="flex flex-row justify-evenly py-5">
        <div className="flex flex-col items-center">
          <div className="text-sm font-medium">Your Photo</div>
          <div
            className="w-16 h-16 rounded-full my-4"
            style={{
              backgroundImage: `url(${
                selectedImage ? selectedImage.url : defaultUserImages[0].url
              })`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
            }}
          ></div>
          <UserPhotoPopover title={'change photo'} position="left" onImageClick={onImageClick} />
        </div>

        <div className="flex flex-col items-center">
          <div className="text-sm font-medium">Workspace Logo</div>
          {!selectedLogo && (
            <div className="w-16 h-16 my-4 flex items-center justify-center bg-primary-purple/10">
              <NotablyIcon />
            </div>
          )}
          {selectedLogo && (
            <div className="my-4">
              <img
                src={selectedLogo}
                alt="cover image"
                style={{
                  width: 64,
                  height: 64,
                  objectFit: 'cover',
                }}
              />
            </div>
          )}
          <label
            className="bg-white text-secondary-purple-dark focus:ring-secondary-purple-dark border-gray-200 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2"
            htmlFor={'logo_upload'}
          >
            change logo
          </label>
          <input
            id={'logo_upload'}
            name={'logo_upload'}
            className="hidden"
            type="file"
            accept={'image/png, image/gif'}
            onChange={onLogoUpload}
          />
        </div>
      </div>
      <div className="flex justify-center text-xs pb-5 text-primary-purple/40">
        {'square .gif, .png 3mb max'}
      </div>

      <div className="h-10 flex justify-center">
        {loading && <ReactLoading type={'spin'} color={'#382152'} height={'10%'} width={'10%'} />}
        {!loading && (
          <Button size={'lg'} className="w-full flex justify-center" onClick={updateCurrentPage}>
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default OnboardingStep2;
