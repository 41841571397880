export const shakespeare =
  'Yet this my comfort: when your words are done,\n' +
  'My woes end likewise with the evening sun.\n' +
  'Well, Syracusian, say in brief the cause\n' +
  'Why thou departed’st from thy native home\n' +
  'And for what cause thou camest to Ephesus.\n' +
  'A heavier task could not have been imposed\n' +
  'Than I to speak my griefs unspeakable:\n' +
  'Yet, that the world may witness that my end\n' +
  'Was wrought by nature, not by vile offence,\n' +
  'I’ll utter what my sorrows give me leave.\n' +
  'In Syracusa was I born, and wed\n' +
  'Unto a woman, happy but for me,\n' +
  'And by me, had not our hap been bad.\n' +
  'With her I lived in joy; our wealth increased\n' +
  'By prosperous voyages I often made\n' +
  'To Epidamnum; till my factor’s death\n' +
  'And the great care of goods at random left\n' +
  'Drew me from kind embracements of my spouse:\n' +
  'From whom my absence was not six months old\n' +
  'Before herself, almost at fainting under\n' +
  'The pleasing punishment that women bear,\n' +
  'Had made provision for her following me\n' +
  'And soon and safe arrived where I was.\n' +
  'There had she not been long, but she became\n' +
  'A joyful mother of two goodly sons;\n' +
  'And, which was strange, the one so like the other,\n' +
  'As could not be distinguish’d but by names.\n' +
  'That very hour, and in the self-same inn,\n' +
  'A meaner woman was delivered\n' +
  'Of such a burden, male twins, both alike:\n' +
  'Those,–for their parents were exceeding poor,–\n' +
  'I bought and brought up to attend my sons.\n' +
  'My wife, not meanly proud of two such boys,\n' +
  'Made daily motions for our home return:\n' +
  'Unwilling I agreed. Alas! too soon,\n' +
  'We came aboard.\n' +
  'A league from Epidamnum had we sail’d,\n' +
  'Before the always wind-obeying deep\n' +
  'Gave any tragic instance of our harm:\n' +
  'But longer did we not retain much hope;\n' +
  'For what obscured light the heavens did grant\n' +
  'Did but convey unto our fearful minds\n' +
  'A doubtful warrant of immediate death;\n' +
  'Which though myself would gladly have embraced,\n' +
  'Yet the incessant weepings of my wife,\n' +
  'Weeping before for what she saw must come,\n' +
  'And piteous plainings of the pretty babes,\n' +
  'That mourn’d for fashion, ignorant what to fear,\n' +
  'Forced me to seek delays for them and me.\n' +
  'And this it was, for other means was none:\n' +
  'The sailors sought for safety by our boat,\n' +
  'And left the ship, then sinking-ripe, to us:\n' +
  'My wife, more careful for the latter-born,\n' +
  'Had fasten’d him unto a small spare mast,\n' +
  'Such as seafaring men provide for storms;\n' +
  'To him one of the other twins was bound,\n' +
  'Whilst I had been like heedful of the other:\n' +
  'The children thus disposed, my wife and I,\n' +
  'Fixing our eyes on whom our care was fix’d,\n' +
  'Fasten’d ourselves at either end the mast;\n' +
  'And floating straight, obedient to the stream,\n' +
  'Was carried towards Corinth, as we thought.\n' +
  'At length the sun, gazing upon the earth,\n' +
  'Dispersed those vapours that offended us;\n' +
  'And by the benefit of his wished light,\n' +
  'The seas wax’d calm, and we discovered\n' +
  'Two ships from far making amain to us,\n' +
  'Of Corinth that, of Epidaurus this:\n' +
  'But ere they came,–O, let me say no more!\n' +
  'Gather the sequel by that went before.\n' +
  'Nay, forward, old man; do not break off so;\n' +
  'For we may pity, though not pardon thee.\n' +
  'O, had the gods done so, I had not now\n' +
  'Worthily term’d them merciless to us!\n' +
  'For, ere the ships could meet by twice five leagues,\n' +
  'We were encounterd by a mighty rock;\n' +
  'Which being violently borne upon,\n' +
  'Our helpful ship was splitted in the midst;\n' +
  'So that, in this unjust divorce of us,\n' +
  'Fortune had left to both of us alike\n' +
  'What to delight in, what to sorrow for.\n' +
  'Her part, poor soul! seeming as burdened\n' +
  'With lesser weight but not with lesser woe,\n' +
  'Was carried with more speed before the wind;\n' +
  'And in our sight they three were taken up\n' +
  'By fishermen of Corinth, as we thought.\n' +
  'At length, another ship had seized on us;\n' +
  'And, knowing whom it was their hap to save,\n' +
  'Gave healthful welcome to their shipwreck’d guests;\n' +
  'And would have reft the fishers of their prey,\n' +
  'Had not their bark been very slow of sail;\n' +
  'And therefore homeward did they bend their course.\n' +
  'Thus have you heard me sever’d from my bliss;\n' +
  'That by misfortunes was my life prolong’d,\n' +
  'To tell sad stories of my own mishaps.\n' +
  'And for the sake of them thou sorrowest for,\n' +
  'Do me the favour to dilate at full\n' +
  'What hath befall’n of them and thee till now.\n' +
  'My youngest boy, and yet my eldest care,\n' +
  'At eighteen years became inquisitive\n' +
  'After his brother: and importuned me\n' +
  'That his attendant–so his case was like,\n' +
  'Reft of his brother, but retain’d his name–\n' +
  'Might bear him company in the quest of him:\n' +
  'Whom whilst I labour’d of a love to see,\n' +
  'I hazarded the loss of whom I loved.\n' +
  'Five summers have I spent in furthest Greece,\n' +
  'Roaming clean through the bounds of Asia,\n' +
  'And, coasting homeward, came to Ephesus;\n' +
  'Hopeless to find, yet loath to leave unsought\n' +
  'Or that or any place that harbours men.\n' +
  'But here must end the story of my life;\n' +
  'And happy were I in my timely death,\n' +
  'Could all my travels warrant me they live.\n' +
  'Hapless AEgeon, whom the fates have mark’d\n' +
  'To bear the extremity of dire mishap!\n' +
  'Now, trust me, were it not against our laws,\n' +
  'Against my crown, my oath, my dignity,\n' +
  'Which princes, would they, may not disannul,\n' +
  'My soul would sue as advocate for thee.\n' +
  'But, though thou art adjudged to the death\n' +
  'And passed sentence may not be recall’d\n' +
  'But to our honour’s great disparagement,\n' +
  'Yet I will favour thee in what I can.\n' +
  'Therefore, merchant, I’ll limit thee this day\n' +
  'To seek thy life by beneficial help:\n' +
  'Try all the friends thou hast in Ephesus;\n' +
  'Beg thou, or borrow, to make up the sum,\n' +
  'And live; if no, then thou art doom’d to die.\n' +
  'Gaoler, take him to thy custody.\n' +
  'I will, my lord.\n' +
  'Hopeless and helpless doth AEgeon wend,\n' +
  'But to procrastinate his lifeless end.';

export const transcript = `
1
00:00:03,360 --> 00:00:04,320
Brittany: So Dan, do you have

2
00:00:04,320 --> 00:00:05,760
any questions for us before we

3
00:00:05,760 --> 00:00:07,890
get started? Yeah, really?

4
00:00:09,450 --> 00:00:11,970
Awesome. So yeah, if you don't

5
00:00:11,970 --> 00:00:13,740
mind, a couple questions to

6
00:00:13,740 --> 00:00:17,340
break the ice here. So it's been

7
00:00:17,340 --> 00:00:18,570
a long time since we've

8
00:00:18,570 --> 00:00:21,270
connected back from I think IHS

9
00:00:21,270 --> 00:00:22,800
days, which has been very long,

10
00:00:22,950 --> 00:00:25,230
actually. I'm so curious to

11
00:00:25,230 --> 00:00:26,310
know, sort of, like, Where are

12
00:00:26,310 --> 00:00:28,860
you calling in from and then,

13
00:00:29,250 --> 00:00:30,420
like, a little bit about sort of

14
00:00:30,420 --> 00:00:32,010
like you and your, your family,

15
00:00:32,010 --> 00:00:32,970
and then we'll get into the

16
00:00:32,970 --> 00:00:34,350
story of your home too.

17
00:00:35,070 --> 00:00:37,110
Dan: Right. So I am in Burbank,

18
00:00:37,140 --> 00:00:40,170
California. We moved here almost

19
00:00:40,170 --> 00:00:42,840
actually, October 31. Is our six

20
00:00:42,840 --> 00:00:45,420
year anniversary here. It's easy

21
00:00:45,420 --> 00:00:46,410
to remember, because we came

22
00:00:46,440 --> 00:00:47,580
flew in on Halloween, which is

23
00:00:47,580 --> 00:00:49,260
really weird, weird town to fly.

24
00:00:50,370 --> 00:00:52,260
In any case, so yeah, almost six

25
00:00:52,260 --> 00:00:53,820
years, and we bought this home,

26
00:00:53,850 --> 00:00:54,870
right? As soon as you moved

27
00:00:54,870 --> 00:00:55,920
here. So we're in this home?

28
00:00:55,920 --> 00:00:59,340
What was six years as well? If

29
00:00:59,340 --> 00:01:00,390
you want like to know about the

30
00:01:00,390 --> 00:01:02,790
house, or what kind of info

31
00:01:02,790 --> 00:01:03,540
you're interested in?

32
00:01:04,080 --> 00:01:04,770
Brittany: Yeah, I think we're

33
00:01:04,770 --> 00:01:05,940
interested to know sort of,

34
00:01:05,940 --> 00:01:09,210
like, what is work life for you

35
00:01:09,270 --> 00:01:12,390
and your family? And then yeah,

36
00:01:12,390 --> 00:01:13,860
I'm just gonna, I'm curious the

37
00:01:13,860 --> 00:01:16,500
story about your house, maybe we

38
00:01:16,500 --> 00:01:17,640
can dig into some of the

39
00:01:17,640 --> 00:01:18,660
projects that you all may have

40
00:01:18,660 --> 00:01:20,190
been since you've owned it. Or

41
00:01:20,460 --> 00:01:22,020
if you have a remodel on the

42
00:01:22,020 --> 00:01:23,340
horizon, we'd love to hear about

43
00:01:23,340 --> 00:01:23,700
that.

44
00:01:24,450 --> 00:01:27,960
Dan: Yes. So um, so I moved to

45
00:01:27,960 --> 00:01:29,220
you know, we moved duck six

46
00:01:29,220 --> 00:01:30,960
years ago, it was my wife, and

47
00:01:30,960 --> 00:01:33,060
my, at the time, almost one year

48
00:01:33,060 --> 00:01:37,440
old daughter. Now she's six, and

49
00:01:37,440 --> 00:01:40,560
we have a son who is three. So

50
00:01:40,560 --> 00:01:41,670
we wanted sort of a house that

51
00:01:41,670 --> 00:01:43,260
we could grow into. So it was

52
00:01:43,260 --> 00:01:44,850
the kind of thing where, you

53
00:01:44,850 --> 00:01:45,510
know, it was kind of like at the

54
00:01:45,510 --> 00:01:48,780
top of our price range. But it's

55
00:01:48,780 --> 00:01:49,950
a great thing. We bought it when

56
00:01:49,950 --> 00:01:50,910
we did because the market has

57
00:01:50,910 --> 00:01:52,350
been crazy since then, like I

58
00:01:52,350 --> 00:01:53,760
could not afford the house. Now,

59
00:01:53,850 --> 00:01:54,960
frankly, if I had to buy it

60
00:01:54,960 --> 00:01:58,260
today. So when we moved in, we

61
00:01:58,260 --> 00:02:00,240
did sort of very minor cosmetic

62
00:02:00,240 --> 00:02:01,320
stuff initially, which is to

63
00:02:01,320 --> 00:02:06,300
say, a lot of painting new

64
00:02:06,300 --> 00:02:13,110
appliances in the kitchen. I

65
00:02:13,110 --> 00:02:14,280
think that's mostly what we did.

66
00:02:14,280 --> 00:02:16,200
Right? We moved in then sort of

67
00:02:16,200 --> 00:02:17,850
I did a project in a kitchen

68
00:02:17,850 --> 00:02:20,610
right built out. a butcher

69
00:02:20,610 --> 00:02:22,560
block, a large bookshop,

70
00:02:22,560 --> 00:02:23,640
different blocks, sort of like U

71
00:02:23,640 --> 00:02:27,000
shaped counter with a lot of

72
00:02:27,000 --> 00:02:29,250
cabinet for a lot of cabinets

73
00:02:29,340 --> 00:02:31,140
beneath for extra storage space.

74
00:02:31,140 --> 00:02:32,580
So sort of the big project that

75
00:02:32,580 --> 00:02:35,820
I did removed in. And since

76
00:02:35,820 --> 00:02:38,820
then, we also have done, we sort

77
00:02:38,820 --> 00:02:40,230
of haven't done major stuff,

78
00:02:40,230 --> 00:02:41,370
we've done sort of odds and ends

79
00:02:41,370 --> 00:02:42,540
as needed. We've gotten you

80
00:02:42,540 --> 00:02:44,490
know, various degrees to replace

81
00:02:44,490 --> 00:02:46,020
the H fab new water heater, like

82
00:02:46,020 --> 00:02:47,160
those sorts of things, because

83
00:02:47,160 --> 00:02:50,490
the house itself was built in

84
00:02:50,490 --> 00:02:52,260
like the 50s or something. So

85
00:02:52,260 --> 00:02:53,280
it's not like you know, 100

86
00:02:53,280 --> 00:02:54,180
years old, but it's definitely

87
00:02:54,180 --> 00:02:56,130
old senate new house. And it's

88
00:02:56,130 --> 00:02:57,060
sort of the kind of thing where

89
00:02:57,060 --> 00:02:58,080
now that we finally have some

90
00:02:58,080 --> 00:02:59,280
savings accumulated, we're going

91
00:02:59,280 --> 00:03:00,360
to be doing work over the next,

92
00:03:00,360 --> 00:03:01,890
you know, five to 10 years. And

93
00:03:02,220 --> 00:03:04,710
we're kicking off this year with

94
00:03:05,010 --> 00:03:08,160
renovating the bathrooms just

95
00:03:08,160 --> 00:03:08,940
sort of going to go on in the

96
00:03:08,940 --> 00:03:10,170
process where we just got

97
00:03:10,170 --> 00:03:12,210
permits approved a few weeks

98
00:03:12,210 --> 00:03:13,380
ago, and we're just going to the

99
00:03:13,380 --> 00:03:15,480
contractor moving. So we haven't

100
00:03:15,480 --> 00:03:16,980
actually broken ground yet. But

101
00:03:16,980 --> 00:03:18,270
we're sort of you know, with the

102
00:03:18,300 --> 00:03:19,590
architect is a bit involved. So

103
00:03:19,590 --> 00:03:20,700
we've got those plans approved

104
00:03:20,730 --> 00:03:21,840
through the permit. So we sort

105
00:03:21,840 --> 00:03:24,030
of know what we're doing. It's

106
00:03:24,030 --> 00:03:25,170
not going to be much the UI,

107
00:03:25,170 --> 00:03:26,490
frankly, just because that's the

108
00:03:26,520 --> 00:03:27,900
sort of project that makes me

109
00:03:27,900 --> 00:03:29,310
nervous, like I can do some

110
00:03:29,310 --> 00:03:30,810
things but not plumbing or

111
00:03:30,840 --> 00:03:33,960
electric. So. So yeah, that's

112
00:03:33,960 --> 00:03:35,550
sort of where we're at. So that

113
00:03:35,550 --> 00:03:36,270
makes sense. You have any

114
00:03:36,270 --> 00:03:36,990
questions?

115
00:03:37,289 --> 00:03:38,249
Brittany: Yeah. No, that that's

116
00:03:38,249 --> 00:03:39,419
really helpful. Thanks for

117
00:03:39,419 --> 00:03:42,059
walking us through sort of the

118
00:03:42,059 --> 00:03:43,319
history of the renovation

119
00:03:43,319 --> 00:03:45,779
projects. So the next question,

120
00:03:46,049 --> 00:03:47,909
how do you feel about design and

121
00:03:47,909 --> 00:03:49,709
home renovation projects? What

122
00:03:49,709 --> 00:03:51,149
are some of the emotions that

123
00:03:51,149 --> 00:03:52,649
come to mind when you think

124
00:03:52,649 --> 00:03:55,619
about either doing them or

125
00:03:55,619 --> 00:03:56,609
planning them?

126
00:03:57,389 --> 00:03:58,169
Dan: So this is sort of the

127
00:03:58,169 --> 00:03:59,789
first time we're doing a real

128
00:03:59,789 --> 00:04:01,169
renovation project with a

129
00:04:01,169 --> 00:04:02,909
contractor. So it's a little bit

130
00:04:03,209 --> 00:04:04,469
nerve racking only because

131
00:04:04,469 --> 00:04:05,459
everything I've heard, it's been

132
00:04:05,459 --> 00:04:09,389
bad, frankly. But, you know, the

133
00:04:09,389 --> 00:04:10,409
person who we found is

134
00:04:10,409 --> 00:04:11,909
supposedly pretty good, I found

135
00:04:11,909 --> 00:04:13,289
him two friends who did a major

136
00:04:13,289 --> 00:04:14,699
remodel where they, you know,

137
00:04:14,699 --> 00:04:16,169
added in addition to their home,

138
00:04:16,169 --> 00:04:17,279
so sort of a much greater

139
00:04:17,279 --> 00:04:21,239
project. And in terms of design,

140
00:04:21,269 --> 00:04:22,049
and that's other than the

141
00:04:22,049 --> 00:04:25,409
frankly, like were interested

142
00:04:25,409 --> 00:04:26,969
in, but don't sort of know a lot

143
00:04:26,969 --> 00:04:28,529
about how it actually like we're

144
00:04:28,529 --> 00:04:29,759
interested to kind of get into

145
00:04:29,759 --> 00:04:31,289
to see how it actually works in

146
00:04:31,289 --> 00:04:34,349
practice. My wife is sort of,

147
00:04:34,379 --> 00:04:35,999
you know, more of the design

148
00:04:35,999 --> 00:04:37,619
person, but I'm involved we're

149
00:04:37,619 --> 00:04:38,369
involved probably that most

150
00:04:38,369 --> 00:04:41,879
husbands would be. But and like

151
00:04:41,879 --> 00:04:44,069
in terms of like, style and

152
00:04:44,069 --> 00:04:47,669
inspiration. It's you know, it's

153
00:04:47,669 --> 00:04:48,539
sort of like a variety of

154
00:04:48,539 --> 00:04:49,739
sources and eclectic like my

155
00:04:49,739 --> 00:04:50,969
wife definitely has a specific

156
00:04:50,969 --> 00:04:53,339
style she likes and not sort of

157
00:04:53,339 --> 00:04:55,319
overly modern or overly retro

158
00:04:55,319 --> 00:04:56,729
kind of somewhere in between.

159
00:04:57,989 --> 00:04:59,459
But sort but sort of clean and

160
00:04:59,459 --> 00:05:02,519
not cluttered. And then that

161
00:05:02,519 --> 00:05:03,989
sort of thing, and I guess like,

162
00:05:03,989 --> 00:05:06,119
you know, just through seeing

163
00:05:06,659 --> 00:05:07,709
what friends are doing, seeing

164
00:05:07,709 --> 00:05:09,599
what people you know, other

165
00:05:09,599 --> 00:05:11,219
people are doing just even

166
00:05:11,219 --> 00:05:12,329
sometimes just look at homeless

167
00:05:12,329 --> 00:05:13,619
things, you know the areas see

168
00:05:13,619 --> 00:05:14,759
what, you know, the new

169
00:05:14,759 --> 00:05:16,169
renovated houses look like

170
00:05:16,859 --> 00:05:19,259
Instagram, obviously, we're both

171
00:05:19,259 --> 00:05:20,489
on Instagram, and we look a lot

172
00:05:20,489 --> 00:05:23,309
of stuff and some HGTV I guess

173
00:05:23,309 --> 00:05:24,299
we watch all the time, the time,

174
00:05:24,299 --> 00:05:24,929
that sort of thing.

175
00:05:25,860 --> 00:05:28,020
Brittany: Awesome. Great. Let's

176
00:05:28,020 --> 00:05:29,310
see where we're at. And these

177
00:05:29,310 --> 00:05:33,270
questions and on time. So,

178
00:05:34,020 --> 00:05:37,050
Alison, do you have any follow

179
00:05:37,050 --> 00:05:39,270
up questions in the section of

180
00:05:39,270 --> 00:05:41,250
icebreakers or contextual

181
00:05:41,250 --> 00:05:42,720
questions? Or do you think we

182
00:05:42,720 --> 00:05:45,390
should jump into the task flow?

183
00:05:45,390 --> 00:05:45,750
Now,

184
00:05:46,829 --> 00:05:47,639
Allison Marshall: just one kind

185
00:05:47,639 --> 00:05:49,529
of follow up, as far as you

186
00:05:49,529 --> 00:05:51,479
mentioned, that the reason you

187
00:05:51,479 --> 00:05:53,039
thought now was sort of like the

188
00:05:53,039 --> 00:05:55,019
right time to do the renovation

189
00:05:55,019 --> 00:05:57,179
was mostly financially driven,

190
00:05:57,179 --> 00:05:58,139
but I'm wondering if there was

191
00:05:58,139 --> 00:05:59,849
any other motivations that sort

192
00:05:59,849 --> 00:06:02,099
of made you realize that now was

193
00:06:02,099 --> 00:06:05,699
a good time to do it? Um,

194
00:06:06,840 --> 00:06:08,010
Dan: well, I mean, you know, on

195
00:06:08,010 --> 00:06:09,510
a personal level, our kids are

196
00:06:09,510 --> 00:06:11,280
at an age now where we'll be

197
00:06:11,280 --> 00:06:12,780
less taxing, you know, you don't

198
00:06:12,780 --> 00:06:13,710
want to do more. And I feel like

199
00:06:13,710 --> 00:06:15,480
with the reservation with our

200
00:06:15,510 --> 00:06:16,800
son is three, it's sort of an

201
00:06:16,800 --> 00:06:18,120
age where I feel like we can

202
00:06:18,360 --> 00:06:21,450
mentally handle it. And it's the

203
00:06:21,450 --> 00:06:22,080
kind of thing where, as I

204
00:06:22,080 --> 00:06:23,430
mentioned, we bought this home,

205
00:06:23,610 --> 00:06:24,960
we knew over time, but there are

206
00:06:24,960 --> 00:06:25,890
things we want to do, you know,

207
00:06:25,890 --> 00:06:26,970
there are other projects after

208
00:06:26,970 --> 00:06:29,100
this one that we have a gigantic

209
00:06:29,100 --> 00:06:31,230
five car garage that we want to

210
00:06:31,230 --> 00:06:33,060
like convert into a half beach

211
00:06:33,090 --> 00:06:34,260
and like half like pool house,

212
00:06:34,260 --> 00:06:36,300
half a garage kind of thing. And

213
00:06:36,300 --> 00:06:37,080
that's, you know, that's going

214
00:06:37,080 --> 00:06:38,490
to cost hundreds of 1000s. And

215
00:06:38,490 --> 00:06:39,510
we don't have that much savings

216
00:06:39,510 --> 00:06:40,890
yet, but we kind of were there

217
00:06:40,890 --> 00:06:41,940
and the bathroom is sort of like

218
00:06:41,940 --> 00:06:43,590
it's triage. Like, there's

219
00:06:43,590 --> 00:06:44,400
certain things you want to do in

220
00:06:44,400 --> 00:06:45,390
bathrooms, our bathrooms are

221
00:06:45,390 --> 00:06:46,860
quite old. And you know, my wife

222
00:06:46,860 --> 00:06:47,880
wants a tub, all that sort of

223
00:06:47,880 --> 00:06:48,960
thing. So it's like, okay, let's

224
00:06:48,960 --> 00:06:50,190
finally, that's the first of the

225
00:06:50,190 --> 00:06:52,350
list and it will kind of work

226
00:06:52,380 --> 00:06:52,830
will be.

227
00:06:56,100 --> 00:06:57,990
Brittany: Awesome. Well, so for

228
00:06:57,990 --> 00:06:59,610
this next part of the interview,

229
00:06:59,670 --> 00:07:01,230
I'm going to chat you over a

230
00:07:01,230 --> 00:07:05,340
URL. So in the zoom chat, and

231
00:07:05,370 --> 00:07:06,810
I'm also going to send over a

232
00:07:06,810 --> 00:07:09,090
password that you can use. So

233
00:07:09,390 --> 00:07:10,260
I'll give you a minute on your

234
00:07:10,260 --> 00:07:12,780
end to pull up Chrome,

235
00:07:12,780 --> 00:07:14,400
hopefully, if that's your

236
00:07:14,430 --> 00:07:16,140
browser of choice. And whenever

237
00:07:16,140 --> 00:07:18,360
you're ready, you can start

238
00:07:18,360 --> 00:07:19,770
sharing your screen with us.

239
00:07:28,200 --> 00:07:32,370
Dan: Okay, now, I'm sure we'll

240
00:07:32,370 --> 00:07:34,050
actually love to stick around,

241
00:07:34,050 --> 00:07:35,520
we try to get it into the cross,

242
00:07:35,520 --> 00:07:36,300
you prefer that.

243
00:07:45,660 --> 00:07:46,440
Brittany: And there should be an

244
00:07:46,440 --> 00:07:48,000
option to share your screen at

245
00:07:48,000 --> 00:07:50,850
the bottom of the zoom UI. It's

246
00:07:50,850 --> 00:07:52,110
like a little upward pointing

247
00:07:52,110 --> 00:07:54,000
arrow. But take your time, if

248
00:07:54,000 --> 00:07:54,990
you need a minute getting set

249
00:07:54,990 --> 00:07:55,110
up.

250
00:08:09,720 --> 00:08:10,710
Dan: Find it work, we use a

251
00:08:10,710 --> 00:08:11,670
different program something that

252
00:08:11,670 --> 00:08:14,790
is super, super brilliant. Let's

253
00:08:14,790 --> 00:08:15,870
see. Bye.

254
00:08:28,019 --> 00:08:29,069
Brittany: Did you see that chat

255
00:08:29,069 --> 00:08:31,349
that I sent you over? Yeah, I

256
00:08:31,349 --> 00:08:36,719
got the site up. Let me know if

257
00:08:36,719 --> 00:08:37,379
I can help with any

258
00:08:37,740 --> 00:08:39,390
Dan: chip content. Okay, so the

259
00:08:39,390 --> 00:08:40,650
furthered screen would not have

260
00:08:40,650 --> 00:08:55,020
found it. Okay. Something but I

261
00:08:55,020 --> 00:08:56,550
can't Oh, there we go. Can you

262
00:08:56,550 --> 00:08:57,270
see my screen?

263
00:08:58,980 --> 00:09:01,560
Brittany: I do. Yes. Okay,

264
00:09:01,560 --> 00:09:06,240
great. So, before we jump in, to

265
00:09:06,240 --> 00:09:07,890
give you a little bit of context

266
00:09:07,890 --> 00:09:10,080
for this section are a little

267
00:09:10,080 --> 00:09:11,250
bit of a roadmap for this

268
00:09:11,250 --> 00:09:12,900
section of the interview. So the

269
00:09:12,900 --> 00:09:15,090
questions that we're asking are

270
00:09:15,900 --> 00:09:17,700
intended to be open ended. So if

271
00:09:17,700 --> 00:09:20,070
you need clarity, feel free to

272
00:09:20,070 --> 00:09:21,900
ask questions. It might feel a

273
00:09:21,900 --> 00:09:24,030
little unnatural. But it's

274
00:09:24,030 --> 00:09:25,500
really helpful for us if you can

275
00:09:25,500 --> 00:09:26,910
speak aloud as you're thinking

276
00:09:26,910 --> 00:09:30,000
through sort of observations and

277
00:09:30,000 --> 00:09:32,820
decisions bouncing around this

278
00:09:33,210 --> 00:09:34,560
application is an active

279
00:09:34,560 --> 00:09:36,120
development. So we might

280
00:09:36,120 --> 00:09:37,800
encounter some bugs along the

281
00:09:37,800 --> 00:09:39,780
way. And thank you for your

282
00:09:39,780 --> 00:09:41,730
patience with that in advance.

283
00:09:43,050 --> 00:09:44,790
So yeah, that sort of like to

284
00:09:44,790 --> 00:09:47,850
set the scenario here. You can

285
00:09:47,850 --> 00:09:50,370
imagine that one of your friends

286
00:09:50,370 --> 00:09:52,950
may be recommended beam to you,

287
00:09:53,310 --> 00:09:54,480
after finding out that you're

288
00:09:54,480 --> 00:09:56,070
getting ready to do a remodeling

289
00:09:56,250 --> 00:09:58,680
project. And so, for this first

290
00:09:58,680 --> 00:09:59,850
part of the call, and just want

291
00:09:59,850 --> 00:10:03,510
to be sort of a fly on the wall

292
00:10:03,510 --> 00:10:05,970
as you land here and check out

293
00:10:05,970 --> 00:10:10,560
their homepage. So I just kind

294
00:10:10,560 --> 00:10:11,760
of play around with the places

295
00:10:11,760 --> 00:10:13,980
you want me to go, or? Yeah, I

296
00:10:13,980 --> 00:10:16,260
think whatever you would kind of

297
00:10:16,260 --> 00:10:18,870
naturally do to learn more about

298
00:10:18,870 --> 00:10:25,110
being at this stage. just

299
00:10:25,110 --> 00:10:26,730
reading all the info?

300
00:10:48,660 --> 00:10:50,640
What are some of the sort of

301
00:10:50,640 --> 00:10:52,290
first impressions that you have

302
00:10:52,290 --> 00:10:53,580
about the information that's

303
00:10:53,580 --> 00:10:54,510
presented here?

304
00:10:56,070 --> 00:10:57,330
Dan: It's pretty clear, it seems

305
00:10:57,330 --> 00:10:58,860
like the idea here is that it

306
00:10:58,860 --> 00:11:01,350
helps you, you know, it's like a

307
00:11:01,350 --> 00:11:04,110
down here, you upload photos of

308
00:11:04,110 --> 00:11:06,180
the space, and it helps you to

309
00:11:06,180 --> 00:11:09,030
create the floor plan and model,

310
00:11:09,300 --> 00:11:11,130
and then you shall get him to

311
00:11:11,250 --> 00:11:13,020
choose finishes and, and

312
00:11:13,020 --> 00:11:16,440
different items to to populate

313
00:11:16,470 --> 00:11:18,090
that model.

314
00:11:19,740 --> 00:11:24,330
Brittany: Okay. And then, what

315
00:11:24,330 --> 00:11:26,160
are some ways that you might

316
00:11:26,190 --> 00:11:29,460
benefics expect to move forward

317
00:11:29,460 --> 00:11:33,030
from here? Or would you ready?

318
00:11:34,049 --> 00:11:35,039
Dan: Yeah, I mean, based on

319
00:11:35,039 --> 00:11:36,029
this, like, I was saying, I

320
00:11:36,029 --> 00:11:39,809
would take pictures first. And

321
00:11:39,809 --> 00:11:41,129
then once it, you know, sort of

322
00:11:41,129 --> 00:11:46,259
figured out the space. I mean, I

323
00:11:46,319 --> 00:11:47,519
think you just start populating

324
00:11:47,519 --> 00:11:48,659
and I it seems like I've used

325
00:11:48,659 --> 00:11:50,699
something similar, but probably

326
00:11:50,699 --> 00:11:52,259
more primitive, like IKEA has a

327
00:11:52,259 --> 00:11:53,189
thing where and I think will

328
00:11:53,189 --> 00:11:54,269
probably work somewhat like

329
00:11:54,269 --> 00:11:56,069
that, where you kind of put in

330
00:11:56,069 --> 00:11:57,449
the floor plans, and then you

331
00:11:57,449 --> 00:11:58,439
figure out, you know, if you

332
00:11:58,439 --> 00:11:59,399
want to build cabinets, or

333
00:11:59,399 --> 00:12:00,929
whatever, you fill it out, you

334
00:12:00,929 --> 00:12:02,249
know, how big you want it in the

335
00:12:02,249 --> 00:12:04,019
room and that sort of thing. It

336
00:12:04,019 --> 00:12:07,949
looks like this also has sort of

337
00:12:07,979 --> 00:12:09,389
starting points, if you like

338
00:12:09,389 --> 00:12:11,039
these sorts of designs and don't

339
00:12:11,039 --> 00:12:12,149
want to start from scratch.

340
00:12:15,360 --> 00:12:16,590
Brittany: You mentioned designs,

341
00:12:16,620 --> 00:12:18,780
is it kind of clear what a

342
00:12:18,780 --> 00:12:20,610
design is in this scenario?

343
00:12:21,570 --> 00:12:22,980
Dan: Not yet, I think I'd have

344
00:12:22,980 --> 00:12:24,750
to get into it more to see if

345
00:12:24,750 --> 00:12:26,760
it's a thing where we're just

346
00:12:26,760 --> 00:12:28,860
talking about sort of style, or

347
00:12:28,860 --> 00:12:31,680
very more specific components,

348
00:12:31,680 --> 00:12:34,020
like actual sinks, or, you know,

349
00:12:34,020 --> 00:12:35,700
actual faucets or just sort of

350
00:12:35,700 --> 00:12:37,770
stylistically, I think I'd have

351
00:12:37,770 --> 00:12:38,730
to get into it to really

352
00:12:38,730 --> 00:12:42,210
understand that better. Okay,

353
00:12:42,900 --> 00:12:44,280
Brittany: so going back up to

354
00:12:44,280 --> 00:12:45,870
sort of the middle section,

355
00:12:45,960 --> 00:12:48,270
where those designs were, what

356
00:12:48,330 --> 00:12:50,970
would you expect to happen? When

357
00:12:50,970 --> 00:12:52,530
you click Customize?

358
00:12:55,500 --> 00:12:56,430
Dan: That it would give you sort

359
00:12:56,430 --> 00:12:58,110
of a starting to design? And

360
00:12:58,110 --> 00:12:59,070
then if there's things you don't

361
00:12:59,070 --> 00:13:00,270
like you, you know, delete that

362
00:13:00,270 --> 00:13:01,530
cabinet and didn't choose a

363
00:13:01,530 --> 00:13:03,270
different style or something or

364
00:13:03,390 --> 00:13:04,380
a different kind of drawer to

365
00:13:04,440 --> 00:13:05,730
kind of handle or something like

366
00:13:05,730 --> 00:13:08,040
that potentially. Okay,

367
00:13:09,030 --> 00:13:10,830
Brittany: great. So why don't we

368
00:13:10,860 --> 00:13:14,010
go ahead and click in to one of

369
00:13:14,010 --> 00:13:16,380
those designs by clicking the

370
00:13:16,380 --> 00:13:21,720
Customize button. Okay. And then

371
00:13:21,720 --> 00:13:24,270
once we land there, take them

372
00:13:24,600 --> 00:13:26,310
take a minute to kind of share

373
00:13:26,310 --> 00:13:28,170
your first impressions on what

374
00:13:28,170 --> 00:13:28,830
we see.

375
00:13:29,820 --> 00:13:32,610
Dan: Sure. So I'm seeing a

376
00:13:32,640 --> 00:13:39,930
bathroom. Okay. So there's these

377
00:13:39,930 --> 00:13:41,430
are sort of different options

378
00:13:41,430 --> 00:13:42,900
within the bathroom to

379
00:13:42,900 --> 00:13:45,450
customize, or to check out. So

380
00:13:45,630 --> 00:13:46,740
obviously, floor tile, wall

381
00:13:46,740 --> 00:13:49,620
towel, shower, tile, sinks,

382
00:13:49,620 --> 00:13:52,860
faucets, toilets, vanities, all

383
00:13:52,860 --> 00:13:54,120
the stuff you would find in a

384
00:13:54,120 --> 00:13:57,570
bathroom? shows sort of click

385
00:13:57,570 --> 00:13:58,500
through some of these are.

386
00:13:59,610 --> 00:14:00,210
Brittany: Yeah, I mean, I'm

387
00:14:00,210 --> 00:14:03,930
curious, like, What? What part

388
00:14:03,930 --> 00:14:05,760
of this kind of stands out to

389
00:14:05,760 --> 00:14:08,070
you the most? And what would you

390
00:14:08,100 --> 00:14:09,870
kind of think to do next?

391
00:14:11,309 --> 00:14:12,509
Dan: I mean, if I was doing, you

392
00:14:12,509 --> 00:14:14,279
know, in doing a remodel, I

393
00:14:14,279 --> 00:14:16,169
would probably honestly click

394
00:14:16,169 --> 00:14:17,159
through all of it, right, like

395
00:14:17,159 --> 00:14:18,419
anything that would seem

396
00:14:18,419 --> 00:14:19,859
relevant to my the space that

397
00:14:19,859 --> 00:14:22,109
I've got, and what I'm trying to

398
00:14:22,109 --> 00:14:25,049
get ideas for, you know, like,

399
00:14:25,049 --> 00:14:26,429
if I'm looking at floor tile,

400
00:14:26,429 --> 00:14:27,749
and I don't know exactly what to

401
00:14:27,749 --> 00:14:28,949
do, maybe have an idea, but I

402
00:14:28,949 --> 00:14:30,269
will, you know, within that

403
00:14:30,269 --> 00:14:32,279
broad sense, what are sort of

404
00:14:32,459 --> 00:14:34,049
the different sort of things one

405
00:14:34,049 --> 00:14:37,169
could do, you know, saying for

406
00:14:37,169 --> 00:14:38,039
the others, right, like, if I

407
00:14:38,039 --> 00:14:39,779
like, you know, more of a modern

408
00:14:39,779 --> 00:14:43,289
sync, then maybe click on that.

409
00:14:43,799 --> 00:14:44,759
I mean, this gives you

410
00:14:44,759 --> 00:14:46,259
obviously, it seems to be a very

411
00:14:46,259 --> 00:14:47,399
specific thing. So I don't know

412
00:14:47,399 --> 00:14:48,269
if it's a thing where you click

413
00:14:48,269 --> 00:14:49,109
on it, and there's other

414
00:14:49,109 --> 00:14:50,129
options. If you wanted to

415
00:14:50,129 --> 00:14:54,869
customize that more. Maybe use

416
00:14:54,929 --> 00:14:55,589
some of these.

417
00:14:57,840 --> 00:14:58,380
Unknown: Go ahead.

418
00:15:00,000 --> 00:15:00,960
Dan: Just think something more

419
00:15:00,960 --> 00:15:01,980
specific than others, right?

420
00:15:01,980 --> 00:15:02,850
Like something just have a

421
00:15:02,850 --> 00:15:04,320
generic price side and some have

422
00:15:04,320 --> 00:15:06,330
actual price tags with numbers,

423
00:15:06,360 --> 00:15:07,560
which presumably means a

424
00:15:07,560 --> 00:15:08,550
specific one.

425
00:15:11,130 --> 00:15:12,480
Brittany: How do you feel about

426
00:15:12,570 --> 00:15:14,550
this concept of customizing an

427
00:15:14,550 --> 00:15:17,730
existing design versus like a

428
00:15:17,730 --> 00:15:19,050
new design that you're starting

429
00:15:19,050 --> 00:15:20,100
from scratch?

430
00:15:22,230 --> 00:15:24,600
Dan: Your way? I think it

431
00:15:24,600 --> 00:15:25,710
doesn't, you know, it seems like

432
00:15:25,710 --> 00:15:27,600
a fine idea. It depends upon I

433
00:15:27,600 --> 00:15:29,610
think like the person, if they

434
00:15:29,610 --> 00:15:31,050
feel like they really already

435
00:15:31,050 --> 00:15:32,700
have a very strong view of what

436
00:15:32,700 --> 00:15:33,780
they want, where they don't need

437
00:15:33,810 --> 00:15:35,460
a starting point. But if there's

438
00:15:35,460 --> 00:15:36,750
someone who has sort of a broad

439
00:15:36,750 --> 00:15:38,700
stylistic sense, but doesn't

440
00:15:38,700 --> 00:15:40,380
really know the details, and

441
00:15:40,380 --> 00:15:41,820
they like that, you know, the

442
00:15:41,820 --> 00:15:43,950
can style, and they can then

443
00:15:43,950 --> 00:15:46,020
play with it and make it more

444
00:15:46,080 --> 00:15:48,030
sort of their explicit style, I

445
00:15:48,030 --> 00:15:48,600
guess.

446
00:15:50,490 --> 00:15:51,990
Brittany: Yeah, I think what I'm

447
00:15:52,050 --> 00:15:54,030
trying to get at is, like for

448
00:15:54,030 --> 00:15:55,680
you, I guess, where wouldn't you

449
00:15:55,680 --> 00:15:57,420
fall on that spectrum?

450
00:15:58,950 --> 00:16:00,990
Dan: Um, I mean, I like the idea

451
00:16:00,990 --> 00:16:03,780
for sure of of seeing some

452
00:16:04,290 --> 00:16:06,060
examples, you know, if nothing

453
00:16:06,060 --> 00:16:07,320
else, like, even if I didn't go

454
00:16:07,320 --> 00:16:08,370
with the example, I don't mind

455
00:16:08,370 --> 00:16:09,750
seeing the examples for like,

456
00:16:09,750 --> 00:16:10,920
you know, inspiration, or maybe

457
00:16:10,920 --> 00:16:12,300
I do really like one, and I can

458
00:16:12,780 --> 00:16:13,950
just use that as the starting

459
00:16:13,950 --> 00:16:15,840
point. I don't know that. I

460
00:16:15,840 --> 00:16:17,820
would definitely want it or not,

461
00:16:18,570 --> 00:16:19,650
it would depend on the examples

462
00:16:19,650 --> 00:16:21,090
right away. You know, if, if one

463
00:16:21,090 --> 00:16:22,890
of those examples was very much

464
00:16:23,010 --> 00:16:24,720
this broad style that I was

465
00:16:24,750 --> 00:16:25,920
interested in, then that could

466
00:16:25,920 --> 00:16:27,480
be really cool to be like, Okay,

467
00:16:27,480 --> 00:16:29,730
look like this is already 50% of

468
00:16:29,730 --> 00:16:30,660
the way there, I just don't like

469
00:16:30,660 --> 00:16:31,950
the sink, right? Or something.

470
00:16:33,450 --> 00:16:34,650
So exhaust?

471
00:16:35,100 --> 00:16:36,870
Brittany: Yeah, definitely. How

472
00:16:36,870 --> 00:16:39,390
have you selected products like

473
00:16:39,420 --> 00:16:42,870
these? Sort of like fixtures for

474
00:16:42,870 --> 00:16:44,490
any projects in the past? Or

475
00:16:44,490 --> 00:16:46,950
like, how do you expect to make

476
00:16:46,950 --> 00:16:48,060
these types of purchases?

477
00:16:48,540 --> 00:16:49,770
Dan: Where are your bathroom?

478
00:16:50,520 --> 00:16:52,500
Yeah, I mean, in the past, that

479
00:16:52,500 --> 00:16:53,340
it's been a thing where I've

480
00:16:53,340 --> 00:16:54,930
sort of gone online and done

481
00:16:54,960 --> 00:16:56,400
searches, like Home Depot or

482
00:16:56,400 --> 00:16:58,680
Lowe's calm, and then you know,

483
00:16:58,680 --> 00:17:00,210
going into the store sometimes,

484
00:17:00,750 --> 00:17:05,130
itself. And I in Frankly, I

485
00:17:05,130 --> 00:17:06,210
don't know, like, that's part of

486
00:17:06,210 --> 00:17:07,080
what I mentioned. But once we

487
00:17:07,080 --> 00:17:08,070
start breaking ground and

488
00:17:08,070 --> 00:17:09,030
choosing stuff for our

489
00:17:09,030 --> 00:17:10,830
renovation, I'll be curious to

490
00:17:10,830 --> 00:17:13,500
see sort of what advice the

491
00:17:13,770 --> 00:17:16,560
contractor has, and then sort of

492
00:17:16,560 --> 00:17:17,580
talking to friends about how

493
00:17:17,580 --> 00:17:19,140
they chose stuff, but but

494
00:17:19,140 --> 00:17:20,250
broadly, I'm, you know, just

495
00:17:20,250 --> 00:17:21,150
kind of like look around on the

496
00:17:21,150 --> 00:17:22,830
internet to first style ideas,

497
00:17:22,830 --> 00:17:24,180
and then trying to match those

498
00:17:24,180 --> 00:17:26,310
ideas through, you know, a

499
00:17:26,310 --> 00:17:27,720
website like home depot.com or

500
00:17:27,720 --> 00:17:28,680
any other, you know, wherever

501
00:17:28,860 --> 00:17:30,510
one would get such materials.

502
00:17:32,820 --> 00:17:33,870
Brittany: So let's imagine that

503
00:17:33,870 --> 00:17:35,490
you were sort of using something

504
00:17:35,490 --> 00:17:37,380
like this to plan your bathroom

505
00:17:37,380 --> 00:17:40,320
remodel. And you had some sort

506
00:17:40,320 --> 00:17:42,000
of design elements and some

507
00:17:42,000 --> 00:17:44,280
products in mind, who might you

508
00:17:44,280 --> 00:17:46,200
share something like that? With?

509
00:17:48,390 --> 00:17:50,700
what sense? Do you mean, I'm

510
00:17:50,700 --> 00:17:52,530
just saying, if you had sort of

511
00:17:52,530 --> 00:17:54,180
like, in some place where you've

512
00:17:54,180 --> 00:17:55,530
organized kind of like your

513
00:17:55,530 --> 00:17:58,080
design, inspiration and your

514
00:17:58,440 --> 00:18:00,270
list of products that you plan

515
00:18:00,270 --> 00:18:02,490
to purchase? Who would you share

516
00:18:02,490 --> 00:18:03,720
something like that with?

517
00:18:06,180 --> 00:18:07,350
Dan: I will presumably, you

518
00:18:07,350 --> 00:18:09,510
know, the contractor, but also,

519
00:18:10,710 --> 00:18:11,610
you know, probably friends and

520
00:18:11,610 --> 00:18:12,870
family, you know, Hey, what are

521
00:18:12,870 --> 00:18:13,950
you guys planning? Oh, yeah,

522
00:18:13,950 --> 00:18:15,300
check this out. Right. Like,

523
00:18:15,720 --> 00:18:16,980
like, we have a friend to who's

524
00:18:16,980 --> 00:18:18,450
going through a kitchen remodel

525
00:18:18,450 --> 00:18:20,310
actually right now. So, you

526
00:18:20,310 --> 00:18:21,990
know, we might be curious, you

527
00:18:21,990 --> 00:18:22,680
might be curious what we're

528
00:18:22,680 --> 00:18:23,730
dealing with, here's what she's

529
00:18:23,730 --> 00:18:24,690
doing and that sort of thing.

530
00:18:25,499 --> 00:18:27,689
Brittany: Cool. And I imagine

531
00:18:27,779 --> 00:18:29,849
maybe your wife or your imagine,

532
00:18:29,849 --> 00:18:31,379
like you all were kind of making

533
00:18:31,379 --> 00:18:33,059
these selections together

534
00:18:33,089 --> 00:18:38,279
already. Right. Awesome. Okay,

535
00:18:38,309 --> 00:18:41,789
so, these next few questions are

536
00:18:41,789 --> 00:18:43,169
Allison, did you want to ask

537
00:18:43,199 --> 00:18:44,759
anything else in sort of this

538
00:18:44,759 --> 00:18:46,829
design viewer section of the

539
00:18:46,829 --> 00:18:47,489
interview?

540
00:18:48,060 --> 00:18:48,630
Allison Marshall: Yeah, if we

541
00:18:48,630 --> 00:18:52,800
could scroll to the top. I'm

542
00:18:52,800 --> 00:18:55,620
curious. Just below the title

543
00:18:55,620 --> 00:18:56,910
there. So just went to Santa

544
00:18:56,910 --> 00:18:58,740
Rosa bathroom. There's somebody

545
00:18:58,770 --> 00:19:00,000
named there. And I'm curious to

546
00:19:00,000 --> 00:19:01,800
know what your impressions are

547
00:19:01,830 --> 00:19:06,540
of what that might imply. Um,

548
00:19:06,750 --> 00:19:07,650
and just your thoughts,

549
00:19:07,650 --> 00:19:08,610
generally speaking,

550
00:19:08,610 --> 00:19:11,460
Dan: I would think that he's the

551
00:19:11,460 --> 00:19:13,500
designer that made the space but

552
00:19:13,710 --> 00:19:15,720
not sure. There's no such sort

553
00:19:15,720 --> 00:19:16,680
of subtitle below.

554
00:19:18,660 --> 00:19:19,710
Allison Marshall: Would you, I

555
00:19:19,710 --> 00:19:22,260
guess, do you trust like,

556
00:19:23,430 --> 00:19:25,140
something curated by like a

557
00:19:25,140 --> 00:19:27,600
celebrity designer? More so than

558
00:19:27,600 --> 00:19:29,370
you would just like someone with

559
00:19:29,370 --> 00:19:31,800
good taste? Or like, what would

560
00:19:31,830 --> 00:19:34,770
make this? I guess, like, more

561
00:19:34,770 --> 00:19:38,010
trustworthy for you? To know,

562
00:19:38,010 --> 00:19:39,270
like, how it was curated?

563
00:19:40,680 --> 00:19:41,610
Dan: For me, I don't think it

564
00:19:41,610 --> 00:19:44,310
matters that much. I mean, like,

565
00:19:44,310 --> 00:19:45,510
I don't really care if it was

566
00:19:46,470 --> 00:19:47,760
fine, or if I like the design. I

567
00:19:47,760 --> 00:19:49,650
like the design. Yeah, for me,

568
00:19:49,650 --> 00:19:50,550
it would be just more of how it

569
00:19:50,550 --> 00:19:52,380
looks. I mean, I mean, I

570
00:19:52,410 --> 00:19:53,730
honestly when I first went, I

571
00:19:53,730 --> 00:19:54,750
didn't even notice the name

572
00:19:54,750 --> 00:19:55,980
because I don't you know, I was

573
00:19:55,980 --> 00:19:56,880
looking at the picture.

574
00:19:58,830 --> 00:19:59,790
Allison Marshall: Great. Thank

575
00:19:59,790 --> 00:19:59,970
you.

576
00:20:03,600 --> 00:20:05,010
Brittany: Any other questions?

577
00:20:05,910 --> 00:20:08,400
Allison Marshall: Um, let's look

578
00:20:08,400 --> 00:20:10,110
here. No, I think we're good

579
00:20:10,110 --> 00:20:12,390
with the design viewer, and we

580
00:20:12,390 --> 00:20:13,500
can kind of talk through the

581
00:20:14,130 --> 00:20:15,960
next few questions before we

582
00:20:15,960 --> 00:20:16,500
wrap up.

583
00:20:17,520 --> 00:20:19,110
Brittany: Okay, great. So this

584
00:20:19,140 --> 00:20:21,000
question is sort of a transition

585
00:20:21,000 --> 00:20:22,650
question into some of the follow

586
00:20:22,650 --> 00:20:24,690
up. So on a scale of one to

587
00:20:24,690 --> 00:20:27,060
seven, how difficult or easy was

588
00:20:27,060 --> 00:20:29,670
this concept to understand one

589
00:20:29,940 --> 00:20:31,980
being very difficult for being

590
00:20:31,980 --> 00:20:33,780
neutral and seven being very

591
00:20:33,780 --> 00:20:39,270
easy? How easy was what to

592
00:20:39,270 --> 00:20:40,980
understand this? This?

593
00:20:42,570 --> 00:20:45,060
customized? Yeah, yeah. So the

594
00:20:45,060 --> 00:20:47,340
concept as a whole, right. So

595
00:20:47,430 --> 00:20:49,710
beam. And then one of the other

596
00:20:49,710 --> 00:20:50,910
questions we'll ask is sort of

597
00:20:50,910 --> 00:20:53,850
if you can describe beam, so

598
00:20:53,850 --> 00:20:55,710
we'll get their budgets curious

599
00:20:55,710 --> 00:20:57,030
on sort of like a scale from one

600
00:20:57,030 --> 00:20:59,460
to seven with seven? Yes.

601
00:21:01,560 --> 00:21:02,610
Dan: I think I think it

602
00:21:02,610 --> 00:21:05,010
probably, so weight one is easy,

603
00:21:05,100 --> 00:21:05,970
or one is hard.

604
00:21:06,210 --> 00:21:08,340
Brittany: One is being one is

605
00:21:08,340 --> 00:21:09,870
very difficult. And then second

606
00:21:09,870 --> 00:21:10,860
is very easy.

607
00:21:11,130 --> 00:21:13,470
Dan: So probably six, I think, I

608
00:21:13,470 --> 00:21:14,280
mean, I think it seemed like a

609
00:21:14,280 --> 00:21:16,530
pretty straightforward layout of

610
00:21:16,530 --> 00:21:18,030
the of the site and what you'd

611
00:21:18,030 --> 00:21:19,710
be doing, how you'd be doing it.

612
00:21:20,640 --> 00:21:22,380
That said, I mean, I'd have to

613
00:21:22,380 --> 00:21:23,790
play with it more to really

614
00:21:23,790 --> 00:21:25,200
fully understand it. Right. So I

615
00:21:25,200 --> 00:21:26,250
know that it's quite a seven,

616
00:21:26,250 --> 00:21:27,810
but it didn't, it didn't seem

617
00:21:27,810 --> 00:21:29,190
confusing to me. Really.

618
00:21:30,090 --> 00:21:32,010
Brittany: Okay, great. And then,

619
00:21:32,340 --> 00:21:33,720
based on what we've seen here

620
00:21:33,720 --> 00:21:35,730
today, can you describe in your

621
00:21:35,730 --> 00:21:39,180
own words, what beam is

622
00:21:42,420 --> 00:21:44,790
Dan: it seems to be a site where

623
00:21:44,790 --> 00:21:48,390
you can, you know, sort of,

624
00:21:49,320 --> 00:21:52,230
digitally virtually design a

625
00:21:52,230 --> 00:21:56,340
space. Based on, you know,

626
00:21:56,400 --> 00:21:57,510
again, as we've talked about

627
00:21:57,510 --> 00:22:00,240
either customized or either sort

628
00:22:00,240 --> 00:22:03,600
of step layouts, for a space or

629
00:22:03,780 --> 00:22:05,010
from scratch to sort of

630
00:22:05,010 --> 00:22:08,100
customize it using. And again, I

631
00:22:08,100 --> 00:22:09,090
didn't click through, so I don't

632
00:22:09,090 --> 00:22:10,170
know for sure. But I assume if

633
00:22:10,170 --> 00:22:11,160
you click through a lot of these

634
00:22:11,160 --> 00:22:12,090
options, there's different

635
00:22:12,090 --> 00:22:13,320
things you can use, like

636
00:22:13,320 --> 00:22:14,640
different sorts of tiles to do

637
00:22:14,640 --> 00:22:18,000
that customization. So through

638
00:22:18,000 --> 00:22:18,870
In other words, through some

639
00:22:18,870 --> 00:22:20,460
catalogue of materials and

640
00:22:20,460 --> 00:22:22,110
fixtures and finishes

641
00:22:24,120 --> 00:22:26,490
Brittany: on it, and then at

642
00:22:26,490 --> 00:22:28,080
what point in the project, do

643
00:22:28,080 --> 00:22:30,090
you see the most value of using

644
00:22:30,090 --> 00:22:30,660
beam?

645
00:22:35,160 --> 00:22:36,990
Dan: probably pretty early in

646
00:22:36,990 --> 00:22:38,490
the project, I would think even

647
00:22:38,520 --> 00:22:39,690
even potentially, before a

648
00:22:39,690 --> 00:22:41,340
project, like if one were to

649
00:22:41,340 --> 00:22:42,990
say, you know, gosh, I would

650
00:22:42,990 --> 00:22:44,730
love to redo my bathroom. I

651
00:22:44,730 --> 00:22:45,990
wonder what I could do with it.

652
00:22:46,020 --> 00:22:47,310
Right? Like, well, let me take

653
00:22:47,310 --> 00:22:50,220
the dimensions and see sort of,

654
00:22:50,340 --> 00:22:51,600
within the floor plan, what kind

655
00:22:51,600 --> 00:22:52,860
of cool stuff I could fit in

656
00:22:52,860 --> 00:22:53,910
there, what kind of a different

657
00:22:53,940 --> 00:22:55,560
vanity with different sinks that

658
00:22:55,560 --> 00:22:56,760
I like better, or, you know, a

659
00:22:56,760 --> 00:22:57,870
different shower, bath,

660
00:22:57,900 --> 00:23:00,000
whatever. So I would think

661
00:23:00,000 --> 00:23:02,400
pretty early on, and then, you

662
00:23:02,400 --> 00:23:04,080
know, sort of further down the

663
00:23:04,080 --> 00:23:06,450
road, you know, as you're at the

664
00:23:06,450 --> 00:23:08,040
point of breaking ground,

665
00:23:08,040 --> 00:23:08,940
obviously, then you'd have to

666
00:23:08,940 --> 00:23:11,070
get a little more explicit, once

667
00:23:11,070 --> 00:23:12,780
you have sort of, I guess at

668
00:23:12,780 --> 00:23:13,830
first that would be like, oh,

669
00:23:13,830 --> 00:23:15,270
wow, I could do this, this would

670
00:23:15,270 --> 00:23:17,190
be cool to do. And then once you

671
00:23:17,190 --> 00:23:18,900
get dude going, Okay, well, I'm

672
00:23:18,900 --> 00:23:19,710
gonna do this, what am I

673
00:23:19,710 --> 00:23:20,730
actually going to do, which

674
00:23:20,730 --> 00:23:21,810
might not be you know, what,

675
00:23:21,840 --> 00:23:23,430
what you initially brainstorm

676
00:23:23,430 --> 00:23:25,170
might be the best idea. But it

677
00:23:25,170 --> 00:23:26,490
would still be able to provide

678
00:23:26,490 --> 00:23:28,620
you with those, you know, more

679
00:23:28,620 --> 00:23:30,510
explicit, specific six or

680
00:23:30,510 --> 00:23:30,960
whatever.

681
00:23:32,700 --> 00:23:34,590
Brittany: Got it. So just a

682
00:23:34,590 --> 00:23:36,390
couple more questions before we

683
00:23:36,390 --> 00:23:39,360
wrap up. So this question is

684
00:23:39,360 --> 00:23:42,840
about either the past or if you

685
00:23:42,840 --> 00:23:45,120
plan to for your bathroom

686
00:23:45,120 --> 00:23:46,050
remodels that are on the

687
00:23:46,050 --> 00:23:47,910
horizon. Have you ever had any

688
00:23:47,910 --> 00:23:50,400
drawings, floor plans or

689
00:23:50,400 --> 00:23:52,200
renderings created for any

690
00:23:52,200 --> 00:23:53,430
projects within your home?

691
00:23:54,150 --> 00:23:55,200
Dan: Yeah, well, yeah, for the

692
00:23:55,200 --> 00:23:56,640
bathroom? We are because we have

693
00:23:56,670 --> 00:23:58,140
we hire an architect, because we

694
00:23:58,140 --> 00:23:59,460
had to do permits all that with

695
00:23:59,460 --> 00:24:00,810
the city. They're very crazy

696
00:24:00,810 --> 00:24:02,100
here about such things.

697
00:24:03,300 --> 00:24:04,470
Brittany: And then how much have

698
00:24:04,470 --> 00:24:05,730
you paid for something like

699
00:24:05,730 --> 00:24:08,520
that? For those projects,

700
00:24:08,880 --> 00:24:11,190
Dan: so our architect was fairly

701
00:24:11,190 --> 00:24:12,390
expensive, but we know he was

702
00:24:12,390 --> 00:24:13,500
very, very good and have a lot

703
00:24:13,500 --> 00:24:14,670
of experience in the city, which

704
00:24:14,670 --> 00:24:15,720
can be, as I mentioned, really

705
00:24:15,720 --> 00:24:16,650
challenging when it comes to

706
00:24:16,650 --> 00:24:19,080
permit. So he was his views were

707
00:24:19,080 --> 00:24:19,920
like 10,000.

708
00:24:22,650 --> 00:24:24,960
Brittany: Okay, and so, a little

709
00:24:24,960 --> 00:24:26,460
bit of context to this, and then

710
00:24:26,460 --> 00:24:29,070
a question. So beam actually

711
00:24:29,070 --> 00:24:31,200
produces custom renderings of

712
00:24:31,200 --> 00:24:33,120
your space. This includes your

713
00:24:33,120 --> 00:24:35,190
materials and all of the

714
00:24:35,190 --> 00:24:36,870
different product selections for

715
00:24:36,870 --> 00:24:39,780
this space. So thinking about

716
00:24:39,780 --> 00:24:41,460
sort of picking out your

717
00:24:41,460 --> 00:24:42,930
selections, and then seeing what

718
00:24:42,930 --> 00:24:44,250
that would look like in your

719
00:24:44,250 --> 00:24:46,230
space. How much would you expect

720
00:24:46,230 --> 00:24:48,000
something like that to cost?

721
00:24:51,030 --> 00:24:54,090
Dan: No idea. I mean, you know,

722
00:24:54,120 --> 00:24:55,350
presumably, so that's more to

723
00:24:55,350 --> 00:24:57,270
me, versus the architectural

724
00:24:57,300 --> 00:24:59,010
design. This is more of the sort

725
00:24:59,010 --> 00:25:02,610
of design design. And what one

726
00:25:02,610 --> 00:25:04,740
would, you know, pay a designer

727
00:25:04,740 --> 00:25:07,590
to do? And I, and frankly, we

728
00:25:07,590 --> 00:25:09,060
have not gone down that road. So

729
00:25:09,060 --> 00:25:10,350
I don't know what they cost, but

730
00:25:10,410 --> 00:25:12,900
certainly 1000s? I don't know.

731
00:25:13,080 --> 00:25:14,250
And it probably depends a lot

732
00:25:14,250 --> 00:25:16,530
upon within the design world,

733
00:25:16,530 --> 00:25:17,610
there's different sorts of

734
00:25:17,700 --> 00:25:19,920
levels of design. I would think,

735
00:25:19,920 --> 00:25:21,660
though, as sort of more a DIY

736
00:25:21,660 --> 00:25:23,940
style virtual design thing like

737
00:25:23,940 --> 00:25:25,470
this would presumably be

738
00:25:25,470 --> 00:25:27,270
somewhere toward the lower end

739
00:25:27,270 --> 00:25:29,160
of what what would pay for, you

740
00:25:29,160 --> 00:25:31,170
know, an actual person designer

741
00:25:32,280 --> 00:25:33,720
that we might guess, or whatever

742
00:25:33,720 --> 00:25:35,220
that is, though, I don't have an

743
00:25:35,220 --> 00:25:36,990
idea of that range at this time.

744
00:25:37,830 --> 00:25:38,250
Okay.

745
00:25:38,820 --> 00:25:39,660
Brittany: Would you be willing

746
00:25:39,660 --> 00:25:42,030
to pay $500 for a rendering of

747
00:25:42,030 --> 00:25:43,800
your design in your space?

748
00:25:45,540 --> 00:25:46,770
Dan: Potentially is I mean, I

749
00:25:46,770 --> 00:25:47,820
think so if, especially if it

750
00:25:47,820 --> 00:25:49,140
was pretty functional, and where

751
00:25:49,140 --> 00:25:51,240
you could also like, figure out

752
00:25:51,240 --> 00:25:53,160
specifics within that. I think

753
00:25:53,160 --> 00:25:54,390
that will be, you know, and

754
00:25:54,390 --> 00:25:56,550
again, it will be, I think it

755
00:25:56,550 --> 00:25:58,230
will be important to me to know,

756
00:25:58,260 --> 00:25:59,850
sort of the catalog is well

757
00:25:59,850 --> 00:26:01,020
built out where it's not like I

758
00:26:01,020 --> 00:26:02,340
have three sinks to choose from,

759
00:26:02,340 --> 00:26:03,690
I have many sinks to choose from

760
00:26:03,690 --> 00:26:08,070
kind of thing. So, potentially,

761
00:26:08,190 --> 00:26:09,630
yeah, a bit of a 10. Like what

762
00:26:09,630 --> 00:26:11,760
that is that 500 sort of just

763
00:26:11,760 --> 00:26:12,960
for the floor plan, or is that

764
00:26:12,960 --> 00:26:14,490
inclusive of being able to do

765
00:26:14,490 --> 00:26:16,710
all the functionality of picking

766
00:26:16,710 --> 00:26:19,830
specific finishes and items?

767
00:26:21,030 --> 00:26:21,630
Unknown: Got it?

768
00:26:22,410 --> 00:26:23,250
Brittany: Well, thank you so

769
00:26:23,250 --> 00:26:25,170
much. I know we're right up to

770
00:26:25,170 --> 00:26:27,120
time, which we have been doing.

771
00:26:27,120 --> 00:26:29,370
So really appreciate you going

772
00:26:29,370 --> 00:26:30,120
through all of these different

773
00:26:30,120 --> 00:26:32,130
questions with us. We will be

774
00:26:32,130 --> 00:26:34,680
sending over a $50 gift card to

775
00:26:34,680 --> 00:26:36,060
thank you for your time today.

776
00:26:36,540 --> 00:26:38,220
Was there anything else that you

777
00:26:38,250 --> 00:26:40,320
wanted to add about anything

778
00:26:40,320 --> 00:26:41,700
that we've discussed so far?

779
00:26:43,320 --> 00:26:44,340
Dan: I think so. I mean, it does

780
00:26:44,340 --> 00:26:45,660
seem like an interesting

781
00:26:45,750 --> 00:26:48,600
concept. And yeah, I would check

782
00:26:48,600 --> 00:26:49,620
it off if I was, you know,

783
00:26:49,950 --> 00:26:52,410
normal. Awesome. Well,

784
00:26:52,410 --> 00:26:53,430
Brittany: thank you so much

785
00:26:53,430 --> 00:26:55,680
again, and yeah, like I said,

786
00:26:55,680 --> 00:26:56,550
we'll be following up with you

787
00:26:56,550 --> 00:26:57,990
later today with a gift card and

788
00:26:57,990 --> 00:26:59,220
it was really great reconnecting

789
00:26:59,220 --> 00:27:00,840
with you and best of luck with

790
00:27:00,840 --> 00:27:03,120
the with the bathroom remodels

791
00:27:03,120 --> 00:27:05,130
and family life and all of that.

792
00:27:07,020 --> 00:27:09,060
Thanks and good See you. Bye.

793
00:27:09,570 --> 00:27:09,930
You
`;
