import * as React from 'react';

export default function ExpandIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7071 5.63615H18.364M18.364 5.63615V11.293M18.364 5.63615L5.63604 18.3641"
        stroke="#382152"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
