import React, { useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Well from '../Components/Well';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Button from '../Components/Button';
import Loader from '../Components/Loader';
import { useSegment } from 'react-segment-hooks';
import { useFetchParticipants, useCreateParticipant } from '../Hooks/useParticipants';
import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyStateText,
  EmptyState,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import participantsEmptyImage from '../assets/empty-states/participants.png';

import {
  HeaderCell,
  MainContent,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '../Components/TableWithBorders';

const EmptyStateContainer = styled.div`
  display: flex;
  height: 80vh;
`;

function Participants(): JSX.Element {
  const history = useHistory();

  const analytics = useSegment();

  useEffect(() => {
    analytics.page({
      name: 'Participants',
    });
  }, []);

  const [createParticipant] = useCreateParticipant();

  const [loading, participants] = useFetchParticipants();

  if (loading) {
    return <Loader />;
  }

  const handleCreate = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const participant = await createParticipant();
    history.push(`/participants/${participant.id}`);
  };

  async function handleParticipantClick(id: string) {
    history.push(`/participants/${id}`);
  }

  return (
    <div className="flex-col h-full overflow-hidden">
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>Participants</h1>
          </div>
          <Button onClick={handleCreate} type={'primary'}>
            New Participant
          </Button>
        </div>
      </SecondaryToolbar>
      <MainContent>
        <Well wellKey="participants-well">
          This is where you manage people who have participated in your research studies and see how
          they’ve contributed over time. Adding a person allows you to analyze notes more
          accurately.
        </Well>
        {!participants.length && (
          <EmptyStateContainer>
            <EmptyState>
              <HeroImage src={participantsEmptyImage} wide></HeroImage>
              <EmptyStateTitle>Add people who participate in your research</EmptyStateTitle>
              <EmptyStateText>
                Track participant data and their contributions to research over time.
              </EmptyStateText>
              <EmptyStateButton onClick={handleCreate}>
                <EmptyStatePlusIcon />
                New participant
              </EmptyStateButton>
            </EmptyState>
          </EmptyStateContainer>
        )}

        {participants.length > 0 && (
          <div className="flex flex-col pt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <Table className="min-w-full divide-y divide-gray-200">
                    <TableHeader>
                      <tr>
                        <HeaderCell scope="col" className="px-6 py-3 text-left">
                          Name
                        </HeaderCell>
                        <HeaderCell scope="col" className="px-6 py-3 text-left">
                          Company
                        </HeaderCell>
                        <HeaderCell scope="col" className="px-6 py-3 text-left">
                          Role
                        </HeaderCell>
                        <HeaderCell scope="col" className="px-6 py-3 text-left">
                          Email
                        </HeaderCell>
                        <HeaderCell scope="col" className="px-6 py-3 text-left">
                          Phone Number
                        </HeaderCell>
                        <HeaderCell scope="col" className="px-6 py-3 text-left">
                          Last Participated
                        </HeaderCell>
                      </tr>
                    </TableHeader>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {participants.map((participant) => (
                        <TableRow
                          hover={true}
                          key={participant.id}
                          onClick={async () => {
                            await handleParticipantClick(participant.id);
                          }}
                        >
                          <TableCell className="px-6 py-4 whitespace-nowrap">
                            {participant.name || 'Untitled participant'}
                          </TableCell>
                          <TableCell className="px-6 py-4 whitespace-nowrap">
                            {participant.company}
                          </TableCell>
                          <TableCell className="px-6 py-4 whitespace-nowrap">
                            {participant.role}
                          </TableCell>
                          <TableCell className="px-6 py-4 whitespace-nowrap">
                            {participant.email}
                          </TableCell>
                          <TableCell className="px-6 py-4 whitespace-nowrap">
                            {participant.phone}
                          </TableCell>
                          <TableCell className="px-6 py-4 whitespace-nowrap">
                            {participant.lastParticipated
                              ? moment(participant.lastParticipated).format('MM/DD/YYYY')
                              : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainContent>
    </div>
  );
}

export default Participants;
