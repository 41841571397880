/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';

import Button from '../Components/Button';
import CoverPhotoPopover from './Popovers/CoverPhotoPopover';
import { defaultCoverImages } from '../Consts/coverImages';
import { CoverProps } from '../Models';

type InsightCoverPhotoProps = {
  data: any;
  updateInsight: (input: any) => void;
  isInsightsPanel: boolean;
  expanded?: boolean;
};

const InsightCoverPhoto = ({
  data,
  updateInsight,
  isInsightsPanel,
  expanded = false,
}: InsightCoverPhotoProps): JSX.Element => {
  const currDefaultImage = defaultCoverImages.find((image) => image.name === data?.cover);

  const [selectedImage, setSelectedImage] = useState<null | CoverProps>(currDefaultImage || null);
  const [showHoverCoverImageOptions, setShowHoverCoverImageOptions] = useState(false);

  const onImageClick = (image: CoverProps) => () => {
    setSelectedImage(image);
    updateInsight({ cover: image.name });
  };

  return (
    <>
      {selectedImage && (
        <div
          className="relative"
          onMouseEnter={() => setShowHoverCoverImageOptions(true)}
          onMouseLeave={() => setShowHoverCoverImageOptions(false)}
        >
          <img
            src={selectedImage.url}
            alt="cover image"
            style={{
              width: '100%',
              height: isInsightsPanel ? 250 : 275,
              objectFit: 'cover',
              objectPosition: selectedImage.position,
            }}
          />
          {showHoverCoverImageOptions && (
            <div className="absolute bottom-0 left-0 mb-1 ml-1 flex flex-row">
              <CoverPhotoPopover
                classNames="mr-1"
                title="Change"
                position="left"
                onImageClick={onImageClick}
              />
              <Button
                type="secondary"
                className="button-border ml-1 hover:bg-primary-purple-light"
                onClick={() => {
                  setSelectedImage(null);
                  updateInsight({ cover: null });
                }}
              >
                <TrashIcon className="w-4 h-4 mr-1" />
                Remove
              </Button>
            </div>
          )}
        </div>
      )}
      {!selectedImage && (
        <div style={{ boxShadow: '0px 1px 0px #D7D3DC' }}>
          <div
            className={
              'flex mx-auto pt-7 pb-4 max-w-800' + (isInsightsPanel && !expanded ? ' px-3' : '')
            }
          >
            <CoverPhotoPopover title="Add cover" position="left" onImageClick={onImageClick} />
          </div>
        </div>
      )}
    </>
  );
};

export default InsightCoverPhoto;
