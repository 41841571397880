import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import Button from './Button';
import ReactLoading from 'react-loading';

type OnboardingStep1Props = {
  loading: boolean;
  setCompanyName: Dispatch<SetStateAction<string>>;
  setFullName: Dispatch<SetStateAction<string>>;
  updateCurrentPage?: (e: MouseEvent<HTMLElement>) => void;
};

const OnboardingStep1 = ({
  setCompanyName,
  setFullName,
  loading,
  updateCurrentPage,
}: OnboardingStep1Props): JSX.Element => {
  return (
    <div>
      <div>
        <div className="text-sm font-semibold">{`Step 1 of 2`}</div>
        <div className="text-xl font-semibold py-2 font-secondary">Your account information</div>
      </div>

      <div className="pt-5 pb-4">
        <label htmlFor="name" className="block text-sm font-medium">
          Full Name
        </label>
        <div className="mt-1">
          <input
            id={'name'}
            name={'name'}
            placeholder={`what's your name?`}
            required
            onChange={(e) => setFullName(e.target.value)}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
          />
        </div>
      </div>

      <div className="pb-4">
        <label htmlFor="name" className="block text-sm font-medium">
          Workspace name
        </label>
        <div className="mt-1">
          <input
            id={'company-name'}
            name={'company-name'}
            placeholder={'Hint: most people use their company name'}
            required
            onChange={(e) => setCompanyName(e.target.value)}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
          />
        </div>
      </div>

      <div className="h-10 flex justify-center">
        {loading && <ReactLoading type={'spin'} color={'#382152'} height={'10%'} width={'10%'} />}
        {!loading && (
          <Button size={'lg'} className="w-full flex justify-center" onClick={updateCurrentPage}>
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default OnboardingStep1;
