import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RawDraftEntity } from 'draft-js';
import { useReadLocalStorage } from 'usehooks-ts';

type TagData = {
  id: string;
  tags: string[];
  top?: number;
};

const TagsBlock = styled.div`
  margin-left: 32px;
  position: absolute;
  user-select: none;
  max-width: 300px;
`;

const Tag = styled.span`
  background: rgba(56, 33, 82, 0.2);
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #382152;
`;
export default function TagsPanel({
  entityMap,
}: {
  entityMap?: { [key: string]: RawDraftEntity };
}): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  const tagsPanelPositions: any = useReadLocalStorage('tagsPanel');

  if (!entityMap || !tagsPanelPositions) return <></>;
  const tagsData: TagData[] = Object.keys(entityMap)
    .map((key) => {
      const id = entityMap[key].data.id;
      return {
        id,
        tags: entityMap[key].data.tags,
        top: tagsPanelPositions[id]?.top,
      };
    })
    .filter((tagData: TagData) => tagData.tags && tagData.tags.length > 0);

  return (
    <div ref={containerRef}>
      {tagsData.map((tagData) => {
        return (
          <TagsBlock key={tagData.id} style={{ top: `${tagData.top}px` }}>
            {tagData.tags.map((tag: string) => {
              return <Tag key={tagData.id + tag}>{tag}</Tag>;
            })}
          </TagsBlock>
        );
      })}
    </div>
  );
}
