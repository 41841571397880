import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ContentState } from 'draft-js';

const HighlightedText = styled.span`
  background-color: rgba(133, 95, 168, 0.2);
  padding: 1px;
  border-radius: 2px;
`;

export const Highlight = ({
  entityKey,
  contentState,
  children,
}: {
  entityKey: string;
  contentState: ContentState;
  children: Node;
}): JSX.Element => {
  const highlightRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const data = contentState.getEntity(entityKey).getData();
    const tagsPanelData = localStorage.getItem('tagsPanel');
    const top = highlightRef.current?.getBoundingClientRect().top || 0;
    const currentTagData = {
      [data.id]: {
        top: top + window.scrollY,
      },
    };
    const newTagsPanelData = tagsPanelData
      ? { ...JSON.parse(tagsPanelData), ...currentTagData }
      : currentTagData;

    localStorage.setItem('tagsPanel', JSON.stringify(newTagsPanelData));
    window.dispatchEvent(new Event('local-storage'));
  }, [contentState, entityKey]);

  return <HighlightedText ref={highlightRef}>{children}</HighlightedText>;
};

export default Highlight;
