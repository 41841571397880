import React, { SetStateAction, useState } from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import Button from '../Components/Button';
import { usePayments } from '../Hooks/usePayments';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useOrganization } from '../Hooks/useOrganization';
import { Loader } from '../Components';
import { useIntercom } from 'react-use-intercom';
import SecondaryToolbar from '../Components/SecondaryToolbar';

const tiers = [
  {
    name: 'Free',
    href: '#',
    lookupKey: {
      monthly: 'free',
      yearly: 'free',
    },
    price: {
      monthly: 0,
      yearly: 0,
    },
    description: 'Free tools to help researchers take notes and analyze data.',
    includedFeatures: [
      'Up to 3 projects',
      'Unlimited notes, tags, themes, and insights',
      '10GB of storage',
      '5 hours of transcription',
      'Zoom integration',
    ],
  },
  {
    name: 'Pro',
    href: '#',
    lookupKey: {
      monthly: 'pro',
      yearly: 'pro_yearly',
    },
    price: {
      monthly: 25,
      yearly: 21,
    },
    description: 'For researchers & teams with more data to analyze.',
    includedFeatures: [
      'Unlimited projects',
      '200GB of storage',
      '10 hours of transcription per month',
    ],
  },
  {
    name: 'Teams',
    href: '#',
    lookupKey: {
      monthly: 'team',
      yearly: 'team_yearly',
    },
    price: {
      monthly: 50,
      yearly: 42,
    },
    description: 'More automation and repository features.',
    includedFeatures: [
      'Unlimited projects',
      'Unlimited storage',
      '20 hours of transcription per month',
      'Automatic sentiment analysis',
      'Cross-project tags & search',
    ],
  },
  {
    name: 'Enterprise',
    href: '#',
    lookupKey: {
      monthly: 'enterprise',
      yearly: 'enterprise',
    },
    price: {
      monthly: 0,
      yearly: 0,
    },
    priceTitle: "Let's talk",
    description: 'Democratize research in large organizations.',
    includedFeatures: [
      'Custom automatic tagging',
      '25 hours transcription per month',
      'Automatic tagging',
      'SSO & encrypted backups',
      'Dedicated account manager',
      'Priority support',
    ],
  },
];

function BillingTermsSlider({
  billingTerms,
  setBillingTerms,
}: {
  billingTerms: string;
  setBillingTerms: SetStateAction<any>;
}) {
  const active =
    'relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-secondary-purple-dark focus:z-10 sm:w-auto sm:px-8';
  const passive =
    'relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-secondary-purple-dark focus:z-10 sm:w-auto sm:px-8';

  return (
    <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
      <button
        onClick={() => setBillingTerms('monthly')}
        type="button"
        className={billingTerms == 'monthly' ? active : passive}
      >
        Monthly billing
      </button>
      <button
        onClick={() => setBillingTerms('yearly')}
        type="button"
        className={billingTerms == 'yearly' ? active : passive}
      >
        Yearly billing
      </button>
    </div>
  );
}

const Paywall = (): JSX.Element => {
  const { createCheckoutSession } = usePayments();

  const hierarchy = ['free', 'pro', 'pro_yearly', 'team', 'team_yearly', 'enterprise'];

  const history = useHistory();

  const url = new URL(window.location.href);

  const success = url.searchParams.get('success');

  const [loading, organization] = useOrganization();

  const [billingTerms, setBillingTerms] = useState<'monthly' | 'yearly'>('monthly');

  const intercom = useIntercom();

  if (success) {
    const planTitle = tiers.find((x) => x.lookupKey['monthly'] === organization.plan)?.name;

    toast.success(`All done! You have successfully subscribed to "${planTitle}" plan.`, {
      autoClose: 5000,
    });
    history.push('/settings/plans');
  }

  async function handleClick(lookupKey: string) {
    switch (lookupKey) {
      case 'enterprise':
        intercom.showNewMessages(
          'Hi, I wanted to learn more about upgrading to the enterprise plan. Can you help?'
        );
        break;
      case 'free':

      default:
        const url = await createCheckoutSession(lookupKey);

        if (url) {
          window.location.href = url;
        } else {
          toast.error('Something went wrong. Try one more time.');
        }
    }
  }

  if (loading) {
    return <Loader />;
  }

  const planIndex = hierarchy.indexOf(organization.plan);

  function getButtonText(lookupKey: string) {
    if (lookupKey === 'enterprise') {
      return 'Contact us';
    }

    if (hierarchy.indexOf(lookupKey) === planIndex) {
      return 'Current Plan';
    }
    if (hierarchy.indexOf(lookupKey) > planIndex) {
      return 'Upgrade';
    }
    if (hierarchy.indexOf(lookupKey) < planIndex) {
      return 'Downgrade';
    }
  }

  return (
    <div className="bg-white">
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <h1 className={'text-l font-medium mt-1'}>Settings / Plans and Billing</h1>
        </div>
      </SecondaryToolbar>
      <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <BillingTermsSlider billingTerms={billingTerms} setBillingTerms={setBillingTerms} />
        </div>
        <div className="space-y-4 sm:mt-6 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium ">{tier.name}</h2>
                <p className="mt-4 text-sm ">{tier.description}</p>
                <p className="mt-8">
                  <span className="text-3xl font-extrabold ">
                    {tier.priceTitle && <>{tier.priceTitle}</>}
                    {!tier.priceTitle && <>${tier.price[billingTerms]}</>}
                  </span>{' '}
                  {!tier.priceTitle && <span className="text-base font-medium ">/mo</span>}
                </p>
                <Button
                  type={
                    hierarchy.indexOf(tier.lookupKey[billingTerms]) === planIndex
                      ? 'secondary'
                      : 'primary'
                  }
                  disabled={hierarchy.indexOf(tier.lookupKey[billingTerms]) === planIndex}
                  onClick={() => handleClick(tier.lookupKey[billingTerms])}
                  className={'w-full mt-8 text-center'}
                >
                  {getButtonText(tier.lookupKey[billingTerms])}
                </Button>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium  tracking-wide uppercase">
                  What{"'"}s included
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-primary-purple"
                        aria-hidden="true"
                      />
                      <span className="text-sm ">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Paywall;
