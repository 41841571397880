import React, { useEffect, useState } from 'react';

type PlayerRect = {
  height: number;
  width: number;
  top: number;
};
export default function useMinimizeOnScroll(
  playerRef: React.RefObject<HTMLVideoElement | HTMLAudioElement>
): [boolean, PlayerRect, () => void] {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [playerRect, setPlayerRect] = useState<PlayerRect>({
    height: 360,
    width: 800,
    top: 108,
  });

  useEffect(() => {
    if (!isLoaded) return;
    const handleScroll = () => {
      const currentIsVisible = playerRect.top + playerRect.height - window.pageYOffset > 116;
      setIsMinimized(!currentIsVisible);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [playerRect, isLoaded]);

  const handleOnLoad = () => {
    const rect = playerRef.current?.getBoundingClientRect();
    if (!rect) return;
    setPlayerRect({
      height: rect?.height,
      width: rect?.width,
      top: rect?.top,
    });
    setIsLoaded(true);
  };
  return [isMinimized, playerRect, handleOnLoad];
}
