import styled from 'styled-components';
import colors from '../Consts/colors';

export const MainContent = styled.div`
  max-width: 1000px;
  margin: auto;
  color: ${colors.primary.purple};
`;

export const Table = styled.table`
  color: ${colors.primary.purple};
`;

export const TableHeader = styled.thead`
  background-color: #f3eff6;
`;

export const TableRow = styled.tr<{ hover?: boolean | undefined }>`
  ${(props) =>
    props.hover === false
      ? ``
      : `
  cursor: pointer;
  &:hover {
    background-color: #f3eff6;
  }
  `}
`;

export const HeaderCell = styled.th`
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
`;

export const TableCell = styled.td`
  font-style: normal;
  font-size: 14px;
`;
