import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_TAGS, FETCH_DASHBOARD_TAGS } from '../GraphQL/queries';
import { Sticky, Tag } from '../Models';
import { uniq, flatten } from 'lodash';

function useTags() {
  const { loading, data } = useQuery(FETCH_TAGS);

  function fetchTags() {
    return [loading, data?.tags];
  }

  function getFromStickies(stickies: Sticky[]): string[] {
    return [
      ...new Set(
        stickies
          .map((x) => x.tagsByItemId)
          .flat()
          .map((x: Tag) => x.name)
      ),
    ];
  }

  return {
    fetchTags,
    getFromStickies,
  };
}

export const useDashboardTags = (dashboardId: string): [boolean, string[]] => {
  const { loading, data } = useQuery(FETCH_DASHBOARD_TAGS, {
    variables: { dashboardId },
  });

  const tagsFromDocs: string[] = flatten(
    data?.documents?.map((doc: { id: string; tags: string[] }) => doc.tags) || []
  );

  const tagsFromTranscripts: string[] = flatten(
    data?.transcriptions?.map((transcript: { id: string; tags: string[] }) => transcript.tags) || []
  );
  const stickies = useMemo(() => data?.notes?.map((x: Sticky) => ({ ...x })) ?? [], [data]);
  const { getFromStickies: getTagsFromStickies } = useTags();
  const tagsFromStickies = getTagsFromStickies(stickies);

  const allTags = uniq([...tagsFromStickies, ...tagsFromDocs, ...tagsFromTranscripts]).filter(
    (x) => x
  );

  return [loading, allTags];
};

export default useTags;
