import React from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import InsightsListView from '../Components/InsightsListView';
import InsightsCardView from '../Components/InsightsCardView';
import Loader from '../Components/Loader';
import {
  useFetchInsights,
  useCreateInsight,
  useFetchInsightByClientId,
  useUpdateInsightByClientId,
} from '../Hooks/useInsights';
import InsightEditor from './InsightEditor';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyStateText,
  EmptyState,
  EmptyStateContainer,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import insightsEmptyImage from '../assets/empty-states/insights.png';

type InsightsPanelProps = {
  dashboardId: string;
  width: number;
  expanded?: boolean;
};

export default function InsightsPanel({
  dashboardId,
  width,
  expanded,
}: InsightsPanelProps): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        path="/projects/:dashboardId/notes/insights/:insightId"
        render={({ match }) => {
          return (
            <EditorPanel clientId={match.params.insightId} width={width} expanded={expanded} />
          );
        }}
      />
      <Route>
        <MultiViewPanel dashboardId={dashboardId} width={width} expanded={expanded} />
      </Route>
    </Switch>
  );
}

type MultiViewPanelProps = {
  dashboardId: string;
  width: number;
  expanded?: boolean;
};

function MultiViewPanel({ dashboardId, expanded }: MultiViewPanelProps): JSX.Element {
  const { loading, data } = useFetchInsights(dashboardId);
  const history = useHistory();

  const [createInsightMutation] = useCreateInsight();

  async function handleCreate() {
    const clientId = nanoid(10);
    await createInsightMutation(dashboardId, clientId);
    history.push('/projects/' + dashboardId + '/notes/insights/' + clientId);
  }

  if (loading) return <Loader />;

  if (!data?.length) {
    return (
      <EmptyStateContainer>
        <EmptyState>
          <HeroImage src={insightsEmptyImage}></HeroImage>
          <EmptyStateTitle>Create insights to share findings</EmptyStateTitle>
          <EmptyStateText>
            Insights are a space to document emerging insights and share research findings.
          </EmptyStateText>
          <EmptyStateButton onClick={handleCreate}>
            <EmptyStatePlusIcon />
            New insight
          </EmptyStateButton>
        </EmptyState>
      </EmptyStateContainer>
    );
  }

  if (expanded) {
    return <InsightsCardView insights={data} baseUrl={`/projects/${dashboardId}/notes/insights`} />;
  }

  return (
    <>
      <CreateControl onClick={handleCreate}>Create a new insight...</CreateControl>
      <InsightsListView insights={data} />
    </>
  );
}

const CreateControl = styled.div`
  cursor: pointer;
  margin: 10px;
  width: 180px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(56, 33, 82, 0.4);
`;

type EditorPanelProps = {
  clientId: string;
  width: number;
  expanded?: boolean;
};

function EditorPanel({ clientId, width, expanded }: EditorPanelProps): JSX.Element {
  const { loading, data } = useFetchInsightByClientId(clientId);

  const [updateInsightMutation] = useUpdateInsightByClientId();

  const updateInsight = async (input: any) => {
    await updateInsightMutation(clientId, input);
  };

  if (loading) return <Loader />;

  return (
    <InsightEditor
      insight={data}
      onSave={updateInsight}
      width={expanded ? 800 : width}
      isInsightsPanel
      expanded={expanded}
      className={`insight-editor_${expanded ? 'expanded' : 'small'}`}
    />
  );
}
