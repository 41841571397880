export const PROJECT_INFO_TEMPLATE = `# **Research Plan Template**
### **Background**
What is the current state of the business and your team? Key priorities and objectives the company is working towards? Provide context to why this research matters right now.
### **Research Goal(s)**
What are you setting out to learn? High level goals that must be met to make the research a success.
### **Target Audience**
Who will be the target audience for the research? How many people will you talk to? What criteria makes them the right participants?
### **Research Activities**
What activities will be carried out in order to meet the research goals? (Ex: Interviews, Usability Test, Card Sorting, etc.)
### **Research Output**
What key artifacts will be delivered at the end of the research program? How will the outputs be used?`

export const INSIGHT_TEMPLATE = `### **What we saw/heard**
How people think, what they feel, and what they said. What's the pattern or theme in the data?
### **What it means**
Why do we think people feel/do this? Best guess, intuitive leaps.
### **Why it matters**
What are the implications of this insight?
### **Opportunities/Recommendations**
Transform into actionable, desirable outcome(s). What needs to happen to move this forward in a meaningful way?`
