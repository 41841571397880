import React, { useRef } from 'react';
import useMinimizeOnScroll from '../Hooks/useMinimizeOnScroll';

export default function AudioPlayer({
  src,
  srcType,
}: {
  src: string;
  srcType: string;
}): JSX.Element {
  const playerRef = useRef<HTMLAudioElement>(null);
  const [isMinimized, playerRect, handleOnLoad] = useMinimizeOnScroll(playerRef);

  return (
    <div className={' bg-white'}>
      {isMinimized && <div style={{ height: playerRect.height, width: '800px' }}></div>}
      <audio
        onLoadedData={handleOnLoad}
        ref={playerRef}
        style={
          !isMinimized
            ? {
                width: '80%',
                zIndex: 3,
              }
            : {
                position: 'fixed',
                right: '20px',
                bottom: '60px',
                width: '20vw',
                zIndex: 3,
              }
        }
        controls
      >
        <source src={src} type={srcType} />
      </audio>
    </div>
  );
}
