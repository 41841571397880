import React, { SyntheticEvent, useRef, useEffect } from 'react';
import useMinimizeOnScroll from '../Hooks/useMinimizeOnScroll';

export default function VideoPlayer({
  src,
  timeOffset,
}: {
  src: string;
  timeOffset?: number | null;
}): JSX.Element {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [isMinimized, playerRect, handleOnLoad] = useMinimizeOnScroll(playerRef);

  const onVideoLoad = (e: SyntheticEvent<HTMLVideoElement>) => {
    handleOnLoad();
    if (timeOffset) e.currentTarget.currentTime = timeOffset;
  };

  useEffect(() => {
    if (playerRef.current && timeOffset) playerRef.current.currentTime = timeOffset;
  }, [timeOffset]);

  return (
    <div className={' bg-white'}>
      {isMinimized && <div style={{ height: playerRect.height, width: '100%' }}></div>}
      <video
        onLoadedData={onVideoLoad}
        ref={playerRef}
        style={
          !isMinimized
            ? {
                width: '100%',
                zIndex: 3,
              }
            : {
                position: 'fixed',
                right: '20px',
                bottom: '60px',
                width: '20vw',
                zIndex: 3,
              }
        }
        controls
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
}
