import Konva from 'konva';
import React, { useCallback } from 'react';
import { Rect, Transformer } from 'react-konva';
import * as KonvaUtils from 'react-konva-utils';
import { toast } from 'react-toastify';
import useNotes from '../Hooks/useNotes';
import useThemes from '../Hooks/useThemes';
import { Sticky, Theme } from '../Models';
import GroupSelectionMenu from './GroupSelectionMenu';

interface Props {
  transformerRef: React.RefObject<Konva.Transformer>;
  selectionBoundingBoxRef: React.RefObject<Konva.Rect>;
  selected: boolean;
  dashboardId: string;
  themes: Theme[];
  makeThemeFromStickies: (x: number, y: number, stickies: Sticky[]) => Promise<void>;
  getSticky: (id: string) => Sticky;
  unselect: () => void;
}

const GroupSelection = ({
  transformerRef,
  selectionBoundingBoxRef,
  selected,
  dashboardId,
  themes,
  makeThemeFromStickies,
  getSticky,
  unselect,
}: Props): JSX.Element => {
  const nodesInGroup = transformerRef.current?.nodes() || [];
  const groupColors = nodesInGroup.map(({ attrs }) => attrs.color);
  const color = groupColors?.[0];

  const { updateNote, deleteNote } = useNotes(dashboardId);
  const { updateTheme, deleteTheme } = useThemes(dashboardId);

  const onTheme = useCallback(async () => {
    if (!transformerRef.current) {
      return;
    }
    const nodesInGroup = transformerRef.current.nodes();
    const themesInSelection = nodesInGroup.filter(node => node.attrs.id.startsWith("theme-"))
    const transformerRefX = transformerRef.current.x()
    const transformerRefY = transformerRef.current.y()
    if (themesInSelection.length === 0) {
      // create a new theme for all these loose stickies
      transformerRef.current.nodes([])
      await makeThemeFromStickies(
        (transformerRefX - (transformerRef.current.getStage()?.x() ?? 0)) / (transformerRef.current.getStage()?.scaleX() ?? 1),
        (transformerRefY - (transformerRef.current.getStage()?.y() ?? 0)) / (transformerRef.current.getStage()?.scaleY() ?? 1),
        nodesInGroup.map(node => getSticky(node.attrs.name)));
    } else if (themesInSelection.length === 1) {
      // add the loose stickies to the theme
      const themeId = themesInSelection[0].attrs.name
      await Promise.all(nodesInGroup.filter(node => node.attrs.id.startsWith("sticky-")).map(stickyNode => updateNote(stickyNode.attrs.name, { themeId })))
      transformerRef.current.nodes([])
    } else {
      // add everything to a new theme; delete the old
      const looseStickies = nodesInGroup.filter(node => node.attrs.id.startsWith("sticky-")).map(stickyNode => stickyNode.attrs.name)
      const themeNodes = nodesInGroup.filter(node => node.attrs.id.startsWith("theme-"))
      const themedStickies = themeNodes
        .map(themeNode => themes.find(theme => theme.id === themeNode.attrs.name))
        .flatMap(theme => theme?.notes || [])
        .map(sticky => sticky.id)
      // create the merged theme
      transformerRef.current.nodes([])
      await makeThemeFromStickies(
        (transformerRefX - (transformerRef.current.getStage()?.x() ?? 0)) / (transformerRef.current.getStage()?.scaleX() ?? 1),
        (transformerRefY - (transformerRef.current.getStage()?.y() ?? 0)) / (transformerRef.current.getStage()?.scaleY() ?? 1),
        [ ...looseStickies, ...themedStickies ].map(getSticky));
      // delete the old themes
      await Promise.all(themeNodes.map(themeNode => deleteTheme(themeNode.attrs.name)))
    }
    unselect()
  }, [deleteTheme, getSticky, makeThemeFromStickies, themes, transformerRef, unselect, updateNote])

  const onColorChange = useCallback(async (newColor) => {
    const nodesInGroup = transformerRef.current?.nodes() || [];
    try {
      await Promise.all(nodesInGroup.map(node => {
        if (node.attrs.id.startsWith("sticky-")) {
          return updateNote(node.attrs.name, { color: newColor }).then(() => null);
        } else if (node.attrs.id.startsWith("theme-")) {
          return updateTheme(node.attrs.name, { color: newColor }).then(() => null);
        }
      }));
    } catch(e) {
      console.error(e)
      toast.error('An unexpected error occurred.');
    }
  }, [transformerRef, updateNote, updateTheme])

  const onDelete = useCallback(async () => {
    const nodesInGroup = transformerRef.current?.nodes() || [];
    try {
      await Promise.all(nodesInGroup.flatMap(node => {
        if (node.attrs.id.startsWith("sticky-")) {
          return [deleteNote(node.attrs.name)];
        } else if (node.attrs.id.startsWith("theme-")) {
          const stickyDeletions = themes.filter(theme => theme.id == node.attrs.name).flatMap(theme => theme.notes)
          return [ ...stickyDeletions.map(sticky => deleteNote(sticky.id)), deleteTheme(node.attrs.name)]
        }
      }));
      transformerRef.current?.nodes([])
      unselect()
    } catch(e) {
      console.error(e)
      toast.error('An unexpected error occurred.');
    }
  }, [transformerRef, unselect, deleteNote, themes, deleteTheme])

  return (
    <>
    {/* group of stickies/themes with border */}
      <Transformer
        id="transformer"
        ref={transformerRef}
        borderStroke="#3B2651"
        name="transformer"
        rotateEnabled={false}
        anchorSize={8}
        anchorStroke="#3B2651"
        enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
        shouldOverdrawWholeArea
      >
        {/* group menu */}
        {selected && (
          <KonvaUtils.Html
            divProps={{
              style: {
                x: (transformerRef.current?.x() ?? 0) + (transformerRef.current?.width() ?? 0) / 2,
                y: transformerRef.current?.y(),
              },
            }}
            transformFunc={({ ...attrs }) => {
              return {
                ...attrs,
                x: (transformerRef.current?.x() ?? 0) + (transformerRef.current?.width() ?? 0) / 2,
                y: transformerRef.current?.y() ?? 0,
              };
            }}
          >
            <GroupSelectionMenu color={color} onColorChange={onColorChange} onTheme={onTheme} onDelete={onDelete} />
          </KonvaUtils.Html>
        )}
      </Transformer>
      {/* user-drawn selection bounding box */}
      <Rect
        fill="rgba(59, 38, 81, 0.15)"
        stroke="#3B2651"
        strokeWidth={2}
        fillAfterStrokeEnabled
        visible={false}
        ref={selectionBoundingBoxRef}
        perfectDrawEnabled={false}
      />
    </>
  );
};

export default GroupSelection;
