import { useAuth0 } from '@auth0/auth0-react';

export default function useFeatureFlags() {
  const { user } = useAuth0();

  function isFeatureEnabled(featureName: string) {
    if (import.meta.env.DEV) {
      return true;
    }
    if (featureName === 'zoom') {
      // Enable zoom for everyone
      return true;
    }
    return /notably\.ai/.test(user?.email ?? '');
  }

  return {
    isFeatureEnabled,
  };
}
