import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FETCH_DASHBOARD_ALL } from '../GraphQL/queries';
import { Loader } from '.';
import useBoards from '../Hooks/useBoards';

function CopyBoard({ dashboardId }: { dashboardId: string }): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const history = useHistory();

  const { loading, data } = useQuery(FETCH_DASHBOARD_ALL, { variables: { dashboardId } });

  const { copyBoard } = useBoards();

  async function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    setProcessing(true);
    e.preventDefault();
    const board = await copyBoard(data.dashboard);

    history.push(`/projects/${board.id}/info`);
  }

  if (loading || processing) {
    return <Loader isFullScreen={false} />;
  }

  return (
    <form className="">
      <div className="mb-3">
        This will copy {'"'}
        {data?.dashboard?.name}
        {'"'}. Do you want to continue?
      </div>
      <div className="flex items-center justify-between w-full">
        <button
          type="submit"
          className={
            'bg-primary-purple text-white flex flex-row items-center rounded-sm-md py-2 px-3'
          }
          onClick={handleClick}
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default CopyBoard;
