import React, { useEffect } from 'react';
import Button from '../Components/Button';
import { useMutation, useQuery } from '@apollo/client';
import { AUTHORIZE_ZOOM, DELETE_INTEGRATION } from '../GraphQL/mutations';
import { useHistory } from 'react-router';
import { Loader } from '../Components';
import { FETCH_INTEGRATIONS } from '../GraphQL/queries';
import { TrashIcon } from '@heroicons/react/outline';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import { TableSimple, TableCell, TableRow } from '../Components/TableSimple';
import { ID } from '../Models';
import { useAuth0 } from '@auth0/auth0-react';

const REDIRECT_URI =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '') +
  '/settings/integrations';

const ZOOM_CLIENT_ID = import.meta.env.VITE_ZOOM_CLIENT_ID ?? 'hbyj35HCRAGZsLGghU7TXA';

const ZOOM_PUBLISHABLE_URL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;

export default function Integrations() {
  const [mutation] = useMutation(AUTHORIZE_ZOOM);
  const [deleteMutation] = useMutation(DELETE_INTEGRATION);
  const { data, loading, refetch } = useQuery(FETCH_INTEGRATIONS);
  const { user } = useAuth0();
  const history = useHistory();

  const url = new URL(window.location.href);

  const code = url.searchParams.get('code');

  useEffect(() => {
    if (code) {
      mutation({
        variables: {
          code,
          redirectUri: REDIRECT_URI,
        },
      })
        .then(refetch)
        .finally(() => {
          history.replace('/settings/integrations');
        });
    }
  }, [code]);

  async function handleDelete(id: ID) {
    await deleteMutation({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: FETCH_INTEGRATIONS,
        },
      ],
    });
    await refetch();
  }

  if (loading || code) {
    return <Loader />;
  }

  const zoomConnected = data.integrations?.find(
    (x: any) => x.type === 'zoom' && x.createdBy === user?.['https://notably.ai/claims/user_id']
  );

  return (
    <>
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <h1 className={'text-l font-medium mt-1'}>Settings / Integrations</h1>
        </div>
      </SecondaryToolbar>
      <div className="flex h-full justify-center pt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <TableSimple>
          <TableRow hover={false}>
            <TableCell>
              <div className={'flex-col'}>
                Zoom Integration
                <div className={'text-sm py-1 block'}>
                  Zoom Integration allows you to import Zoom Cloud Recordings and Transcripts into
                  Notably.
                </div>
              </div>
            </TableCell>
            <TableCell>
              {!zoomConnected && (
                <Button
                  onClick={() => {
                    window.location.href = ZOOM_PUBLISHABLE_URL;
                  }}
                  type={'primary'}
                  size={'sm'}
                >
                  Connect
                </Button>
              )}
              {zoomConnected && (
                <Button
                  onClick={() => handleDelete(zoomConnected.id)}
                  type={'secondary'}
                  size={'sm'}
                  icon={<TrashIcon className={'w-4 h-4 mr-1'} />}
                >
                  Disconnect
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableSimple>
      </div>
    </>
  );
}
