import { gql } from '@apollo/client/core';
import {
  BOARD_CORE_FIELDS,
  FILE_CORE_FIELDS,
  NOTE_CORE_FIELDS,
  THEME_CORE_FIELDS,
  TRANSCRIPT_CORE_FIELDS,
  PROJECT_INFO_CORE_FIELDS,
  PROJECT_INSIGHT_CORE_FIELDS,
  DOCUMENT_CORE_FIELDS,
  PARTICIPANT_CORE_FIELDS,
} from './fragments';

const FETCH_NOTES = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  query getNotes($condition: NoteCondition) {
    notes(condition: $condition) {
      ...NoteCoreFields
      theme {
        ...ThemeCoreFields
      }
      participant {
        id
        name
      }
    }
  }
`;

const FETCH_DASHBOARD_TAGS = gql`
  query getDashboardTags($dashboardId: BigInt!) {
    notes(condition: { dashboardId: $dashboardId }) {
      tagsByItemId {
        id
        name
      }
    }
    transcriptions(condition: { dashboardId: $dashboardId }) {
      id
      tags
    }
    documents(condition: { dashboardId: $dashboardId }) {
      id
      tags
    }
  }
`;

const FETCH_THEMES = gql`
  ${THEME_CORE_FIELDS}
  query getThemesByDashboardId($dashboardId: BigInt!) {
    themes(condition: { dashboardId: $dashboardId }) {
      ...ThemeCoreFields
    }
  }
`;

const FETCH_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  ${PROJECT_INFO_CORE_FIELDS}
  query getDashboard($dashboardId: BigInt!) {
    dashboard(id: $dashboardId) {
      ...BoardCoreFields
      themes {
        ...ThemeCoreFields
      }
      projectInfo {
        ...ProjectInfoCoreFields
      }
    }
  }
`;

const FETCH_DASHBOARD_ALL = gql`
  ${BOARD_CORE_FIELDS}
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  ${PROJECT_INFO_CORE_FIELDS}
  ${FILE_CORE_FIELDS}
  ${TRANSCRIPT_CORE_FIELDS}
  query getDashboardAll($dashboardId: BigInt!) {
    dashboard(id: $dashboardId) {
      ...BoardCoreFields
      themes {
        ...ThemeCoreFields
      }
      notes {
        ...NoteCoreFields
        theme {
          ...ThemeCoreFields
        }
      }
      files {
        ...FileCoreFields
        transcription {
          ...TranscriptionCoreFields
        }
      }
      projectInfo {
        ...ProjectInfoCoreFields
      }
      transcriptions {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const FETCH_DASHBOARDS = gql`
  ${BOARD_CORE_FIELDS}
  query getDashboards($userId: BigInt) {
    dashboards(condition: { createdBy: $userId }) {
      ...BoardCoreFields
      notes {
        id
      }
      themes {
        id
      }
    }
  }
`;

const FETCH_TAGS = gql`
  query tags {
    tags {
      id
      name
    }
  }
`;

const FETCH_TRANSCRIPTS = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchTranscriptions($dashboardId: BigInt!) {
    transcriptions(condition: { dashboardId: $dashboardId }) {
      ...TranscriptionCoreFields
    }
  }
`;

const FETCH_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  ${FILE_CORE_FIELDS}
  query fetchTranscription($id: BigInt!) {
    transcription(id: $id) {
      ...TranscriptionCoreFields
      file {
        ...FileCoreFields
        signedVideoUrl
      }
      participantId
      participant {
        id
        name
      }
    }
  }
`;

const FETCH_FILES = gql`
  ${FILE_CORE_FIELDS}
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchFiles($dashboardId: BigInt!) {
    files(condition: { dashboardId: $dashboardId }) {
      ...FileCoreFields
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const FETCH_FILE = gql`
  ${FILE_CORE_FIELDS}
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchFile($id: BigInt!) {
    file(id: $id) {
      ...FileCoreFields
      signedVideoUrl
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const FETCH_PROJECT_INFO = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  query fetchProjectInfo($id: BigInt!) {
    projectInfo(id: $id) {
      ...ProjectInfoCoreFields
    }
  }
`;

const FETCH_PROJECT_INFO_BY_DASHBOARD_ID = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  query fetchProjectInfo($dashboardId: BigInt!) {
    projectInfoByDashboardId(dashboardId: $dashboardId) {
      ...ProjectInfoCoreFields
    }
  }
`;

const FETCH_PROJECT_INSIGHTS = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  query fetchProjectInsights($dashboardId: BigInt!) {
    projectInsights(condition: { dashboardId: $dashboardId }, orderBy: UPDATED_AT_DESC) {
      ...ProjectInsightCoreFields
    }
  }
`;

const FETCH_PROJECT_INSIGHT_BY_CLIENT_ID = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  query fetchProjectInsight($clientId: String!) {
    projectInsightByClientId(clientId: $clientId) {
      ...ProjectInsightCoreFields
    }
  }
`;

const FETCH_INTEGRATIONS = gql`
  query fetchIntegrations {
    integrations {
      id
      type
      createdBy
    }
  }
`;

const FETCH_ZOOM_CLOUD_RECORDINGS = gql`
  query fetchZoom($pageSize: Int, $continuationToken: String, $from: String, $to: String) {
    zoomCloudRecordings(
      pageSize: $pageSize
      continuationToken: $continuationToken
      from: $from
      to: $to
    ) {
      nextPageToken
      recordings {
        topic
        startTime
        shareUrl
        videoDownloadUrl
        audioDownloadUrl
        transcript
      }
    }
  }
`;

const FETCH_DOCUMENTS = gql`
  ${DOCUMENT_CORE_FIELDS}
  query fetchDocuments($dashboardId: BigInt!) {
    documents(condition: { dashboardId: $dashboardId }, orderBy: UPDATED_AT_DESC) {
      ...DocumentCoreFields
    }
  }
`;

const FETCH_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  query fetchDocument($id: BigInt!) {
    document(id: $id) {
      ...DocumentCoreFields
      notesConnection {
        totalCount
      }
      notes {
        id
      }
      participantId
      participant {
        id
        name
      }
    }
  }
`;

const FETCH_PARTICIPANTS = gql`
  ${PARTICIPANT_CORE_FIELDS}
  query fetchParticipants {
    participants(orderBy: NAME_ASC) {
      ...ParticipantCoreFields
    }
  }
`;

const FETCH_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  query fetchParticipant($id: BigInt!) {
    participant(id: $id) {
      ...ParticipantCoreFields
      documents {
        id
        dashboardId
        name
        updatedAt
        createdAt
        userByCreatedBy {
          name
        }
      }
      transcriptions {
        id
        dashboardId
        name
        updatedAt
        userByCreatedBy {
          name
        }
      }
    }
  }
`;

const FETCH_USERS = gql`
  query fetchUsers {
    users {
      id
      name
      email
      avatar
      role
      inviteAccepted
    }
  }
`;

const CURRENT_ORG = gql`
  query currentOrganization {
    currentOrganization {
      id
      plan
      name
      logo
      projectsCount
      projectsLimitExceeded
      durationLimitExceeded
      fileLimitExceeded
      durationLimitTotal
      durationLimitMonthly
      projectsLimitTotal
      fileSizeLimitTotal
    }
  }
`;

export {
  FETCH_USERS,
  FETCH_NOTES,
  FETCH_DASHBOARD,
  FETCH_DASHBOARDS,
  FETCH_THEMES,
  FETCH_TAGS,
  FETCH_TRANSCRIPTS,
  FETCH_TRANSCRIPT,
  FETCH_FILE,
  FETCH_FILES,
  FETCH_PROJECT_INFO,
  FETCH_PROJECT_INFO_BY_DASHBOARD_ID,
  FETCH_DASHBOARD_ALL,
  FETCH_PROJECT_INSIGHTS,
  FETCH_PROJECT_INSIGHT_BY_CLIENT_ID,
  FETCH_INTEGRATIONS,
  FETCH_ZOOM_CLOUD_RECORDINGS,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENT,
  FETCH_PARTICIPANTS,
  FETCH_PARTICIPANT,
  CURRENT_ORG,
  FETCH_DASHBOARD_TAGS,
};
