import * as React from 'react';
import { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import CollapseIcon from '../Icons/CollapseIcon';
import ExpandIcon from '../Icons/ExplandIcon';
import XIcon from '../Icons/XIcon';

type PanelHeaderProps = {
  title: string;
  expanded?: boolean;
  onChange?: (value: boolean) => void;
  onClose?: (value: boolean) => void;
  icon: JSX.Element;
  open: boolean;
};

type PanelProps = PropsWithChildren<{
  title: string | any;
  expanded?: boolean;
  visible?: boolean;
  onChange?: (value: boolean) => void;
  headerControl?: typeof PanelHeader | null;
  style?: CSSProperties;
  position: 'left' | 'right' | 'center';
  onResize?: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: JSX.Element;
  open?: boolean;
  content: (width: number, expanded?: boolean) => JSX.Element;
}>;

const positionStyles = {
  left: {
    zIndex: 99,
    position: 'fixed',
    left: 0,
  },
  right: {
    zIndex: 99,
    // position: 'fixed',
    // right: 0,
  },
  center: {
    // zIndex: 99,
  },
};

function PanelHeader({
  icon,
  open,
  title,
  expanded,
  onChange,
  onClose,
}: PanelHeaderProps): JSX.Element {
  if (!open) {
    return (
      <div className={'w-full flex justify-center'}>
        <button className={'mt-3'} onClick={() => onClose?.(true)}>
          {icon}
        </button>
      </div>
    );
  }

  return (
    <div className="top-0 bg-white z-100 pt-3.5 flex flex-row justify-between items-center pb-3 px-3 border-b border-secondary-gray-dark">
      <div className="font-body font-medium text-md text-font-black">{title}</div>
      <div>
        <button onClick={() => onChange?.(!expanded)}>
          {expanded ? <CollapseIcon /> : <ExpandIcon />}
        </button>
        <button className="ml-2" onClick={() => onClose?.(false)}>
          <XIcon />
        </button>
      </div>
    </div>
  );
}

export const DEFAULT_PANEL_WIDTH = 96 * 4;

export const CLOSED_PANEL_WIDTH = 36;

export default function Panel({
  title,
  position,
  open = false,
  visible = true,
  content,
  headerControl = PanelHeader,
  icon = <DocumentTextIcon className="text-secondary-purple-dark w-6 h-6" />,
  ...props
}: PanelProps): JSX.Element {
  const mergedStyles = { ...props.style, ...(positionStyles[position] as CSSProperties) };

  const resizing = useRef<number | null>(null);
  const [width, setWidth] = useState(DEFAULT_PANEL_WIDTH);
  const [isOpen, setIsOpen] = useState(open);
  const [expanded, setExpanded] = useState(false);

  const [childWidth, setChildWidth] = useState(width);

  const transitionSpeed = 300;

  function onMouseUp() {
    if (resizing.current) {
      setChildWidth(width);
    }
    resizing.current = null;
  }

  function onMouseDown(e: React.MouseEvent<HTMLDivElement>) {
    if (!isOpen) {
      return;
    }
    resizing.current = e.screenX;
  }

  function onMouseMove(e: MouseEvent) {
    if (!resizing.current) {
      return;
    }

    resizing.current = e.screenX;

    const screenX = e.screenX;

    requestAnimationFrame(() => {
      if (position == 'left') {
        setWidth(Math.max(DEFAULT_PANEL_WIDTH, screenX));
      }

      if (position == 'right') {
        setWidth(Math.max(DEFAULT_PANEL_WIDTH, window.innerWidth - screenX));
      }
    });
  }

  useEffect(() => {
    if (!isOpen) {
      setWidth(CLOSED_PANEL_WIDTH);
      setTimeout(() => {
        setChildWidth(CLOSED_PANEL_WIDTH);
      }, transitionSpeed);
      return;
    }
    if (expanded) {
      const value = window.innerWidth - CLOSED_PANEL_WIDTH + 1;
      setWidth(value);
      setTimeout(() => {
        setChildWidth(value);
      }, transitionSpeed);
    } else {
      const value = DEFAULT_PANEL_WIDTH;
      setWidth(value);
      setTimeout(() => {
        setChildWidth(value);
      }, transitionSpeed);
    }
  }, [expanded, isOpen]);

  function ResizeBar() {
    return <div onMouseDown={onMouseDown} className="w-1 bg-gray-100 cursor-col-resize" />;
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseMove]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div
      className={
        'select-none flex h-full bg-white overflow-hidden' +
        (visible ? '' : ' hidden') +
        (resizing.current ? ' cursor-col-resizing' : '')
      }
      {...props}
      style={{
        ...mergedStyles,
        width: width + 'px',
        transition: `width ${transitionSpeed / 1000}s`,
      }}
    >
      {(!expanded || !isOpen) && position == 'right' && <ResizeBar />}
      <div className={'flex-1 w-full justify-center'}>
        {headerControl &&
          headerControl({
            icon,
            open: isOpen,
            title,
            expanded,
            onChange: (value) => setExpanded(value),
            onClose: (value) => setIsOpen(value),
          })}
        {isOpen && content(childWidth - 2, expanded)}
      </div>
      {(!expanded || !isOpen) && position == 'left' && <ResizeBar />}
    </div>
  );
}
