import React, { useEffect, useMemo, useState } from 'react';
import { InfiniteCanvas, Loader } from '../Components';
import { useQuery } from '@apollo/client';
import { FETCH_NOTES } from '../GraphQL/queries';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import { SelectableContext } from '../Components/Selectable';
import Panel from '../Components/Panel';
import { Sticky } from '../Models';
import NotesTable from '../Components/NotesTable';
import SearchToolBar from '../Components/SearchToolBar';
import useTags from '../Hooks/useTags';
import useThemes from '../Hooks/useThemes';
import useNotes from '../Hooks/useNotes';
import SheetIcon from '../Icons/SheetIcon';
import { useSegment } from 'react-segment-hooks';
import InsightsPanel from '../Components/InsightsPanel';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { getFromStickies as getParticipantsFromStickies } from '../Hooks/useParticipants';

function InsightsTitle(): JSX.Element {
  const match = useRouteMatch<{ projectId: string }>(
    '/projects/:projectId/notes/insights/:insightId'
  );

  if (match?.isExact) {
    return (
      <Link to={'/projects/' + match.params?.projectId + '/notes'}>
        <ArrowLeftIcon className={'w-6 h-6'} />
      </Link>
    );
  }

  return <>Insights</>;
}

function Notes(): JSX.Element {
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const analytics = useSegment();

  useEffect(() => {
    analytics.page({
      name: 'Notes',
      properties: {
        id: dashboardId,
      },
    });
  }, []);

  const { loading, data } = useQuery(FETCH_NOTES, { variables: { condition: { dashboardId } } });

  const [editable, setEditable] = useState('');
  const [selected, setSelected] = useState('');

  const stickies = useMemo(() => data?.notes?.map((x: Sticky) => ({ ...x })) ?? [], [data]);

  function getPanelProps(panelName: 'left' | 'right' | 'center') {
    return {
      position: panelName,
    };
  }

  const { getFromStickies } = useThemes(parseInt(dashboardId));

  const { getFromStickies: getTagsFromStickies } = useTags();
  const { searchStickyByText } = useNotes(dashboardId);

  const [searchQuery, setSearchQuery] = useState('');
  const [groupBy, setGroupGy] = useState<string[]>(['theme']);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<number[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);

  const tags = getTagsFromStickies(stickies);

  // TODO: need to deeply test that
  // search tool bar is not using all data from them maps
  // it doesn't use notes.
  // so can we keep it simpler and just pass array of themes without nesting stickies inside?
  const stickesWithJustTheme = stickies.map((sticker: Sticky) => ({
    id: sticker.id,
    theme: sticker.theme,
  }));
  const themes = React.useMemo(() => {
    return getFromStickies(stickies);
  }, [JSON.stringify(stickesWithJustTheme)]);
  const participants = getParticipantsFromStickies(stickies);

  const filteredStickies = useMemo(() => {
    return searchStickyByText(
      stickies,
      searchQuery,
      selectedTags,
      selectedThemes,
      selectedParticipants
    ).map((s) => ({ ...s, data: s.document ?? s.transcription }));
  }, [stickies, searchQuery, selectedTags, selectedThemes, selectedParticipants]);

  if (loading && !data) {
    return <Loader />;
  }

  return (
    <div className={'flex flex-col flex-auto'}>
      <SearchToolBar
        dashboardId={dashboardId}
        tags={tags}
        themes={themes}
        participants={participants}
        onTagChange={setSelectedTags}
        onThemeChange={setSelectedThemes}
        onParticipantChange={setSelectedParticipants}
        onSearchChange={(text) => setSearchQuery(text)}
        onGroupByChange={setGroupGy}
      />
      <div className="flex flex-auto relative split-view-container">
        <SelectableContext.Provider value={[selected, setSelected, editable, setEditable]}>
          <Panel
            title="Notes"
            open={true}
            {...getPanelProps('left')}
            icon={<SheetIcon width={18} height={18} />}
            content={(width) => (
              <NotesTable width={width} dashboardId={dashboardId} stickies={filteredStickies} />
            )}
          />
          <InfiniteCanvas dashboardId={dashboardId} groupBy={groupBy} stickies={filteredStickies} />
          <Panel
            title={<InsightsTitle />}
            {...getPanelProps('right')}
            open={false}
            content={(width, expanded) => {
              return <InsightsPanel dashboardId={dashboardId} width={width} expanded={expanded} />;
            }}
          />
        </SelectableContext.Provider>
      </div>
    </div>
  );
}

export default Notes;
