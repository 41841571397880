import React from 'react';

export default function PersonIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 7.5C16 9.70914 14.2091 11.5 12 11.5C9.79086 11.5 8 9.70914 8 7.5C8 5.29086 9.79086 3.5 12 3.5C14.2091 3.5 16 5.29086 16 7.5Z" stroke="#382152" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 14.5C8.13401 14.5 5 17.634 5 21.5H19C19 17.634 15.866 14.5 12 14.5Z" stroke="#382152" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
