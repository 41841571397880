import React, { ComponentProps, DetailedHTMLProps, HTMLProps, InputHTMLAttributes } from 'react';
import { Text } from 'react-konva';
import { Selectable } from './Selectable';
import { NodeConfig } from 'konva/lib/Node';
import ThemeMenu from './ThemeMenu';
import useThemes from '../Hooks/useThemes';

interface ThemeProps extends NodeConfig {
  text: string;
  defaultStep: number;
  color: string;
  fontSize: number;
  spacing: number;
  showText: boolean;

  editorProps?: HTMLProps<HTMLInputElement>;
  selectableProps?: Partial<ComponentProps<typeof Selectable>>;
}

function Theme({
  id,
  x,
  y,
  width,
  height,
  color,
  text,
  fontSize,
  spacing,
  children,
  selectableProps,
  dashboardId,
  showText,
  editorProps,
}: ThemeProps): JSX.Element {
  const listenForEnterKeypress = (
    e: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  ) => {
    switch (e.key) {
      case 'Enter':
        document.getElementById(`theme-canvas-input-${id}`)?.blur();
        break;
      default:
      // do nothing
    }
  };

  const { updateTheme, deleteTheme } = useThemes(dashboardId);

  async function onColorChange(color: string) {
    await updateTheme('' + id, { color });
  }

  async function onDelete() {
    await deleteTheme('' + id);
  }

  const onEdit = () => {
    selectableProps?.onChange?.({
      selected: true,
      editable: !selectableProps?.editable,
    });
  };

  const parts = text.split(' ');
  const longestWord = parts.reduce((i, j) => (j.length > i.length ? j : i));

  const scaleFontSize = (): number => {
    return Math.min(
      (width ?? 1) / Math.max(longestWord.length * 0.75, 5),
      (height ?? 1) / Math.max(parts.length)
    );
  };

  return (
    <Selectable
      id={`theme-${id}`}
      name={`${id}`}
      x={x}
      y={y}
      cache={false}
      width={width}
      height={height}
      color={color}
      inverted={true}
      simplified={!showText}
      menu={
        <ThemeMenu
          color={color}
          onEdit={onEdit}
          onDelete={onDelete}
          onColorChange={onColorChange}
        />
      }
      editor={
        <input
          id={`theme-canvas-input-${id}`}
          className="bg-white font-body font-medium outline-none border-none"
          style={{
            width: `${(width ?? 0) - 2 * spacing}px`,
            top: (y ?? 0) + spacing,
            left: (x ?? 0) + spacing,
            fontSize: `${fontSize}px`,
            lineHeight: `${fontSize * 1.25}px`,
            overflow: 'hidden',
            margin: spacing,
          }}
          onFocus={(e) => e.target.select()}
          type="text"
          defaultValue={text ?? ''}
          onKeyDown={listenForEnterKeypress}
          autoFocus
          {...editorProps}
        />
      }
      {...selectableProps}
    >
      {children}
      <Text
        hitStrokeWidth={0}
        listening={false}
        strokeScaleEnabled={false}
        shadowEnabled={false}
        transformsEnabled={'position'} // position transform only
        text={text}
        fill="#382152"
        fontSize={showText ? fontSize : scaleFontSize()}
        lineHeight={1.25}
        align={showText ? 'left' : 'center'}
        padding={spacing}
        fontStyle={showText ? 'normal' : 'bold'}
        fontFamily="Inter"
        verticalAlign={showText ? 'top' : 'middle'}
        textDecoration="uppercase"
        wrap="word"
        width={width}
        height={height}
      />
    </Selectable>
  );
}

export default Theme;
