import { default as Discovery } from '../assets/projects/default-icons/Discovery.svg';
import { default as IdeaSeed } from '../assets/projects/default-icons/Idea-Seed.svg';
import { default as Observation } from '../assets/projects/default-icons/Observation.svg';
import { default as RedHerring } from '../assets/projects/default-icons/Red-Herring.svg';
import { default as RedThread } from '../assets/projects/default-icons/Red-Thread.svg';
import { default as SacredCow } from '../assets/projects/default-icons/Sacred-Cow.svg';
import { default as SynthesisSpark } from '../assets/projects/default-icons/Synthesis-Spark.svg';
import { default as UserTesting } from '../assets/projects/default-icons/User-Testing.svg';

export const defaultProjectIcons = [
  { name: 'Discovery', url: Discovery, position: '50% 50%' },
  { name: 'IdeaSeed', url: IdeaSeed, position: '50% 50%' },
  { name: 'Observation', url: Observation, position: '50% 50%' },
  { name: 'RedHerring', url: RedHerring, position: '50% 50%' },
  { name: 'RedThread', url: RedThread, position: '50% 50%' },
  { name: 'SacredCow', url: SacredCow, position: '50% 50%' },
  { name: 'SynthesisSpark', url: SynthesisSpark, position: '50% 50%' },
  { name: 'UserTesting', url: UserTesting, position: '50% 50%' },
];
