import React, { useEffect, useState } from 'react';

import SecondaryToolbar from '../Components/SecondaryToolbar';
import Button from '../Components/Button';
import Loader from '../Components/Loader';
import { useSegment } from 'react-segment-hooks';
import useUsers from '../Hooks/useUsers';
import {
  HeaderCell,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '../Components/TableWithBorders';
import Input from '../Components/Input';
import { toast } from 'react-toastify';

function Users(): JSX.Element {
  const analytics = useSegment();

  useEffect(() => {
    analytics.page({
      name: 'Users',
    });
  }, []);

  const { inviteUser, fetchUsers, deleteUser } = useUsers();

  const [loading, users] = fetchUsers();

  const [inviteEmail, setInviteEmail] = useState('');

  if (loading) {
    return <Loader />;
  }

  const handleInvite = async (email: string | null = null) => {
    if (!email) {
      email = inviteEmail;
    }

    if (!email) {
      return;
    }
    try {
      await inviteUser(email);

      toast.success('The user has been successfully invited.');
    } catch {
      toast.error(
        'An error occurred. This may happen if the email has been already registered with us. Try another email.'
      );
    }

    setInviteEmail('');
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure that you want to remove this team member?')) {
      return;
    }

    await deleteUser(id);
  };

  function capitalize(str: string) {
    return str.substr(0, 1).toUpperCase() + str.substr(1);
  }

  return (
    <div className="flex-col h-full overflow-hidden">
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <h1 className={'text-l font-medium mt-1'}>Settings / Team Members</h1>
        </div>
      </SecondaryToolbar>

      <div className="flex flex-col pt-2 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={'pb-3 w-80 mt-10'}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className={'flex'}
          >
            <Input
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target?.value ?? '')}
              placeholder={'user@example.com'}
            />
            <Button className={'ml-2'} onClick={async () => await handleInvite()}>
              Invite
            </Button>
          </form>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table className="min-w-full divide-y divide-gray-200">
                <TableHeader>
                  <tr>
                    <HeaderCell scope="col" className="px-6 py-3 text-left">
                      Name
                    </HeaderCell>
                    <HeaderCell scope="col" className="px-6 py-3 text-left">
                      Email
                    </HeaderCell>
                    <HeaderCell scope="col" className="px-6 w-30 py-3 text-left">
                      Role
                    </HeaderCell>
                    <HeaderCell scope="col" className="px-6 w-30 py-3 text-left">
                      Status
                    </HeaderCell>
                    <HeaderCell scope="col" className="px-6 w-30 py-3 text-left" />
                  </tr>
                </TableHeader>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user: any, index: number) => (
                    <TableRow hover={false} key={index}>
                      <TableCell className="px-6 py-4 whitespace-nowrap">
                        {user.name || 'Untitled participant'}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap">{user.email}</TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap">
                        {capitalize(user.role)}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap">
                        {user.inviteAccepted === false ? 'Pending' : 'Active'}
                      </TableCell>
                      <TableCell className="px-6 w-20 py-4 whitespace-nowrap">
                        {user.inviteAccepted === false && (
                          <Button type={'secondary'} onClick={() => handleInvite(user.email)}>
                            Resend invite
                          </Button>
                        )}
                        {(user.inviteAccepted === null || user.inviteAccepted === true) && (
                          <Button type={'secondary'} onClick={() => handleDelete(user.id)}>
                            Remove
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
