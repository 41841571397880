import React, { ReactNode } from 'react';
import Sidebar2 from '../Components/Sidebar2';
import { useOrganization } from '../Hooks/useOrganization';

type DefaultLayoutProps = {
  children: ReactNode;
};

function SidebarLayout({ children }: DefaultLayoutProps): JSX.Element {
  return (
    <div className={'flex-auto w-full flex'}>
      <Sidebar2 />
      <div className={'flex-auto'}>{children}</div>
    </div>
  );
}

export default SidebarLayout;
