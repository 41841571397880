import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory, useParams, Route } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import {
  DocumentTextIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  PlusIcon,
  TrashIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { Loader, Modal } from '../Components';
import Well from '../Components/Well';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Button from '../Components/Button';
import Avatar from '../Components/Avatar';
import useFiles from '../Hooks/useFiles';
import { VideoCameraIcon, MusicNoteIcon } from '@heroicons/react/outline';
import useTranscripts from '../Hooks/useTranscripts';
import useUpload from '../Hooks/useUpload';
import { useSegment } from 'react-segment-hooks';
import ReactTooltip from 'react-tooltip';
import ZoomCloudRecordingsListView from '../Components/ZoomCloudRecordingsListView';
import { DropDownMenu } from '../Components/DropDownMenu';
import { Menu } from '@headlessui/react';
import { MenuRow } from '../Components/TableSimple';
import useFeatureFlags from '../Hooks/useFeatureFlags';
import { useFetchDocuments, useDeleteDocument, useCreateDocument } from '../Hooks/useDocuments';
import { checkOrganizationLimits, useOrganization } from '../Hooks/useOrganization';
import { formatDuration, humanFileSize } from '../Utils/formatting';
import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyStateSecondaryText,
  EmptyStateContainer,
  EmptyState,
  EmptyStateTitle,
  EmptyStateText,
  HeroImage,
} from '../Components/EmptyState2';
import dataEmptyImage from '../assets/empty-states/data.png';
import { isVideoOrAudio } from '../Utils/fileTypes';

const StatusText = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: rgba(56, 33, 82, 0.6);
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function Status({ status, statusText }: { status: string; statusText: string }) {
  let icon = <></>;

  if (status === 'PROCESSING') {
    return (
      <div>
        <StatusText>Transcribing...</StatusText>
      </div>
    );
  }
  if (status === 'ERRORED') {
    statusText = 'The transcription process failed. Re-upload the file to try again.';
    icon = <ExclamationCircleIcon className={'w-6 h-6 text-red-500'} />;
    return (
      <StatusWrapper>
        {icon} <StatusText>{statusText}</StatusText>
      </StatusWrapper>
    );
  }

  return <></>;
}

function Data(): JSX.Element {
  const history = useHistory();

  const { dashboardId } = useParams<{ dashboardId: string }>();

  const analytics = useSegment();

  useEffect(() => {
    analytics.page({
      name: 'Data',
      properties: {
        projectId: dashboardId,
      },
    });
  }, []);

  const { fetchFiles, deleteFile } = useFiles();

  const { fetchTranscripts, deleteTranscript } = useTranscripts();

  const { handleFileUpload, handleTranscriptUpload } = useUpload(dashboardId);

  const [loading, files, refetchFiles] = fetchFiles(dashboardId);

  const [loadingTranscripts, transcripts] = fetchTranscripts(dashboardId);

  const [loadingDocuments, documents] = useFetchDocuments(dashboardId);

  const [uploading, setUploading] = useState(false);

  const [, org] = useOrganization();

  const [createDocument] = useCreateDocument();
  const [deleteDocument] = useDeleteDocument();

  const { isFeatureEnabled } = useFeatureFlags();

  const processingFiles = files?.filter((file: any) => file.status === 'PROCESSING') || [];

  useInterval(
    () => {
      if (loading || !files) {
        return;
      }
      refetchFiles();
    },
    // Delay in milliseconds or null to stop it
    processingFiles.length > 0 ? 1000 : null
  );

  const handleClick = (path: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!path) {
      return;
    }
    history.push(path);
  };

  const handleCreateDocument = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const doc = await createDocument(dashboardId);
    history.push(`/projects/${dashboardId}/data/docs/${doc.id}`);
  };

  if (loading || !files) {
    return <Loader />;
  }

  if (loadingTranscripts || !transcripts || loadingDocuments) {
    return <Loader />;
  }

  const fileItems = files?.map((x: any) => ({
    icon:
      x.type === 'audio' ? (
        <MusicNoteIcon className={'w-6 h-6 mr-2'} />
      ) : (
        <VideoCameraIcon className={'w-6 h-6 mr-2'} />
      ),
    name: x.name,
    status: x.status,
    path: x.transcription ? `/projects/${dashboardId}/data/${x.transcription?.id}` : null,
    createdBy: x.userByCreatedBy,
    metadata: [humanFileSize(x.size), x.duration && formatDuration(x.duration)],
    createdAt: x.createdAt,
    deleteAction: async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (confirm(`Are you sure you want to delete "${x.name}"?. This cannot be undone.`)) {
        await deleteFile(x.id);
      }
    },
  }));

  const transcriptItems = transcripts
    ?.filter((x: any) => !x.fileId)
    .map((x: any) => ({
      icon: <DocumentTextIcon className={'w-6 h-6 mr-2'} />,
      name: x.name,
      status: x.status,
      path: `/projects/${dashboardId}/data/${x.id}`,
      createdBy: x.userByCreatedBy,
      createdAt: x.createdAt,
      deleteAction: async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (confirm(`Are you sure you want to delete "${x.name}"?. This cannot be undone.`)) {
          await deleteTranscript(x.id);
        }
      },
    }));

  const documentItems = documents.map((x: any) => ({
    icon: <DocumentIcon className={'w-6 h-6 mr-2'} />,
    name: x.name,
    path: `/projects/${dashboardId}/data/docs/${x.id}`,
    createdBy: x.userByCreatedBy,
    createdAt: x.createdAt,
    deleteAction: async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (confirm(`Are you sure you want to delete "${x.name}"?. This cannot be undone.`)) {
        await deleteDocument(dashboardId, x.id);
      }
    },
  }));

  const allItems = [...transcriptItems, ...fileItems, ...documentItems].sort((x, y) =>
    x.createdAt >= y.createdAt ? -1 : 1
  );

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!checkOrganizationLimits(org, history, ['duration', 'storage'])) {
      return false;
    }

    if (uploading) {
      return;
    }

    if (e.target.files == null || e.target.files.length == 0) {
      return;
    }

    setUploading(true);

    const file = e.target.files[0];

    e.target.value = '';

    analytics.track({
      event: 'AddData',
      properties: {
        ext: file.name.split('.').pop(),
      },
    });

    if (isVideoOrAudio(file.name)) {
      await handleFileUpload(file);
      refetchFiles();
      setUploading(false);
      return;
    }

    await handleTranscriptUpload(file);

    setUploading(false);
  }

  const isZoomEnabled = isFeatureEnabled('zoom');

  const importDataDropdown = (
    <DropDownMenu
      alignLeft
      button={
        <Menu.Button>
          <Button className={uploading ? 'opacity-75 pointer-events-none' : ''} type="secondary">
            <UploadIcon className="w-5 h-5 mr-1" />
            Import data
          </Button>
        </Menu.Button>
      }
    >
      <Menu.Item>
        <label
          className={
            'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100'
          }
          htmlFor={'video_upload'}
        >
          Upload Video
        </label>
      </Menu.Item>
      <Menu.Item>
        <label
          className={
            'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100'
          }
          htmlFor={'audio_upload'}
        >
          Upload Audio
        </label>
      </Menu.Item>
      <Menu.Item>
        <label
          className={
            'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100'
          }
          htmlFor={'srt_upload'}
        >
          Upload Transcript
        </label>
      </Menu.Item>
      {isZoomEnabled && (
        <Menu.Item>
          <MenuRow href="#" onClick={handleClick('/projects/' + dashboardId + '/data/zoom')}>
            Import from Zoom
          </MenuRow>
        </Menu.Item>
      )}
    </DropDownMenu>
  );

  return (
    <div className="flex-col h-full overflow-hidden">
      <Route exact path={'/projects/:dashboardId/data/zoom'}>
        <Modal title={'Select Zoom Cloud Recordings To Import'} isOpen={true}>
          <ZoomCloudRecordingsListView />
        </Modal>
      </Route>
      <input
        id={'video_upload'}
        name={'video_upload'}
        accept={'video/mp4'}
        type={'file'}
        onChange={handleUpload}
        className={'hidden'}
      />
      <input
        id={'audio_upload'}
        name={'audio_upload'}
        accept={'audio/mp3, audio/mp4, audio/x-m4a'}
        type={'file'}
        onChange={handleUpload}
        className={'hidden'}
      />
      <input
        id={'srt_upload'}
        name={'srt_upload'}
        accept={'.srt,.vtt'}
        type={'file'}
        onChange={handleUpload}
        className={'hidden'}
      />
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>Data</h1>
          </div>
          <div>
            {importDataDropdown}
            <Button className="ml-3" onClick={handleCreateDocument}>
              <PlusIcon className="text-white w-5 h-5 mr-1" />
              New document
            </Button>
          </div>
        </div>
      </SecondaryToolbar>
      <Well wellKey="transcript-well">
        In this Data section is where you import raw data such as video, or transcript files. Once
        processed, you can highlight parts of your data to add as notes for analysis.
      </Well>
      {!allItems.length && (
        <EmptyStateContainer>
          <EmptyState>
            <HeroImage src={dataEmptyImage} wide></HeroImage>
            <EmptyStateTitle>Add data to get started</EmptyStateTitle>
            <EmptyStateText>Data includes raw notes, videos, and transcripts.</EmptyStateText>
            <EmptyStateButton onClick={handleCreateDocument}>
              <EmptyStatePlusIcon />
              New document
            </EmptyStateButton>
            <EmptyStateText>or</EmptyStateText>
            <div className="m-3">{importDataDropdown}</div>
            <EmptyStateSecondaryText>
              supported file types are .mp4, .mp3, .m4a, .srt.
            </EmptyStateSecondaryText>
          </EmptyState>
        </EmptyStateContainer>
      )}
      <div className="bg-white flex-col pb-52">
        {allItems?.map((item: any, index: number) => (
          <div
            key={index}
            onClick={handleClick(item.path)}
            className={
              'border-b hover:bg-gray-50 hover:cursor-pointer flex border-gray-200 py-2 px-3 items-center justify-between'
            }
          >
            <div className={'flex'}>
              {item.icon} {item.name || 'Untitled'}
            </div>
            <div className={'flex text-left'}>
              <div className={'text-gray-500 mr-3 flex text-ellipsis'}>
                <Status status={item.status} statusText={item.statusText} />
              </div>
              {item.metadata
                ?.filter((x: any) => x)
                .map((x: string, i: number) => {
                  return (
                    <div key={i} className={'text-gray-500 mr-3 flex text-ellipsis'}>
                      {x}
                    </div>
                  );
                })}
              <div className={'text-gray-500 mr-3 w-46 text-ellipsis'}>
                <StatusText>{moment(item.createdAt).format('MMM Do, YYYY')}</StatusText>
              </div>
              <div className={'flex w-44'}>
                <Avatar user={item.createdBy} />
                <div className={'ml-2 text-ellipsis whitespace-nowrap overflow-hidden'}>
                  {item.createdBy?.name}
                </div>
              </div>
              <div>
                <a onClick={(e) => item.deleteAction(e)}>
                  <TrashIcon className={'w-5 h-5 m-1'} />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ReactTooltip place={'top'} effect={'solid'} />
    </div>
  );
}

export default Data;
