import React, { useState } from 'react';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { OptionTypeBase, components } from 'react-select';
import { Theme, Sticky } from '../Models';
import useThemes from '../Hooks/useThemes';
import Badge from './Badge1';

type Props = {
  value: Theme | null;
  themes: Theme[];
  onChange: (value: OptionTypeBase | null) => void;
  sticky: Sticky;
};

const ThemePicker = ({ value, themes, onChange, sticky }: Props): JSX.Element => {
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const { createTheme } = useThemes(sticky.dashboardId);

  const handleChange = async (newValue: OptionTypeBase) => {
    if (newValue?.action == 'clear') {
      onChange(null);
      return;
    }
    if (newValue?.__isNew__) {
      await createTheme(sticky.dashboardId, { name: newValue?.value }, [sticky]);
    } else {
      onChange(newValue?.value);
    }
  };

  if (!showSelect) {
    return (
      <Container
        onClick={() => {
          setShowSelect(true);
        }}
      >
        {!value && <Placeholder>add theme</Placeholder>}
        {value && <Badge name={value.name} color={value.color} />}
      </Container>
    );
  }

  return (
    <SelectContainer>
      <CreatableSelect
        placeholder="search themes"
        defaultMenuIsOpen
        isClearable
        autoFocus
        onBlur={() => {
          setShowSelect(false);
        }}
        onChange={(value) => handleChange(value as OptionTypeBase)}
        options={themes.map((x: Theme) => ({
          value: x.id,
          label: x.name,
          color: x.color,
        }))}
        value={value ? { value: value.id as string, label: value.name, color: value.color } : null}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,

          // eslint-disable-next-line react/display-name
          MenuList: ({ children, ...rest }) => {
            return (
              <>
                <components.MenuList {...rest}>{children}</components.MenuList>
              </>
            );
          },
        }}
        styles={{
          container(base) {
            return { ...base, ...containerStyle };
          },
          control(base) {
            return { ...base, ...controlStyle };
          },
          menu(base) {
            return {
              ...base,
              ...menuStyle,
            };
          },
          menuList(base) {
            return {
              ...base,
              ...menuListStyle,
            };
          },
          option(base) {
            return {
              ...base,
              ...optionStyle,
            };
          },
          singleValue(base) {
            return {
              ...base,
              ...singleValueStyle,
            };
          },
          multiValue(base) {
            return {
              ...base,
              ...multiValueStyle,
            };
          },
          multiValueLabel(base) {
            return {
              ...base,
              ...multiValueLabelStyle,
            };
          },
          multiValueRemove(base) {
            return {
              ...base,
              ...multiValueRemoveStyle,
            };
          },
          menuPortal(base) {
            return { ...base, zIndex: 9999 };
          },
          placeholder(base) {
            return { ...base, ...placeholderStyle };
          },
          valueContainer(base) {
            return { ...base, ...valueContainerStyle };
          },
          clearIndicator(base) {
            return { ...base, ...clearIndicatorStyle };
          },
          input(base) {
            return { ...base, ...inputStyle };
          },
        }}
      />
    </SelectContainer>
  );
};

const Container = styled.div`
  min-height: 38px;
  display: flex;
  align-items: center;
`;

const Placeholder = styled.span`
  color: ${(props) => props.theme.colors.text.placeholder2};
  cursor: pointer;
`;

const SelectContainer = styled.div`
  min-width: 240px;
  z-index: 9999;
`;

const menuStyle = {
  borderRadius: 0,
  border: 'none',
  marginTop: 0,
  zIndex: 9999,
};

const menuListStyle = {
  padding: 0,
  zIndex: 9999,
};

const placeholderStyle = {
  color: 'rgba(56, 33, 82, 0.41)',
  fontWeight: 500,
  fontSize: '14px',
  margin: 0,
};

const inputStyle = {
  color: '#382152',
  fontWeight: 500,
  fontSize: '14px',
  margin: 0,
  padding: 0,
};

const clearIndicatorStyle = {
  color: 'rgba(56, 33, 82, 0.2)',
  '&:hover': {
    color: 'rgba(56, 33, 82, 0.4)',
  },
};

const valueContainerStyle = {
  padding: 0,
};

const controlStyle = {
  boxShadow: '0px 0px 0px #E5E5E5',
  border: 'none',
  backgroundColor: 'transparent',
  outlineColor: 'transparent',
  borderTopWidth: 0,
  borderRadius: 0,
  color: '#382152',
  fontWeight: 500,
  fontSize: '14px',
};

const containerStyle = {
  borderWidth: '0',
  boxShadow: 'none',
  backgroundColor: 'white',
  outlineColor: 'transparent',
};

const optionStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  padding: '6px 6px',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3EFF6',
  },
  zIndex: 9999,
};

const singleValueStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  margin: 0,
  padding: 0,
};

const multiValueStyle = {
  backgroundColor: 'rgba(56, 33, 82, 0.1)',
};

const multiValueLabelStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  margin: 0,
  padding: 0,
};

const multiValueRemoveStyle = {
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export default React.memo(ThemePicker);
