import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { EditorState, Modifier, ContentBlock, SelectionState } from 'draft-js';
import HighlightIcon from '../Icons/HighlightIcon';
import { TagIcon } from '@heroicons/react/outline';
import MultiSelect, { OptionType } from './MultiSelect';
import { useDashboardTags } from '../Hooks/useTags';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { uniq } from 'lodash';

const HIGHLIGHT_ENTITY_TYPE = 'HIGHLIGHT';

type HighlightData = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags?: any[];
};

const getSelectedBlock = (editorState: EditorState): ContentBlock => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]])
    .toList()
    .get(0);
};

const getSelectionEntity = (editorState: EditorState): string | undefined => {
  if (!editorState) return;
  let entity;
  const selection = editorState.getSelection();
  let start = selection.getStartOffset();
  let end = selection.getEndOffset();
  if (start === end && start === 0) {
    end = 1;
  } else if (start === end) {
    start -= 1;
  }
  const block = getSelectedBlock(editorState);
  if (!block) return;

  for (let i = start; i < end; i += 1) {
    const currentEntity = block.getEntityAt(i);
    if (!currentEntity) {
      entity = undefined;
      break;
    }
    if (i === start) {
      entity = currentEntity;
    } else if (entity !== currentEntity) {
      entity = undefined;
      break;
    }
  }
  return entity;
};

const getHighlightEntityDataFromSelection = (
  editorState: EditorState
): HighlightData | undefined => {
  const entityKey = getSelectionEntity(editorState);
  if (!entityKey) return;
  const contentState = editorState.getCurrentContent();
  const entity = contentState.getEntity(entityKey);
  if (entity.getType() !== HIGHLIGHT_ENTITY_TYPE) return;
  return entity.getData();
};

const addHighlight = (editorState: EditorState, options?: { tags?: string[] }): EditorState => {
  const selection = editorState.getSelection();

  const highlightData: HighlightData = {
    id: nanoid(10),
  };
  if (options?.tags) highlightData.tags = options.tags;

  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('HIGHLIGHT', 'MUTABLE', highlightData);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentStateWithEntityApplied = Modifier.applyEntity(
    contentStateWithEntity,
    selection,
    entityKey
  );

  return EditorState.set(editorState, {
    currentContent: contentStateWithEntityApplied,
  });
};

const removeHighlight = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const startKey = selectionState.getStartKey();
  const contentBlock = contentState.getBlockForKey(startKey);
  const startOffset = selectionState.getStartOffset();
  const entity = contentBlock.getEntityAt(startOffset);

  if (!entity) {
    return editorState;
  }

  let entitySelection: SelectionState | null = null;

  contentBlock.findEntityRanges(
    (character) => character.getEntity() === entity,
    (start, end) => {
      const isForwards = !selectionState.getIsBackward();
      entitySelection = selectionState.merge({
        anchorOffset: isForwards ? start : end,
        focusOffset: isForwards ? end : start,
      });
    }
  );

  if (!entitySelection) return editorState;

  const newContentState = Modifier.applyEntity(contentState, entitySelection, null);

  const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');

  return newEditorState;
};

const isSelectionHighlighted = (editorState: EditorState): boolean => {
  return !!getHighlightEntityDataFromSelection(editorState)?.id;
};

const toogleHighlight = (editorState: EditorState): EditorState => {
  const isHighlighted = isSelectionHighlighted(editorState);
  if (isHighlighted) {
    return removeHighlight(editorState);
  } else {
    return addHighlight(editorState);
  }
};

const HighlightButton = ({
  setEditorState,
  getEditorState,
}: {
  setEditorState: (state: EditorState) => void;
  getEditorState: () => EditorState;
}) => {
  function onClick() {
    const editorState = getEditorState();
    const newState = toogleHighlight(editorState);
    setEditorState(newState);
  }

  function preventBubblingUp(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }

  return (
    <button
      onMouseDown={preventBubblingUp}
      onClick={onClick}
      className={'inline-block leading-5 hover:bg-gray-100 flex py-2 px-3 border-r'}
    >
      <div className={'-bm-2 mr-2'}>
        <HighlightIcon />
      </div>
      {isSelectionHighlighted(getEditorState()) ? ' Un-Highlight' : 'Highlight'}
    </button>
  );
};

const TagsDropdown = ({
  setEditorState,
  getEditorState,
  onOverrideContent,
  dashboardId,
  inlineTags,
}: {
  setEditorState: (state: EditorState) => void;
  getEditorState: () => EditorState;
  onOverrideContent: (content?: any) => void;
  dashboardId: string;
  inlineTags: string[];
}) => {
  function preventBubblingUp(event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) {
    event.preventDefault();
  }

  const Select = () => {
    function onBlur() {
      const editorState = getEditorState();
      const tags = value.map((x) => x.value);

      if (isSelectionHighlighted(editorState)) {
        const contentState = editorState.getCurrentContent();
        const selectedEntityKey = getSelectionEntity(editorState) || '';
        const selectedEntity = contentState.getEntity(selectedEntityKey);
        const updatedContentState = contentState.replaceEntityData(selectedEntityKey, {
          tags,
          id: selectedEntity.getData().id,
        });
        const updatedState = EditorState.set(editorState, {
          currentContent: updatedContentState,
        });
        setEditorState(updatedState);
      } else {
        if (value.length > 0) {
          setEditorState(addHighlight(editorState, { tags }));
        }
      }

      onOverrideContent(undefined);
    }

    const [, tags] = useDashboardTags(dashboardId);

    function getCurrentTags() {
      const data = getHighlightEntityDataFromSelection(getEditorState());
      return data?.tags || [];
    }

    const [value, setValue] = useState<OptionType[]>(
      getCurrentTags().map((x) => ({ value: x, label: x }))
    );

    return (
      <div onMouseDown={preventBubblingUp} className={'w-56 z-100'}>
        <MultiSelect
          menuPlacement={'bottom'}
          autoFocus
          onChange={(e) => setValue(e as OptionType[])}
          menuIsOpen={true}
          isMulti
          defaultValue={value}
          onBlur={onBlur}
          options={uniq([...tags, ...inlineTags]).map((tag) => ({ value: tag, label: tag }))}
        />
      </div>
    );
  };

  const data = getHighlightEntityDataFromSelection(getEditorState());

  return (
    <button
      onMouseDown={preventBubblingUp}
      onClick={() => {
        const editorState = getEditorState();
        const isHighlighted = isSelectionHighlighted(editorState);
        if (!isHighlighted) setEditorState(addHighlight(editorState));
        onOverrideContent(Select);
      }}
      className={'inline-block leading-5 hover:bg-gray-100 flex py-2 px-3'}
    >
      <div className={'-bm-2 mr-1'}>
        <TagIcon className={'h-5 w-5'} />
      </div>
      <span>Tag</span>
      <TagsCount>{data?.tags?.length ? data.tags.length : ''}</TagsCount>
    </button>
  );
};

function Buttons(props: PropsWithChildren<any>): JSX.Element {
  return (
    <div className={'flex'}>
      <HighlightButton {...props} />
      <TagsDropdown {...props} />
    </div>
  );
}

const TagsCount = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: rgba(56, 33, 82, 0.6);
  margin-left: 5px;
`;

export default Buttons;
