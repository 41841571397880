import { PhotographIcon, PlusIcon } from '@heroicons/react/outline';
import React from 'react';
import ExpandIcon from '../Icons/ExplandIcon';

export function CanvasToolbar({
  onClick,
  active,
}: {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  active: boolean;
}) {
  return (
    <div className="absolute z-30 flex border-t-8 border-secondary-purple-dark items-center top-1/3 flex-col right-3 bg-white rounded-sm shadow-md pt-2">
      <div
        onClick={onClick}
        className={
          ' hover:bg-gray-100 z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (active ? 'bg-gray-100' : '')
        }
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 8.75V2.75C16 1.64543 15.1046 0.75 14 0.75H2C0.895431 0.75 0 1.64543 0 2.75V14.75C0 15.8546 0.895432 16.75 2 16.75H7.72953L12.0302 12.7802L16 8.75ZM10.9866 11.7022L13.1557 9.5H9.72953C9.03917 9.5 8.47953 10.0596 8.47953 10.75V14.0163L10.9866 11.7022ZM6.97953 15.25V10.75C6.97953 9.23122 8.21075 8 9.72953 8H14.5V2.75C14.5 2.47386 14.2761 2.25 14 2.25H2C1.72386 2.25 1.5 2.47386 1.5 2.75V14.75C1.5 15.0261 1.72386 15.25 2 15.25H6.97953Z"
            fill="#382152"
          />
        </svg>
      </div>
      <div className={'opacity-25 z-50 p-1 rounded-sm-md '}>
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 16.3438L10.0938 20.1875H21.9375L18.125 15.0938L15.1562 19.0625L13 16.3438Z"
            fill="#382152"
          />
          <rect
            x="8.75"
            y="9.5625"
            width="14.5"
            height="13.375"
            rx="1.25"
            stroke="#382152"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <circle cx="14.5312" cy="13.5938" r="1.5" fill="#382152" />
        </svg>
      </div>
      <div onClick={onClick} className={'opacity-25 z-50 p-1 rounded-sm-md'}>
        <ExpandIcon />
      </div>
      <div onClick={onClick} className={'opacity-25 z-50 p-1 rounded-sm-md'}>
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9925 11.5059H16.9887V13.0041H18.4869V10.7569C18.4869 10.5582 18.4079 10.3677 18.2675 10.2272C18.127 10.0867 17.9365 10.0078 17.7378 10.0078H8.74906C8.5504 10.0078 8.35987 10.0867 8.21939 10.2272C8.07892 10.3677 8 10.5582 8 10.7569V13.0041H9.49812V11.5059H12.4944V20.994H10.4969V22.4922H15.99V20.994H13.9925V11.5059Z"
            fill="#382152"
          />
          <path
            d="M21.5032 20.4473V18.4972H23.7504V16.9991H21.5032V15.0016H20.0051V16.9991H18.0076V18.4972H20.0051V20.4473C20.0051 20.9896 20.2205 21.5098 20.604 21.8933C20.9875 22.2768 21.5077 22.4922 22.05 22.4922H24.0001V20.9941H22.05C21.905 20.9941 21.7659 20.9365 21.6633 20.8339C21.5608 20.7314 21.5032 20.5923 21.5032 20.4473Z"
            fill="#382152"
          />
        </svg>
      </div>
    </div>
  );
}
