import React, { useState } from 'react';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { OptionTypeBase, components } from 'react-select';
import { useFetchParticipantsLazy, useCreateParticipant, useDeleteParticipant, useUpdateParticipant } from '../Hooks/useParticipants';
import { Modal } from '.';
import ParticipantEditor from './ParticipantEditor';
import Button from './Button';

type Props = {
  participant: any,
  onChange: (participantId: string | null) => void,
}

const ParticipantNameContainer = styled.div`
  min-height: 38px;
  display: flex;
  align-items: center;
`

const ChooseButton = styled(Button)`
  margin-top: 32px;
`

const Placeholder = styled.span`
  color: ${props => props.theme.colors.text.placeholder2};
  cursor: pointer;
`
const ParticipantName = styled.span`
  color: ${props => props.theme.colors.primary.purple};
  cursor: pointer;
`

const SelectContainer = styled.div`
  min-width: 240px;
  z-index: 9999;
`

const menuStyle = {
  borderRadius: 0,
  border: 'none',
  marginTop: 0,
  zIndex: 9999,
};

const menuListStyle = {
  padding: 0,
  zIndex: 9999,
};

const placeholderStyle = {
  color: 'rgba(56, 33, 82, 0.41)',
  fontWeight: 500,
  fontSize: '14px',
  margin: 0,
};

const inputStyle = {
  color: '#382152',
  fontWeight: 500,
  fontSize: '14px',
  margin: 0,
  padding: 0,
};

const clearIndicatorStyle = {
  color: 'rgba(56, 33, 82, 0.2)',
  '&:hover': {
    color: 'rgba(56, 33, 82, 0.4)',
  },
};

const valueContainerStyle = {
  padding: 0,
};


const controlStyle = {
  boxShadow: '0px 1px 0px #E5E5E5',
  border: 'none',
  backgroundColor: 'transparent',
  outlineColor: 'transparent',
  borderTopWidth: 0,
  borderRadius: 0,
  color: '#382152',
  fontWeight: 500,
  fontSize: '14px',
};

const containerStyle = {
  borderWidth: '0',
  boxShadow: 'none',
  backgroundColor: 'white',
  outlineColor: 'transparent',
};

const optionStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  padding: '6px 6px',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3EFF6',
  },
  zIndex: 9999,
};

const singleValueStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  margin: 0,
  padding: 0,
};

const ParticipantInput = ({ participant, onChange }: Props): JSX.Element => {
  const [fetch, { loading, called }, participants] = useFetchParticipantsLazy();

  const [createParticipant] = useCreateParticipant();
  const [deleteParticipant] = useDeleteParticipant();
  const [updateParticipant] = useUpdateParticipant();
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [newParticipant, setNewParticipant] = useState<any>(null);

  const handleChange = (newValue: OptionTypeBase) => {
    const participantId = newValue?.value || null
    onChange(participantId);
    setShowSelect(false);
    if (participantId) updateParticipant(participantId, { lastParticipated: new Date().toISOString() });
  };

  const handleCreate = async (name: string) => {
    const res = await createParticipant({ name });
    setNewParticipant(res);
    setShowSelect(false);
  };

  const handleClose = async () => {
    await deleteParticipant(newParticipant.id);
    setNewParticipant(null);
  };

  const handleChoose = async () => {
    onChange(newParticipant.id)
    setNewParticipant(null);
    updateParticipant(newParticipant.id, { lastParticipated: new Date().toISOString() });
  };


  if (newParticipant) {
    return (
      <Modal title={'Create new participant'} isOpen={true} onClose={handleClose}>
        <ParticipantEditor participant={newParticipant} />
        <ChooseButton onClick={handleChoose}>
          Create
        </ChooseButton>
      </Modal>
    )
  }

  if (!showSelect) {
    return (
      <ParticipantNameContainer onClick={() => {
        fetch();
        setShowSelect(true);
      }}>
        {!participant &&
          <Placeholder>add participant</Placeholder>
        }
        {participant &&
          <ParticipantName>{participant.name || 'Untitled'}</ParticipantName>
        }
      </ParticipantNameContainer>
    );
  }

  return (
    <SelectContainer>
      <CreatableSelect
        placeholder='search for a participant'
        defaultMenuIsOpen
        isClearable
        autoFocus
        onBlur={() => { setShowSelect(false) }}
        onChange={(value) => handleChange(value as OptionTypeBase)}
        options={participants.map(participant => ({ value: participant.id, label: participant.name || 'Untitled' }))}
        value={participant ? { value: participant.id, label: participant.name || 'Untitled' } : null}
        onCreateOption={(inputValue) => { handleCreate(inputValue) }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,

          // eslint-disable-next-line react/display-name
          MenuList: ({ children, ...rest }) => {
            return (
              <>
                <components.MenuList {...rest}>
                  {children}
                </components.MenuList>
              </>
            );
          },
        }}

        styles={{
          container(base) {
            return { ...base, ...containerStyle };
          },
          control(base) {
            return { ...base, ...controlStyle };
          },
          menu(base) {
            return {
              ...base,
              ...menuStyle,
            };
          },
          menuList(base) {
            return {
              ...base,
              ...menuListStyle,
            };
          },
          option(base) {
            return {
              ...base,
              ...optionStyle,
            };
          },
          singleValue(base) {
            return {
              ...base,
              ...singleValueStyle,
            };
          },
          menuPortal(base) {
            return { ...base, zIndex: 9999 };
          },
          placeholder(base) {
            return { ...base, ...placeholderStyle };
          },
          valueContainer(base) {
            return { ...base, ...valueContainerStyle };
          },
          clearIndicator(base) {
            return { ...base, ...clearIndicatorStyle };
          },
          input(base) {
            return { ...base, ...inputStyle };
          },
        }}
      />
    </SelectContainer>
  );
}


export default React.memo(ParticipantInput, (prevProps, nextProps) => {
  return prevProps.participant?.id === nextProps.participant?.id && prevProps.participant?.name === nextProps.participant?.name
});
