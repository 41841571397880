import React, {
  useRef,
  useState,
  ChangeEvent,
  BaseSyntheticEvent,
  useContext,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';

import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Shape } from 'konva/lib/Shape';
import { Stage, Layer } from 'react-konva';

import { STICKY_COLORS, GROUPING_COLORS } from '../Consts';
import {
  DEFAULT_STEP,
  DEFAULT_SHOW_TEXT_SCALE,
  DEFAULT_STICKY_COLOR,
  ID,
  Sticky,
  STICKY_FONT,
  STICKY_SPACE,
  STICKY_WIDTH,
  Tag,
  Theme,
} from '../Models';
import { ApolloConsumer, ApolloProvider } from '@apollo/client';
import { Sticky as StickyComponent, Theme as ThemeComponent, StickyModal } from '.';
import { ThemeInput } from '../GraphQL/__generated__/globalTypes';
import { SelectableContext } from './Selectable';
import useThemes from '../Hooks/useThemes';
import useNotes from '../Hooks/useNotes';
import Util = Konva.Util;
import { Group } from 'konva/lib/Group';
import debounce from 'lodash.debounce';
import { CanvasToolbar } from './CanvasToolbar';
import Well from './Well';
import { useSegment } from 'react-segment-hooks';
import GroupSelection from './GroupSelection';

type InfiniteCanvasProps = {
  dashboardId: string;
  stickies: Sticky[];
  groupBy: string[];
};

// TODO: move hook somewhere?
const useDragBound = (ref: React.RefObject<Konva.Stage>) => {
  // we will use hook instead of event listeners on the components
  // to isolate the logic of the feature from the component
  React.useEffect(() => {
    const stage = ref.current!;
    let tableWidth = 0;
    const anim = new Konva.Animation(() => {
      const pos = stage.getPointerPosition();
      if (!pos) {
        return false;
      }

      const edgeDistance = 100;
      const delta = 6 / stage.scaleX();
      if (pos.x < edgeDistance + tableWidth) {
        stage.x(stage.x() + delta);
      }
      if (pos.x > stage.width() - edgeDistance) {
        stage.x(stage.x() - delta);
      }
      if (pos.y < edgeDistance) {
        stage.y(stage.y() + delta);
      }
      if (pos.y > stage.height() - edgeDistance) {
        stage.y(stage.y() - delta);
      }
      // return false to skip redraw
      return false;
    }, [stage]);
    stage.on('dragstart', (e) => {
      // do nothing if we are dragging the stage
      if (e.target === e.currentTarget) {
        return;
      }
      // TODO: are there any ways to width of the table?
      // TODO: should canvas size be smaller when we have opened table?
      tableWidth =
        (document.querySelector('.InovuaReactDataGrid') as HTMLDivElement)?.offsetWidth || 0;
      anim.start();
    });
    stage.on('dragend', () => {
      anim.stop();
    });
  }, []);
};

function InfiniteCanvas({ stickies, dashboardId, groupBy }: InfiniteCanvasProps): JSX.Element {
  const [selected, setSelected, editable, setEditable] = useContext(SelectableContext);

  const analytics = useSegment();

  const [creating, setCreating] = useState(false);
  const [showText, setShowText] = useState(true);

  const layerRef = useRef<Konva.Layer>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const selectionBoundingBoxRef = useRef<Konva.Rect>(null);
  const selectionBoundingBoxStartPos = useRef({
    x: 0,
    y: 0,
  });

  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  });
  const [stagePos, setStagePos] = useState({
    x: 0,
    y: 0,
  });
  const [stageScale, setStageScale] = useState(1);
  const [openedNoteId, setOpenedNoteId] = useState<string | null>(null);

  const { mapFromStickies, getFromStickies, updateTheme, createTheme } = useThemes(
    parseInt(dashboardId)
  );
  const { createNote, updateNote, deleteNote, addTagsToNote, removeTagsFromNote } = useNotes(
    dashboardId
  );

  const [copySticky, setCopySticky] = useState('');

  useDragBound(stageRef);

  const getSticky = (id: ID) => stickies.find((s) => s.id === id) as Sticky;
  const themes = getFromStickies(stickies);

  const openNoteModal = (id: string): void => {
    setOpenedNoteId(id);
  };

  const updateTags = async (stickyId: ID, tags: string[] | null): Promise<void> => {
    const note = getSticky(stickyId);
    const currentTags = note.tagsByItemId.map((x: Tag) => x.name);
    const deletedTags = !tags
      ? currentTags
      : currentTags.filter((tagName: string) => tags.indexOf(tagName) == -1);

    await removeTagsFromNote(note, deletedTags);
    if (tags) await addTagsToNote(note, tags);
  };

  const handleStickyDragEnd = async (event: KonvaEventObject<DragEvent>) => {
    const { x, y, name: id } = event.target.attrs;

    const sticky = getSticky(id);
    // if dragging out of the theme
    if (sticky.theme) {
      const { x: parentX, y: parentY } = event.target.parent?.attrs;
      return await updateNote(id, { themeId: null, x: x + parentX, y: y + parentY });
    }

    return await updateNote(id, { x, y });
  };

  const handleThemeDragEnd = async (event: KonvaEventObject<DragEvent>) => {
    const { x, y, name: index }: { x: number; y: number; name: string } = event.target.attrs;
    const themesMap = mapFromStickies(stickies);
    await updateTheme(themesMap[index].id, { x, y });
  };

  const handleThemeInput = (id: ID) => async (e: ChangeEvent<HTMLInputElement>) => {
    const themesMap = mapFromStickies(stickies);
    await updateTheme(themesMap[id].id, { name: e.target.value });
  };

  const handleIntersection = async (e: KonvaEventObject<DragEvent>) => {
    if (e.target.attrs.id.startsWith('sticky')) {
      await handleStickyIntersection(e);
    } else if (e.target.attrs.id.startsWith('theme')) {
      await handleThemeDragEnd(e);
    }
  };

  const handleStickyIntersection = async (e: KonvaEventObject<DragEvent>) => {
    const target = e.target;
    const targetRect = e.target.getClientRect();

    const children = layerRef.current?.children || [];

    let intersected = false;

    for (const item of children) {
      if (item === target) {
        continue;
      }
      if (haveIntersection(item.getClientRect(), targetRect)) {
        if (item.attrs.id?.startsWith('sticky')) {
          await stickyOnStickyIntersection(item, target);
          intersected = true;
          // if we were in a group, drop out of group and into new theme
          transformerRef.current?.nodes(
            transformerRef.current.nodes().filter((node) => node.attrs.id !== target.attrs.id)
          );
          break; // break if we're intersecting
        } else if (item.attrs.id?.startsWith('theme')) {
          if (await stickyOnThemeIntersection(item, target)) {
            intersected = true;
            // if we were in a group, drop out of group and into new theme
            transformerRef.current?.nodes(
              transformerRef.current.nodes().filter((node) => node.attrs.id !== target.attrs.id)
            );
            break; // break if we're intersecting
          }
        }
      }
    }
    if (!intersected) {
      await handleStickyDragEnd(e);
    }
  };

  async function stickyOnStickyIntersection(
    item: Shape | Konva.Group,
    target: Shape | Konva.Stage
  ) {
    const itemId = item.attrs.name;
    const targetId = target.attrs.name;
    const { x, y } = item.attrs;
    const sticky1 = getSticky(itemId);
    const sticky2 = getSticky(targetId);

    await makeThemeFromStickies(x - STICKY_SPACE, y - DEFAULT_STEP * 2 - STICKY_FONT * 1.25, [
      sticky1,
      sticky2,
    ]);
  }

  async function makeThemeFromStickies(x: number, y: number, stickies: Sticky[]) {
    const themeColor = STICKY_COLORS[Math.floor(Math.random() * STICKY_COLORS.length)];

    const theme: ThemeInput = {
      name: 'Untitled',
      color: themeColor,
      x,
      y,
      dashboardId,
    };

    await createTheme(dashboardId, theme, stickies);
  }

  async function stickyOnThemeIntersection(item: Shape | Konva.Group, target: Shape | Konva.Stage) {
    const themeId = item.attrs.name;
    const stickyId = target.attrs.name;
    const themesMap = mapFromStickies(stickies);
    const theme = themesMap[themeId];

    const sticky = getSticky(stickyId);

    if (sticky.theme?.id == themeId) {
      return false;
    }

    await updateNote(sticky.id, { themeId: theme.id });

    return true;
  }

  function haveIntersection(
    r1: {
      width: number;
      height: number;
      x: number;
      y: number;
    },
    r2: {
      width: number;
      height: number;
      x: number;
      y: number;
    }
  ) {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  }

  // request update function will not update state imidiately
  // but it will do it in a short timeout
  // it use useful for throttling updates
  const timeout = useRef<number | undefined>(undefined);
  const requestUpdateState = (func: () => void) => {
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      func();
    }, 100);
  };

  function zoomStage(event: KonvaEventObject<WheelEvent>) {
    const scaleBy = 1.05;
    const stage = event.target.getStage();
    event.evt.preventDefault();
    event.cancelBubble = true;
    const layer = layerRef.current;

    if (!layer || !stage) {
      return;
    }

    if (event.evt.ctrlKey || event.evt.metaKey) {
      const pointer = stage?.getPointerPosition();
      const oldScale = stage?.scaleX();

      if (!pointer || !stage || !oldScale) {
        return;
      }
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };
      const newScale = -event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      setShowText(newScale > DEFAULT_SHOW_TEXT_SCALE);
      // it is better to set attributes of the stage directly, without changing state of the component
      // because it is faster
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      // but let's update state in a timeout
      // so we can recalculate positions of stickies
      requestUpdateState(() => {
        // use batched updates to have just one render instead of two
        ReactDOM.unstable_batchedUpdates(() => {
          setStageScale(newScale);
          setStagePos(newPos);
        });
      });
    } else {
      const dx = event.evt.deltaX;
      const dy = event.evt.deltaY;

      const x = stage.x() - dx;

      const y = stage.y() - dy;
      stage.position({ x, y });
      // update state in a timeout
      requestUpdateState(() => {
        setStagePos({ x, y });
      });
    }
  }

  const listenForKeyPress = (selected: string | null, editable: string | null) => async (
    e: BaseSyntheticEvent<KeyboardEvent>
  ) => {
    if (editable !== '') {
      return;
    }

    if (stageRef.current !== null) {
      let newScale = stageRef.current.scaleX();
      let deltaX = 0,
        deltaY = 0;
      const arrowStep = 100;

      if (e.nativeEvent.key == 'ArrowDown') {
        e.preventDefault();
        deltaY -= arrowStep;
      }
      if (e.nativeEvent.key == 'ArrowUp') {
        e.preventDefault();
        deltaY += arrowStep;
      }
      if (e.nativeEvent.key == 'ArrowLeft') {
        e.preventDefault();
        deltaX += arrowStep;
      }
      if (e.nativeEvent.key == 'ArrowRight') {
        e.preventDefault();
        deltaX -= arrowStep;
      }
      if ((e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) && e.nativeEvent.key === '=') {
        e.preventDefault();
        newScale *= 1.05;
        setShowText(newScale > DEFAULT_SHOW_TEXT_SCALE);
      } else if ((e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) && e.nativeEvent.key === '-') {
        e.preventDefault();
        newScale /= 1.05;
        setShowText(newScale > DEFAULT_SHOW_TEXT_SCALE);
      }
      if (
        (e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) &&
        selected?.startsWith('sticky') &&
        !editable &&
        e.nativeEvent.key === 'c'
      ) {
        e.preventDefault();
        e.stopPropagation();
        setCopySticky(selected);
        return;
      }
      if (
        (e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) &&
        copySticky?.startsWith('sticky') &&
        e.nativeEvent.key === 'v'
      ) {
        e.preventDefault();
        e.stopPropagation();
        const toCopy = getSticky(copySticky.split('-')[1]);
        const note = await createNote(dashboardId, {
          x: toCopy.x + STICKY_WIDTH + DEFAULT_STEP,
          y: toCopy.y,
          color: toCopy.color,
          text: toCopy.text,
          tags: {
            create: toCopy.tagsByItemId?.map((x: Tag) => ({
              itemType: 'note',
              name: x.name,
            })),
          },
        });
        setCopySticky('');
        setSelected?.(('sticky-' + note.id) as string);

        analytics.track({
          event: 'CreateNote',
          properties: {
            origin: 'Copy',
          },
        });

        return;
      }

      stageRef.current.scale({ x: newScale, y: newScale });
      stageRef.current.position({
        x: stageRef.current.x() + deltaX,
        y: stageRef.current.y() + deltaY,
      });
      stageRef.current.batchDraw();
    }

    if (e.nativeEvent.key == 'Backspace' && selected) {
      e.preventDefault();

      const [type, id] = selected.split('-');
      if (type === 'sticky') {
        await deleteNote(id);
        setSelected?.('');
      }
      if (type === 'theme') {
        throw new Error('Theme deletion not implemented');
      }
    }
  };

  function handleStageClick(e: KonvaEventObject<MouseEvent>) {
    const pointer = stageRef.current?.getPointerPosition();
    const stage = e.target.getStage();
    const oldScale = stage?.scaleX();

    e.cancelBubble = true;

    if (creating && pointer && stage && oldScale) {
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      analytics.track({
        event: 'CreateNote',
        properties: {
          origin: 'CanvasToolbar',
        },
      });

      createNote(dashboardId, {
        ...mousePointTo,
      }).then((r) => {
        setCreating(false);
        setSelected?.(`sticky-${r.id}`);
        setEditable?.(`sticky-${r.id}`);
      });
    }

    if (e.target.parent?.attrs.name !== transformerRef.current?.name()) {
      // click inside selection group
      setSelected?.('');
      setEditable?.('');
    }
  }

  // start drawing selection rectangle
  function handleMouseDown(e: KonvaEventObject<MouseEvent>) {
    const stage = stageRef.current;
    const selectionRectangle = selectionBoundingBoxRef.current;
    if (!stage || !selectionRectangle || e.target !== stage || selectionRectangle.visible()) {
      return;
    }
    e.evt.preventDefault();

    selectionBoundingBoxStartPos.current = {
      x: stage.getRelativePointerPosition().x,
      y: stage.getRelativePointerPosition().y,
    };

    selectionRectangle.setAttrs({
      x: stage.getRelativePointerPosition().x,
      y: stage.getRelativePointerPosition().y,
      width: 0,
      height: 0,
      visible: true,
    });
    setSelected?.('');
  }

  // continue drawing selection rectangle/adding stickies to the group (if active)
  function handleMouseMove(e: KonvaEventObject<MouseEvent>) {
    const stage = stageRef.current;
    const selectionRectangle = selectionBoundingBoxRef.current;
    const transformer = transformerRef.current;
    if (!stage || !selectionRectangle || !transformer || !selectionRectangle.isVisible()) {
      return;
    }
    e.evt.preventDefault();
    selectionRectangle.setAttrs({
      x: Math.min(stage.getRelativePointerPosition().x, selectionBoundingBoxStartPos.current.x),
      y: Math.min(stage.getRelativePointerPosition().y, selectionBoundingBoxStartPos.current.y),
      width: Math.abs(
        stage.getRelativePointerPosition().x - selectionBoundingBoxStartPos.current.x
      ),
      height: Math.abs(
        stage.getRelativePointerPosition().y - selectionBoundingBoxStartPos.current.y
      ),
      visible: true,
    });
    const stickies = stage.find((node: Konva.Node) => node.id().startsWith('sticky-'));
    const selectedBox = selectionRectangle.getClientRect();
    const selectedStickies = stickies.filter(
      (sticky) =>
        !getSticky(sticky.attrs.name)?.theme &&
        Util.haveIntersection(selectedBox, sticky.getClientRect())
    );
    const themes = stage.find((node: Konva.Node) => node.id().startsWith('theme-'));
    const selectedThemes = themes.filter((theme) =>
      Util.haveIntersection(selectedBox, theme.getClientRect())
    );
    transformer.nodes([...selectedThemes, ...selectedStickies]);
  }

  // finish drawing selection rectangle
  function handleMouseUp(e: KonvaEventObject<MouseEvent>) {
    const stage = stageRef.current;
    const selectionRectangle = selectionBoundingBoxRef.current;
    const transformer = transformerRef.current;
    if (selectionRectangle) {
      selectionRectangle.visible(false);
    }
    if (
      selectionRectangle &&
      selectionRectangle.height() === 0 &&
      selectionRectangle.width() === 0
    ) {
      transformerRef.current?.nodes([]);
    }
    if (
      !selectionRectangle ||
      !stage ||
      !transformer ||
      (selectionRectangle.width() === 0 && selectionRectangle.height() === 0)
    ) {
      return;
    }
    e.evt.preventDefault();
    if (transformer.nodes().length === 1) {
      setSelected?.(transformer.nodes()[0].id());
      transformer.nodes([]);
    } else if (transformer.nodes().length > 0) {
      setSelected?.(transformer.name());
    }
  }

  function isClientRectOnScreen(stage: Konva.Stage, rect: Konva.Node) {
    const screenRect = {
      x: 0,
      y: 0,
      width: stage.width(),
      height: stage.height(),
    };
    return Util.haveIntersection(screenRect, rect.getClientRect());
  }

  useEffect(() => {
    if (!divRef.current) {
      return;
    }

    const ref = divRef.current;

    const resizeObserver = new ResizeObserver(
      debounce((entries: ResizeObserverEntry[]) => {
        entries.forEach((entry) => {
          const { width, height } = entry.contentRect;

          setCanvasSize({ width, height });
        });
      }, 1000)
    );

    resizeObserver.observe(ref);

    // clean up function
    return () => {
      // remove resize listener
      resizeObserver.unobserve(ref);
    };
  }, [divRef]);

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage) {
      return;
    }

    stage.container().style.cursor = creating ? 'crosshair' : 'default';

    if (!selected) {
      return;
    }

    if (!layerRef.current) {
      return;
    }

    const selectedNode = stage.findOne<Group>('#' + selected);

    if (!selectedNode) {
      return;
    }

    if (isClientRectOnScreen(stage, selectedNode)) {
      return;
    }

    const { x: xx, y: yy, width, height } = selectedNode.getClientRect({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      relativeTo: stage,
    });

    stage.to({
      duration: 0.5,
      scaleX: 1,
      scaleY: 1,
      x: (stage.width() - width) / 2 - xx,
      y: (stage.height() - height) / 2 - yy,
    });

    stage.batchDraw();
  }, [stageRef, creating, selected]);

  function getThemeColor(theme: Theme) {
    if (groupBy[0] != 'theme') {
      return GROUPING_COLORS.themeColor;
    }
    return theme.color;
  }

  function getColor(sticky: Sticky, theme?: Theme) {
    switch (groupBy[0]) {
      case 'sentiment':
        if (typeof sticky.sentimentScore === undefined) {
          return GROUPING_COLORS.default;
        }
        if (sticky.sentimentScore == null) {
          return GROUPING_COLORS.default;
        }
        if (sticky.sentimentScore == 0) {
          return GROUPING_COLORS.sentimentScore.neutral;
        }
        if (sticky.sentimentScore > 0) {
          return GROUPING_COLORS.sentimentScore.positive;
        }
        if (sticky.sentimentScore < 0) {
          return GROUPING_COLORS.sentimentScore.negative;
        }
        break;
      case 'tag':
        const found = sticky.tagsByItemId.findIndex((x: { name: string }) => {
          return x.name === groupBy[1];
        });

        if (found === -1) {
          return GROUPING_COLORS.default;
        } else {
          return GROUPING_COLORS.tag;
        }
      case 'participant':
        if (sticky.participantId !== groupBy[1]) {
          return GROUPING_COLORS.default;
        } else {
          return GROUPING_COLORS.participant;
        }
    }
    return theme?.color ?? (sticky.color || DEFAULT_STICKY_COLOR);
  }

  // lets calculate viewport of visible area
  // but also we will make it bigger then "just visible" area
  // so some shapes, that are not on the screen, but very close to it,
  // will be still rendered
  const offset = 1;
  const viewport = {
    x: -stagePos.x / stageScale - (canvasSize.width / stageScale) * offset,
    y: -stagePos.y / stageScale - (canvasSize.height / stageScale) * offset,
    width: (canvasSize.width / stageScale) * (1 + 2 * offset),
    height: (canvasSize.height / stageScale) * (1 + 2 * offset),
  };
  const openedSticky = openedNoteId ? getSticky(openedNoteId) : null;

  return (
    <>
      {openedSticky && (
        <StickyModal
          sticky={openedSticky}
          themes={themes}
          updateNote={updateNote}
          updateTags={updateTags}
          onClose={() => {
            setOpenedNoteId(null);
          }}
        />
      )}
      <div className={'relative flex-auto h-full'}>
        <div className={'z-100 fixed left-1/4 top-10 w-1/2'}>
          <Well wellKey={'notes-well'}>
            Notes are tiny snippets of data to analyze with tags and themes. You can also turn on
            automatic sentiment analysis. Work with notes on the canvas or the table, they’re always
            in sync.
          </Well>
        </div>
        <CanvasToolbar onClick={() => setCreating(!creating)} active={creating} />
      </div>
      <div
        ref={divRef}
        className={'outline-none background-grid left-0 fixed w-full h-full'}
        onKeyDown={listenForKeyPress(selected, editable)}
        tabIndex={-1}
      >
        <ApolloConsumer>
          {(client) => (
            <Stage
              onWheel={zoomStage}
              ref={stageRef}
              x={stagePos.x}
              y={stagePos.y}
              width={canvasSize.width}
              height={canvasSize.height}
              perfectDrawEnabled={false} // Don't need perfect draw
              onClick={handleStageClick}
              onDragEnd={(e) => {
                setStagePos(e.currentTarget.position());
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              <ApolloProvider client={client}>
                <SelectableContext.Provider value={[selected, setSelected, editable, setEditable]}>
                  <Layer
                    ref={layerRef}
                    transformsEnabled={'position'} // Reduce transform handlers
                    onDragEnd={handleIntersection}
                    perfectDrawEnabled={false} // Don't need perfect draw
                  >
                    {themes.map((theme: Theme) => {
                      const rowSize = Math.ceil(Math.sqrt(theme.notes.length));
                      const themeWidth = (DEFAULT_STEP + STICKY_WIDTH) * rowSize + DEFAULT_STEP * 2;
                      const themeHeight =
                        (DEFAULT_STEP + STICKY_WIDTH) * Math.ceil(theme.notes.length / rowSize) +
                        DEFAULT_STEP * 3 +
                        STICKY_FONT * 1.25;

                      if (
                        theme.x + themeWidth < viewport.x ||
                        theme.x > viewport.x + viewport.width
                      ) {
                        return null;
                      }

                      if (
                        theme.y + themeHeight < viewport.y ||
                        theme.y > viewport.y + viewport.height
                      ) {
                        return null;
                      }

                      return (
                        <ThemeComponent
                          dashboardId={dashboardId}
                          notes={theme.notes}
                          key={`theme-${theme.id}`}
                          id={`${theme.id}`}
                          x={theme.x}
                          y={theme.y}
                          width={themeWidth}
                          height={themeHeight}
                          spacing={STICKY_SPACE}
                          color={getThemeColor(theme)}
                          defaultStep={DEFAULT_STEP}
                          fontSize={STICKY_FONT}
                          showText={showText}
                          editorProps={{
                            onBlur: handleThemeInput(theme.id),
                          }}
                          selectableProps={{
                            selected: selected == `theme-${theme.id}`,
                            editable: editable == `theme-${theme.id}`,
                            onChange: ({ editable, selected }) => {
                              setSelected?.(selected ? `theme-${theme.id}` : '');
                              setEditable?.(editable ? `theme-${theme.id}` : '');
                              transformerRef.current?.nodes([]);
                            },
                          }}
                          text={theme.name}
                        >
                          {theme.notes.map((sticky, index) => {
                            const x =
                              STICKY_SPACE + (DEFAULT_STEP + STICKY_WIDTH) * (index % rowSize);
                            const y =
                              DEFAULT_STEP * 2 +
                              STICKY_FONT * 1.25 +
                              Math.floor(index / rowSize) * (STICKY_WIDTH + DEFAULT_STEP);

                            const absX = x + theme.x;
                            const absY = y + theme.y;
                            // we sticky is not visible, just render nothing
                            if (absX < viewport.x || absX > viewport.x + viewport.width) {
                              return null;
                            }
                            if (absY < viewport.y || absY > viewport.y + viewport.height) {
                              return null;
                            }
                            return (
                              <StickyComponent
                                // menu={false}
                                dashboardId={dashboardId}
                                tags={sticky.tagsByItemId
                                  ?.map(({ name }: { name: string }) => name)
                                  .filter((x) => x)}
                                key={'theme-sticky-' + sticky.id}
                                id={`${sticky.id}`}
                                x={x}
                                y={y}
                                text={sticky.text}
                                width={STICKY_WIDTH}
                                height={STICKY_WIDTH}
                                defaultStep={DEFAULT_STEP}
                                color={getColor(sticky, theme)}
                                selectableProps={{
                                  selected: selected == `sticky-${sticky.id}`,
                                  editable: editable == `sticky-${sticky.id}`,
                                  onChange: ({ editable, selected }) => {
                                    setSelected?.(selected ? `sticky-${sticky.id}` : '');
                                    setEditable?.(editable ? `sticky-${sticky.id}` : '');
                                    transformerRef.current?.nodes([]);
                                  },
                                }}
                                showText={showText}
                                fontSize={STICKY_FONT}
                                participant={sticky.participant}
                                createNote={createNote}
                                updateNote={updateNote}
                                deleteNote={deleteNote}
                                openNote={openNoteModal}
                              />
                            );
                          })}
                        </ThemeComponent>
                      );
                    })}
                    {stickies.map((sticky) => {
                      if (sticky.theme) {
                        return null;
                      }
                      // we sticky is not visible, just render nothing
                      if (sticky.x < viewport.x || sticky.x > viewport.x + viewport.width) {
                        return null;
                      }
                      if (sticky.y < viewport.y || sticky.y > viewport.y + viewport.height) {
                        return null;
                      }
                      return (
                        <StickyComponent
                          dashboardId={dashboardId}
                          tags={sticky.tagsByItemId
                            ?.map(({ name }: { name: string }) => name)
                            .filter((x) => x)}
                          key={`sticky-${sticky.id}`}
                          id={`${sticky.id}`}
                          x={sticky.x}
                          y={sticky.y}
                          width={STICKY_WIDTH}
                          height={STICKY_WIDTH}
                          color={getColor(sticky)}
                          selectableProps={{
                            selected: selected == `sticky-${sticky.id}`,
                            editable: editable == `sticky-${sticky.id}`,
                            onChange: ({ editable, selected }) => {
                              setSelected?.(selected ? `sticky-${sticky.id}` : '');
                              setEditable?.(editable ? `sticky-${sticky.id}` : '');
                              transformerRef.current?.nodes([]);
                            },
                          }}
                          showText={showText}
                          text={sticky.text}
                          participant={sticky.participant}
                          createNote={createNote}
                          updateNote={updateNote}
                          deleteNote={deleteNote}
                          openNote={openNoteModal}
                        />
                      );
                    })}
                    <GroupSelection
                      transformerRef={transformerRef}
                      selectionBoundingBoxRef={selectionBoundingBoxRef}
                      selected={selected === transformerRef.current?.name()}
                      dashboardId={dashboardId}
                      themes={themes}
                      makeThemeFromStickies={makeThemeFromStickies}
                      getSticky={getSticky}
                      unselect={() => setSelected?.('')}
                    />
                  </Layer>
                </SelectableContext.Provider>
              </ApolloProvider>
            </Stage>
          )}
        </ApolloConsumer>
      </div>
    </>
  );
}

export default InfiniteCanvas;
