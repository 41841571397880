import React from 'react';
import { useParams, NavLink, useRouteMatch } from 'react-router-dom';
import NotesIcon from '../Icons/NotesIcon';
import DataIcon from '../Icons/DataIcon';
import InsightsIcon from '../Icons/InsightsIcon';
import InfoIcon from '../Icons/InfoIcon';
import useBoards from '../Hooks/useBoards';
import ProjectTitle from './ProjectTitle';

const navigation = [
  { name: 'Info', href: '/info', icon: <InfoIcon /> },
  { name: 'Data', href: '/data', icon: <DataIcon /> },
  { name: 'Analysis', href: '/notes', icon: <NotesIcon /> },
  { name: 'Insights', href: '/insights', icon: <InsightsIcon /> },
];

export default function Sidebar2() {
  const match = useRouteMatch({
    path: '/projects/:dashboardId',
    exact: false,
  });

  const { dashboardId } = useParams<{ dashboardId: string }>();
  const { fetchBoard, updateBoard } = useBoards();

  const [loading, board] = fetchBoard(parseInt(dashboardId) ?? 0);

  return (
    <div className="flex flex-col w-56 border-r border-gray-200 nav-sidebar">
      <div className="flex-1 flex flex-col">
        <div className="px-2 py-2 font-medium">
          <ProjectTitle
            text={loading ? '' : board?.name}
            onSave={async (newName) => {
              await updateBoard(parseInt(dashboardId), {
                name: newName,
              });
            }}
          />
        </div>
        <nav className="flex-1 px-2 bg-white space-y-1">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              activeClassName="bg-secondary-purple-light"
              to={`${match?.url}${item.href}`}
              className={
                'hover:bg-secondary-purple-light group flex items-center px-2 py-2 text-sm font-medium rounded-md'
              }
            >
              <span className={'inline-flex pr-3'}>{item.icon}</span>
              {item.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
}
