import * as React from 'react';
import ReactLoading from 'react-loading';

type LoaderProps = {
  isFullScreen?: boolean;
};

const Loader = ({ isFullScreen = true }: LoaderProps): JSX.Element => {
  const classNames = (isFullScreen ? 'mt-15pct ' : '') + 'flex justify-center';
  return (
    <div className={classNames}>
      <ReactLoading type={'spin'} color={'#382152'} height={'50px'} width={'50px'} />
    </div>
  );
};

export default Loader;
