import React, { useEffect } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';

import QueueLink from 'apollo-link-queue';
import { useHistory } from 'react-router';

const AuthorizedApolloProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const errorWithCode = networkError as { statusCode? };

    if (errorWithCode?.statusCode === 403) {
      history.push('/onboarding');
      return;
    }

    graphQLErrors &&
      graphQLErrors.map((e) => {
        toast.error('An unexpected error occurred.') && console.error(networkError);
      });
    errorWithCode &&
      errorWithCode.statusCode &&
      toast.error('An unexpected error occurred.') &&
      console.error(networkError);
  });

  const authLink = setContext(async (_, { headers, ...context }) => {
    const token = await getAccessTokenSilently();

    window.localStorage.setItem('token', token);

    return {
      headers: {
        ...headers,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
      ...context,
    };
  });

  const graphqlUrl = (import.meta.env.VITE_NOTABLY_GRAPHQL_URL ||
    'http://localhost:3000/graphql') as string;

  const httpLink = new HttpLink({ uri: graphqlUrl });
  const offlineLink = new QueueLink();

  const cache: InMemoryCache = new InMemoryCache({});
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, authLink, offlineLink, httpLink]),
  });

  function goOnline() {
    offlineLink.open();
  }

  function goOffline() {
    offlineLink.close();
  }

  useEffect(() => {
    // Note: remove these listeners when your app is shut down to avoid leaking listeners.

    window.addEventListener('offline', goOffline);
    window.addEventListener('online', goOnline);

    return () => {
      window.removeEventListener('offline', goOffline);
      window.removeEventListener('online', goOnline);
    };
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
