import { getDashboards_dashboards } from "../GraphQL/__generated__/getDashboards";

export interface OnboardingProgress {
  hasOpenedSampleProject: boolean;
  hasCreatedProject: boolean;
  hasCustomLogo: boolean;
  hasInvitedTeammates: boolean;
}

interface Props {
  dashboards: getDashboards_dashboards[] | null;
  users: any;
  org: any;
}

const useOnboardingProgress = ({
  dashboards,
  users,
  org
}: Props): OnboardingProgress => {
  const hasOpenedSampleProject = dashboards?.find(dashboard => dashboard.isSample)?.isVisited || false;
  const hasCreatedProject = dashboards ? dashboards.filter(dashboard => !dashboard.isSample).length > 0 : false;
  const hasCustomLogo = !!org.logo;
  const hasInvitedTeammates = users?.length ? users.length > 1 : false;

  return {
    hasOpenedSampleProject,
    hasCreatedProject,
    hasCustomLogo,
    hasInvitedTeammates,
  }
}

export default useOnboardingProgress
