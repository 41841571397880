import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Avatar from '../Components/Avatar';
import { Insight } from '../Models';
import { Link } from 'react-router-dom';

import { defaultCoverImages } from '../Consts/coverImages';

type InsightsCardViewProps = {
  insights: Insight[];
  baseUrl: string;
};

interface TitleProps {
  readonly isEmpty: boolean;
}

interface InsightCardProps {
  readonly hasCover: boolean;
}

export default function InsightsCardView({
  insights = [],
  baseUrl,
}: InsightsCardViewProps): JSX.Element {
  return (
    <InsightCards>
      {insights.map((insight) => {
        const selectedImage = defaultCoverImages.find((image) => image.name === insight?.cover);
        return (
          <InsightCard key={insight.id} hasCover={!!selectedImage}>
            <Link to={`${baseUrl}/${insight.clientId}`}>
              <InsightCardContent>
                {selectedImage && (
                  <img
                    src={selectedImage.url}
                    alt="cover image"
                    style={{
                      width: '100%',
                      height: 150,
                      objectFit: 'cover',
                    }}
                  />
                )}
                <InsightTitle isEmpty={!insight.title}>
                  {insight.title || 'Untitled Insight'}
                </InsightTitle>
                <div className="flex flex-row items-center justify-center">
                  <InsightDate>{moment(insight.createdAt).format('MMM D, YYYY')}</InsightDate>
                  <div className="rounded-full bg-primary-purple-light h-1 w-1"></div>
                  <CreatedBy>
                    <Avatar user={insight.userByCreatedBy} />
                    <UserName>{insight.userByCreatedBy?.name}</UserName>
                  </CreatedBy>
                </div>
              </InsightCardContent>
            </Link>
          </InsightCard>
        );
      })}
    </InsightCards>
  );
}

const InsightCards = styled.div`
  display: flex;
  margin: 32px 96px;
  flex-wrap: wrap;
`;

const InsightCard = styled.div<InsightCardProps>`
  width: 332px;
  margin: 20px 10px;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px #e5e5e5, 2px 2px 4px rgba(0, 0, 0, 0.1);
  ${(props) => {
    if (!props.hasCover) {
      return `height: 215px;`;
    }
  }}
`;

const InsightCardContent = styled.div`
  padding: 10px;
`;

const InsightDate = styled.div`
  font-size: 14px;
  color: rgba(56, 33, 82, 1);
  padding-right: 12px;
`;

const InsightTitle = styled.div<TitleProps>`
  color: ${(props) => props.theme.colors.text.purple};
  margin: 10px 0;
  height: 144px;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;

  border-bottom: 1px solid rgba(56, 33, 82, 0.1);

  display: -webkit-box !important;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: normal;

  font-weight: ${(props) => props.isEmpty && 400};
  color: ${(props) => props.isEmpty && props.theme.colors.text.placeholder};
`;

const CreatedBy = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.primary.purple};
  padding-left: 12px;
`;

const UserName = styled.div`
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
`;
