import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Insight } from '../Models';
import { Link } from 'react-router-dom';

import { defaultCoverImages } from '../Consts/coverImages';

type InsightsListViewProps = {
  insights: Insight[];
};

interface TitleProps {
  readonly isEmpty: boolean;
  readonly hasCover: boolean;
}

export default function InsightsListView({ insights = [] }: InsightsListViewProps): JSX.Element {
  return (
    <InsightList>
      {insights.map((insight) => {
        const selectedImage = defaultCoverImages.find((image) => image.name === insight?.cover);
        return (
          <InsightCard key={insight.id} to={'./notes/insights/' + insight.clientId}>
            <InsightCardContent>
              {selectedImage && (
                <img
                  src={selectedImage.url}
                  alt="cover image"
                  style={{
                    width: '100%',
                    height: 150,
                    objectFit: 'cover',
                  }}
                />
              )}
              <InsightTitle isEmpty={!insight.title} hasCover={!!selectedImage}>
                {insight.title || 'Untitled Insight'}
              </InsightTitle>
              <InsightDate>{moment(insight.createdAt).format('MMM D, YYYY')}</InsightDate>
            </InsightCardContent>
          </InsightCard>
        );
      })}
    </InsightList>
  );
}

const InsightList = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  height: 100%;
  align-content: flex-start;
`;

const InsightCard = styled(Link)`
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
  margin: 0 10px 10px 10px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px #e5e5e5, 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const InsightCardContent = styled.div`
  padding: 10px 10px;
`;

const InsightDate = styled.div`
  font-size: 14px;
  padding-right: 12px;
`;

const InsightTitle = styled.div<TitleProps>`
  color: ${(props) => props.theme.colors.text.purple};
  margin: 0 0 10px 0;
  padding-top: ${(props) => props.hasCover && '10px'};
  padding-bottom: 10px;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;

  border-bottom: 1px solid rgba(56, 33, 82, 0.1);

  font-weight: ${(props) => props.isEmpty && 400};
  color: ${(props) => props.isEmpty && props.theme.colors.text.placeholder};
`;
