import React, { createContext, useMemo, useRef } from 'react';
import { Group, Rect } from 'react-konva';
import Konva from 'konva';
import KonvaEventObject = Konva.KonvaEventObject;
import { NodeConfig } from 'konva/lib/Node';
import * as KonvaUtils from 'react-konva-utils';

export type OnChangeEventHandler = ({
  selected,
  editable,
}: {
  selected: boolean;
  editable: boolean;
}) => void;

interface SelectableProps extends NodeConfig {
  cache?: boolean;
  simplified?: boolean;
  selected?: boolean;
  editable?: boolean;
  editor: JSX.Element;
  onChange?: OnChangeEventHandler;
  menu?: JSX.Element;
}

type SelectableStore = [
  string | null,
  ((value: string) => void) | null,
  string | null,
  ((value: string) => void) | null
];

const selectedRectStyles = {
  stroke: '#382152',
};

const SelectableContext = createContext<SelectableStore>([null, null, null, null]);

function Selectable({
  editor,
  onChange,
  selected = false,
  editable = false,
  simplified = false,
  width,
  height,
  color,
  children,
  inverted = false,
  menu,
  scale,
  ...props
}: SelectableProps): JSX.Element {
  const groupRef = useRef<Konva.Group>(null);

  const onDblClick = (e: KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true;

    onChange?.({
      selected: true,
      editable: true,
    });
  };

  const onClick = (e: KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true;

    if (!groupRef.current) {
      return;
    }

    onChange?.({
      selected: true,
      editable: false,
    });
  };

  const onBlur = () => {
    onChange?.({
      selected: false,
      editable: false,
    });
  };

  const handleOnMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage()?.container();
    if (container) {
      if (container.style.cursor == 'default') {
        container.style.cursor = 'pointer';
      }
    }
  };

  const handleOnMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage()?.container();
    if (container) {
      if (container.style.cursor == 'pointer') {
        container.style.cursor = 'default';
      }
    }
  };

  const richProps = simplified
    ? {}
    : {
        shadowBlur: 2,
        shadowOffsetX: 1,
        shadowOffsetY: 3,
        shadowColor: 'rgba(21, 21, 21, 0.15)',
        cornerRadius: 2,
      };

  const rectProps = {
    fill: color,
    stroke: color == '#fff' ? '#E5E5E5' : color,
    shadowForStrokeEnabled: false,
    hitStrokeWidth: 0, // Don't need a hitstroke
    strokeWidth: color == '#fff' ? 1 : 3,
    opacity: ('' + props.id).startsWith('-') ? 0.7 : 1,
    ...richProps,
  };

  const invertedProps = {
    stroke: color,
    fill: 'white',
  };

  const rect = useMemo(() => {
    return (
      <Rect
        perfectDrawEnabled={false} // Don't need perfect draw
        transformsEnabled={'position'} //reduce handlers for trasnformation
        width={width}
        height={height}
        {...rectProps}
        {...(inverted ? invertedProps : {})}
        {...(selected ? selectedRectStyles : {})}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, scale, height, inverted, selected, color, rectProps]);

  return (
    <Group
      {...props}
      draggable
      transformsEnabled={'position'} // reduce handlers for transformation
      perfectDrawEnabled={false} // Don't need perfect draw
      onClick={onClick}
      onDblClick={onDblClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      ref={groupRef}
      color={color}
    >
      {selected && menu && (
        <KonvaUtils.Html
          transformFunc={({ x, ...attrs }) => {
            return { ...attrs, scaleX: 1, scaleY: 1, x: x + ((width ?? 0) / 2) * attrs.scaleX };
          }}
        >
          {menu}
        </KonvaUtils.Html>
      )}
      {rect}
      {children}
      {editable && editor && (
        <KonvaUtils.Html>
          <div onBlur={onBlur}>{editor}</div>
        </KonvaUtils.Html>
      )}
    </Group>
  );
}

export { Selectable, SelectableContext };
