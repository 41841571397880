import styled from 'styled-components';

export const TableSimple = styled.div.attrs({
  className: 'bg-white flex-col pb-52 w-full',
})``;

export const TableRow = styled.div.attrs<{ hover?: boolean | undefined }>((props) => ({
  className: `${
    props.hover === false ? '' : 'hover:bg-primary-purple/10 hover:cursor-pointer'
  } flex py-2 px-3 justify-between items-center`,
}))<{ hover?: boolean | undefined }>``;

export const TableCell = styled.div.attrs({
  className: 'text-ellipsis whitespace-nowrap flex text-left items-center overflow-hidden',
})``;

export const MenuRow = styled.a.attrs({
  className: 'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100',
})``;
