import { useQuery, gql, useMutation } from '@apollo/client';
import { FETCH_USERS } from '../GraphQL/queries';
import { DELETE_USER, INVITE_USER, UPDATE_USER } from '../GraphQL/mutations';
import { ID } from '../Models';

const CURRENT_USER = gql`
  query currentUsr {
    currentUsr {
      id
      avatar
      picture
      name
      role
      createdAt
    }
  }
`;

export default function useUsers() {
  const { data, loading } = useQuery(CURRENT_USER);
  const { data: usersData, loading: loadingUsers } = useQuery(FETCH_USERS);

  const [mutation] = useMutation(INVITE_USER);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [deleteUserMutation] = useMutation(DELETE_USER);

  function currentUser() {
    return [loading, data?.currentUsr];
  }

  function fetchUsers() {
    return [loadingUsers, usersData?.users];
  }

  async function inviteUser(email: string) {
    const result = await mutation({
      variables: {
        email,
      },
      refetchQueries: [
        {
          query: FETCH_USERS,
        },
      ],
    });

    return result.data.success;
  }

  async function deleteUser(id: ID) {
    const { data } = await deleteUserMutation({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: FETCH_USERS,
        },
      ],
    });

    return data.deleteUser.user;
  }

  async function updateUser(id: ID, input: any) {
    const { data } = await updateUserMutation({
      variables: {
        id: id,
        input,
      },
      refetchQueries: [
        {
          query: FETCH_USERS,
        },
      ],
    });

    return data.updateUser.user;
  }

  return {
    updateUser,
    deleteUser,
    currentUser,
    fetchUsers,
    inviteUser,
  };
}
