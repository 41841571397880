import React, { ReactNode } from 'react';
import { Disclosure, Menu } from '@headlessui/react';
import {
  ChevronDownIcon,
  CogIcon,
  LogoutIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  ChatIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Link, useRouteMatch } from 'react-router-dom';
import { DropDownMenu } from './DropDownMenu';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import useUsers from '../Hooks/useUsers';
import { useOrganization } from '../Hooks/useOrganization';
import { defaultUserImages } from '../Consts/userImages';

let navigation = [] as { name: string; href: string; icon?: ReactNode; current: boolean }[];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Header2 = ({ showUpgradeBtn }: { showUpgradeBtn: boolean }): JSX.Element | null => {
  const { logout, user: auth0User } = useAuth0();
  const { currentUser } = useUsers();
  const [, user] = currentUser();
  const [, org] = useOrganization();
  const currImage =
    defaultUserImages.find((image) => image.name === user?.picture) || defaultUserImages[0];
  const match = useRouteMatch('/projects/:id/notes*');

  const isParticipantPage = useRouteMatch('/participants/');
  const isMainPage = useRouteMatch({
    path: '/',
    exact: true,
  });
  const isProjectPage = useRouteMatch('/projects/');

  navigation = [
    { name: 'Projects', href: '/', current: Boolean(isMainPage) || Boolean(isProjectPage) },
    { name: 'Participants', href: '/participants', current: Boolean(isParticipantPage) },
  ];

  if (match?.isExact) {
    return null;
  }

  return (
    <Disclosure as="nav" className="bg-white z-100 border-b border-gray-200 sticky top-0">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-5 lg:px-5 flex">
            <div className="py-2">
              <div>
                <Link to={'/'}>
                  {org?.logo ? (
                    <img
                      src={org.logo}
                      alt="cover image"
                      style={{
                        width: 32,
                        height: 32,
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <svg
                      className={'hover:opacity-75'}
                      width="32"
                      height="32"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.5097 1.85339H2.52347C1.69125 1.85339 1.0166 2.52804 1.0166 3.36027V12.6397C1.0166 13.472 1.69125 14.1466 2.52347 14.1466H10.1946C10.2564 14.1466 10.3179 14.1455 10.3792 14.1431V14.142C10.6881 14.1419 10.99 14.1116 11.2821 14.0539C13.174 13.68 14.6522 12.1554 14.9573 10.2397C14.9954 10.0003 15.0152 9.75484 15.0152 9.50474V9.34188H15.0166L15.0166 9.32462V3.36027C15.0166 2.52804 14.342 1.85339 13.5097 1.85339ZM14.1125 9.33558L14.1125 9.32462V8.4479H5.40547V10.3853H10.3779V10.2397C10.3779 9.74037 10.7827 9.33558 11.2821 9.33558H14.1125ZM10.3779 11.2894H5.40547V13.2425H10.1946C10.2561 13.2425 10.3172 13.2411 10.3779 13.2383V11.2894ZM4.37475 11.2894V13.2425H2.52347C2.19059 13.2425 1.92073 12.9726 1.92073 12.6397V11.2894H4.37475ZM4.37475 10.3853H1.92073V8.4479H4.37475V10.3853ZM4.37475 7.54378H1.92073V5.61365H4.37475V7.54378ZM4.37475 4.70952H1.92073V3.36027C1.92073 3.02738 2.19059 2.75752 2.52347 2.75752H4.37475V4.70952ZM5.40547 4.70952V2.75752H13.5097C13.8426 2.75752 14.1125 3.02738 14.1125 3.36027V4.70952H5.40547ZM5.40547 5.61365H14.1125V7.54378H5.40547V5.61365ZM14.0388 10.2397C13.7562 11.655 12.6734 12.7816 11.2821 13.1277V10.2397H14.0388Z"
                        fill="#3B2651"
                      />
                    </svg>
                  )}
                </Link>
              </div>
            </div>
            <div className="relative flex items-center justify-between h-13 flex-1">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {navigation.length > 0 && (
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                )}
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-secondary-purple-light' : '',
                          'px-3 py-2 rounded-md font-medium text-primary-purple'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <DropDownMenu
                  button={
                    <Menu.Button className="flex flex-row items-center flex-shrink">
                      <img className="rounded-full h-6 w-6" src={currImage.url} />
                      <div className=" font-body text-sm pl-2">
                        {auth0User?.given_name} {auth0User?.family_name}
                      </div>
                      <div className="pr-5 pl-1">
                        <ChevronDownIcon className=" w-2 h-2" />
                      </div>
                    </Menu.Button>
                  }
                >
                  <Menu.Item>
                    <Link
                      to="/settings/plans"
                      className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                    >
                      <CogIcon className="mr-3 h-5 w-5" />
                      Settings
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href="https://notably.canny.io"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                    >
                      <ChatIcon className="mr-3 h-5 w-5" />
                      Give feedback
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      rel="noreferrer"
                      href="https://help.notably.ai"
                      target="_blank"
                      className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                    >
                      <QuestionMarkCircleIcon className="mr-3 h-5 w-5" />
                      Help Center
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href="#"
                      onClick={() =>
                        logout({
                          returnTo: `${window.location.protocol}//${window.location.hostname}${
                            window.location.port ? ':' + window.location.port : ''
                          }`,
                        })
                      }
                      className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                    >
                      <LogoutIcon className="mr-3 h-5 w-5" />
                      Logout
                    </a>
                  </Menu.Item>
                </DropDownMenu>
                {showUpgradeBtn && (
                  <UpgradeSection>
                    <UpgradeLink to="/settings/plans">Upgrade</UpgradeLink>
                  </UpgradeSection>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-gray-700'
                      : 'text-gray-300 hover:bg-gray-50 hover:text-gray-900',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const UpgradeSection = styled.div`
  border-left: 1px solid #d7d3dc;
  padding-left: 8px;
  display: flex;
`;
const UpgradeLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
`;
const CannyLink = styled.a`
  margin-right: 12px;
`;

export default Header2;
