import { ID, Sticky, Tag } from '../Models';
import { Reference, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_NOTE,
  DELETE_NOTE,
  RUN_SENTIMENT_ANALYSIS,
  UPDATE_NOTE,
} from '../GraphQL/mutations';
import { NoteInput } from '../GraphQL/__generated__/globalTypes';
import { FETCH_NOTES } from '../GraphQL/queries';
import useThemes from './useThemes';
import { RawDraftContentState, RawDraftInlineStyleRange } from 'draft-js';

export default function useNotes(dashboardId: ID = 0) {
  const [createNoteMutation] = useMutation(CREATE_NOTE);
  const [updateNoteMutation] = useMutation(UPDATE_NOTE);
  const [deleteNoteMutation] = useMutation(DELETE_NOTE);
  const [fetch, { loading, data }] = useLazyQuery(FETCH_NOTES);
  const client = useApolloClient();
  const [runSentimentAnalysisMutation] = useMutation(RUN_SENTIMENT_ANALYSIS);

  const { searchThemeByText } = useThemes(dashboardId ?? 1);

  function mapToId(stickies: Sticky[]): { [key: string]: Sticky } {
    return stickies.reduce((a, x: Sticky) => ({ ...a, [x.id]: x }), {});
  }

  async function runSentimentAnalysis(noteId: ID) {
    const result = await runSentimentAnalysisMutation({
      variables: {
        noteId,
      },
      refetchQueries: [
        {
          query: FETCH_NOTES,
          variables: {
            condition: {
              dashboardId,
            },
          },
        },
      ],
    });
    return result?.data?.score;
  }

  function fetchNotes(dashboardId: ID) {
    fetch({
      variables: {
        condition: {
          dashboardId,
        },
      },
    });
    return [loading, data];
  }

  async function deleteNote(id: ID) {
    await deleteNoteMutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            notes(existingRefs, { readField }) {
              return existingRefs.filter((ref: Reference) => id !== readField('id', ref));
            },
          },
        });
      },
    });
  }

  async function updateNote(stickyId: ID, input: NoteInput): Promise<Sticky> {
    const record = await updateNoteMutation({
      variables: { id: stickyId, input },
      update(
        cache,
        {
          data: {
            updateNote: { note },
          },
        }
      ) {
        cache.modify({
          id: cache.identify({
            __typename: 'Note',
            id: stickyId,
          }),
          fields: {
            theme(existing, { toReference }) {
              if (note.themeId) {
                return toReference({
                  __typename: 'Theme',
                  id: note.themeId,
                });
              }
              if (note.themeId === note) {
                return null;
              }
              if (note.theme) {
                return toReference(note.theme);
              }
              return existing;
            },
            participant(existing, { toReference }) {
              if (note.participantId) {
                return toReference({
                  __typename: 'Participant',
                  id: note.participantId,
                });
              }
              if (note.participantId === null) {
                return null;
              }
              return existing;
            },
          },
        });
      },
    });

    return record.data?.updateNote?.note;
  }

  async function createNote(dashboardId: ID, note: NoteInput): Promise<Sticky> {
    note = {
      x: Math.ceil(Math.random() * 1000) + 300,
      y: Math.ceil(Math.random() * 600),
      ...note,
    };

    const record = await createNoteMutation({
      variables: {
        input: {
          dashboardId,
          ...note,
        },
      },
      // optimisticResponse: { // commenting this out, causes re-rendering of the grid and canvas
      //   createNote: {
      //     note: {
      //       __typename: 'Note',
      //       id: -Math.random() * 1000,
      //       text: '',
      //       tagsByItemId: [],
      //       theme: null,
      //       color: null,
      //       dashboardId,
      //       userByCreatedBy: null,
      //       sentimentScore: null,
      //       createdAt: null,
      //       url: null,
      //       ...note,
      //     },
      //   },
      // },
      update(
        cache,
        {
          data: {
            createNote: { note },
          },
        }
      ) {
        cache.modify({
          fields: {
            notes(notes, { toReference }) {
              cache.writeQuery({
                query: FETCH_NOTES,
                variables: { condition: { dashboardId } },
                data: {
                  notes: [...notes, toReference(note)],
                },
              });
              return [...notes, toReference(note)];
            },
          },
        });
      },
    });

    return record.data?.createNote?.note;
  }

  function searchStickyByText(
    items: Sticky[],
    query: string,
    tags?: string[],
    themeIds?: number[],
    participantIds?: string[]
  ): Sticky[] {
    let filteredStickies = items;

    if (tags && tags.length) {
      filteredStickies = filteredStickies.filter(
        (sticky) =>
          sticky.tagsByItemId.filter((value: Tag) => tags.includes(value.name)).length ==
          tags.length
      );
    }

    if (themeIds && themeIds.length) {
      filteredStickies = filteredStickies.filter(
        (x) => x.theme && searchThemeByText([x.theme], query, themeIds).length > 0
      );
    }

    if (participantIds && participantIds.length) {
      filteredStickies = filteredStickies.filter(
        (x) => x.participant && participantIds.includes(x.participant.id)
      );
    }

    if (!query) {
      return filteredStickies;
    }

    const lcQuery = query.toLowerCase();
    return filteredStickies.filter(
      (x) =>
        x.text?.toLowerCase().includes(lcQuery) ||
        x.tagsByItemId.filter((x: Tag) => x.name?.toLowerCase().includes(lcQuery)).length
    );
  }

  const addTagsToNote = async (note: Sticky, tagNames: string[]) => {
    const newTags = tagNames.filter(
      (tagName) => !note.tagsByItemId.find((x: Tag) => x.name == tagName)
    );

    if (!newTags.length) {
      return;
    }

    await updateNoteMutation({
      variables: {
        id: note.id,
        input: {
          tags: {
            create: newTags.map((tagName) => ({
              itemType: 'note',
              name: tagName,
            })),
          },
        },
      },
      optimisticResponse: {
        updateNote: {
          __typename: 'UpdateNotePayload',
          note: {
            ...note,
            tagsByItemId: [
              ...note.tagsByItemId,
              ...newTags.map((tagName) => ({ id: '', __typename: 'Tag', name: tagName })),
            ],
          },
        },
      },
    });
  };

  const removeTagsFromNote = async (note: Sticky, tagNames: string[]) => {
    const tagsToRemove: Tag[] = note.tagsByItemId.filter(
      (x: Tag) => tagNames.indexOf(x.name) != -1
    );

    if (!tagsToRemove.length) {
      return;
    }

    await updateNoteMutation({
      variables: {
        id: note.id,
        input: {
          tags: {
            deleteById: tagsToRemove.map(({ id }) => ({
              id,
            })),
          },
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify({
            __typename: 'Note',
            id: note.id,
          }),
          fields: {
            tagsByItemId(existingTags, { readField }) {
              return existingTags.filter(
                (tagRef: Reference) => tagNames.indexOf(readField('name', tagRef) ?? '') == -1
              );
            },
          },
        });
      },
    });
  };

  async function fetchNotesByTranscriptId(dashboardId: ID, transcriptionId: ID) {
    const {
      data: { notes },
    } = await client.query({
      query: FETCH_NOTES,
      variables: {
        condition: {
          dashboardId,
          transcriptionId,
        },
      },
    });

    return notes;
  }

  async function createNotesFromDraftState(
    raw: RawDraftContentState,
    options: { transcriptId?: ID; documentId?: ID; participantId?: ID; tags?: string[] }
  ) {
    await Promise.all(
      raw.blocks.map((block) => {
        Promise.all(
          block.entityRanges.map((range) => {
            const currentEntity = raw.entityMap[range.key];
            if (currentEntity.type !== 'HIGHLIGHT') {
              return;
            }

            const entityText = block.text.substr(range.offset, range.length);
            const entityTags = currentEntity.data.tags || [];
            const allTags = [...entityTags, ...(options.tags || [])].map((tag) => ({
              itemType: 'note',
              name: tag,
            }));

            const timeOffset = Math.floor(block.data?.start / 1000);

            return createNote(dashboardId, {
              transcriptionId: options.transcriptId,
              documentId: options.documentId,
              participantId: options.participantId,
              text: entityText,
              tags: {
                create: allTags,
              },
              options: JSON.stringify({
                timeOffset: Number.isInteger(timeOffset) ? timeOffset : null,
              }),
            });
          })
        );
      })
    );
  }

  return {
    fetchNotes,
    deleteNote,
    updateNote,
    createNote,
    mapToId,
    searchStickyByText,
    addTagsToNote,
    removeTagsFromNote,
    runSentimentAnalysis,
    createNotesFromDraftState,
    fetchNotesByTranscriptId,
  };
}
