import React from 'react';

export default function InsightsIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5801 16H19.5801H13.5801ZM13.5801 20H19.5801H13.5801ZM21.5801 25H11.5801C10.4755 25 9.58008 24.1046 9.58008 23V9C9.58008 7.89543 10.4755 7 11.5801 7H17.1659C17.4311 7 17.6855 7.10536 17.873 7.29289L23.2872 12.7071C23.4747 12.8946 23.5801 13.149 23.5801 13.4142V23C23.5801 24.1046 22.6847 25 21.5801 25Z"
        stroke="#382152"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
