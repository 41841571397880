import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CalendarIcon, UserIcon } from '@heroicons/react/outline';
import moment from 'moment';

import Editor from './Editor';
import Avatar from './Avatar';
import InsightCoverPhoto from '../Components/InsightCoverPhoto';

type Props = {
  width?: number;
  expanded?: boolean;
  isInsightsPanel: boolean;
  insight: any;
  onSave: (content: any) => void;
  className?: string;
};

export default function InsightEditor({
  insight,
  onSave,
  width,
  className,
  expanded = true,
  isInsightsPanel,
}: Props): JSX.Element {
  return (
    <InsightEditorContainer maxWidth={width} className={`insight-editor h-full ${className}`}>
      {isInsightsPanel && (
        <InsightCoverPhoto
          data={insight}
          expanded={expanded}
          isInsightsPanel={isInsightsPanel}
          updateInsight={onSave}
        />
      )}
      <Details maxWidth={width}>
        <TitleInput
          defaultValue={insight?.title}
          onBlur={(e) => onSave({ title: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
          placeholder="Untitled insight"
          className={'text-3xl my-6 w-full font-medium'}
          autoFocus={!insight?.title}
        />
        <DetailsRow>
          <LabelWithIcon>
            <UserIconI />
            <Label>Created by</Label>
          </LabelWithIcon>
          <div className="flex items-center flex-1">
            <Avatar user={insight?.userByCreatedBy} />
            <UserName>{insight?.userByCreatedBy.name}</UserName>
          </div>
        </DetailsRow>
        <DetailsRow>
          <LabelWithIcon>
            <CalendarIconI />
            <Label>Date</Label>
          </LabelWithIcon>
          <div>
            <div>{moment(insight?.createdAt).format('MM/DD/YYYY')}</div>
          </div>
        </DetailsRow>
      </Details>

      <Editor
        width={width}
        className={'prose-sm'}
        onSave={(content) => {
          onSave({ content });
        }}
        content={insight?.content}
        placeholder="add more description..."
      />
    </InsightEditorContainer>
  );
}

interface InsightEditorContainerProps {
  readonly maxWidth?: number;
}

interface DetailsProps {
  readonly maxWidth?: number;
}

const InsightEditorContainer = styled.div<InsightEditorContainerProps>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${(props) => {
    if (props.maxWidth) {
      return `overflow: scroll;`;
    }
  }}
`;

const TitleInput = styled(TextareaAutosize)`
  outline: none;
  transition: 0.3s ease 0s;
`;

const Details = styled.div<DetailsProps>`
  padding: 0 12px 16px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + 'px' : '800px')};
  margin: auto;
`;

const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
`;

const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

const UserIconI = styled(UserIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;
const CalendarIconI = styled(CalendarIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

const UserName = styled.span`
  margin-left: 8px;
  flex: 1 1 0%;
`;
