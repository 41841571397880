import { default as UserPhoto1 } from '../assets/onboarding/default-user-photos/user-photo-1.png';
import { default as UserPhoto2 } from '../assets/onboarding/default-user-photos/user-photo-2.png';
import { default as UserPhoto3 } from '../assets/onboarding/default-user-photos/user-photo-3.png';
import { default as UserPhoto4 } from '../assets/onboarding/default-user-photos/user-photo-4.png';
import { default as UserPhoto5 } from '../assets/onboarding/default-user-photos/user-photo-5.png';
import { default as UserPhoto6 } from '../assets/onboarding/default-user-photos/user-photo-6.png';
import { default as UserPhoto7 } from '../assets/onboarding/default-user-photos/user-photo-7.png';
import { default as UserPhoto8 } from '../assets/onboarding/default-user-photos/user-photo-8.png';
import { default as UserPhoto9 } from '../assets/onboarding/default-user-photos/user-photo-9.png';
import { default as UserPhoto10 } from '../assets/onboarding/default-user-photos/user-photo-10.png';
import { default as UserPhoto11 } from '../assets/onboarding/default-user-photos/user-photo-11.png';
import { default as UserPhoto12 } from '../assets/onboarding/default-user-photos/user-photo-12.png';
import { default as UserPhoto13 } from '../assets/onboarding/default-user-photos/user-photo-13.png';
import { default as UserPhoto14 } from '../assets/onboarding/default-user-photos/user-photo-14.png';
import { default as UserPhoto15 } from '../assets/onboarding/default-user-photos/user-photo-15.png';
import { default as UserPhoto16 } from '../assets/onboarding/default-user-photos/user-photo-16.png';
import { default as UserPhotoCuriosity } from '../assets/onboarding/default-user-photos/user-photo-curiosity.png';
import { default as UserPhotoDaring } from '../assets/onboarding/default-user-photos/user-photo-daring.png';
import { default as UserPhotoEmpathy } from '../assets/onboarding/default-user-photos/user-photo-empathy.png';
import { default as UserPhotoTrust } from '../assets/onboarding/default-user-photos/user-photo-trust.png';

export const defaultUserImages = [
  { name: 'DefaultUserPhoto1', url: UserPhoto1, position: '50% 50%' },
  { name: 'DefaultUserPhoto2', url: UserPhoto2, position: '50% 50%' },
  { name: 'DefaultUserPhoto3', url: UserPhoto3, position: '50% 50%' },
  { name: 'DefaultUserPhoto4', url: UserPhoto4, position: '50% 50%' },
  { name: 'DefaultUserPhoto5', url: UserPhoto5, position: '50% 50%' },
  { name: 'DefaultUserPhoto6', url: UserPhoto6, position: '50% 50%' },
  { name: 'DefaultUserPhoto7', url: UserPhoto7, position: '50% 50%' },
  { name: 'DefaultUserPhoto8', url: UserPhoto8, position: '50% 50%' },
  { name: 'DefaultUserPhoto9', url: UserPhoto9, position: '50% 50%' },
  { name: 'DefaultUserPhoto10', url: UserPhoto10, position: '50% 50%' },
  { name: 'DefaultUserPhoto11', url: UserPhoto11, position: '50% 50%' },
  { name: 'DefaultUserPhoto12', url: UserPhoto12, position: '50% 50%' },
  { name: 'DefaultUserPhoto13', url: UserPhoto13, position: '50% 50%' },
  { name: 'DefaultUserPhoto14', url: UserPhoto14, position: '50% 50%' },
  { name: 'DefaultUserPhoto15', url: UserPhoto15, position: '50% 50%' },
  { name: 'DefaultUserPhoto16', url: UserPhoto16, position: '50% 50%' },
  { name: 'DefaultUserPhotoCuriosity', url: UserPhotoCuriosity, position: '50% 50%' },
  { name: 'DefaultUserPhotoDaring', url: UserPhotoDaring, position: '50% 50%' },
  { name: 'DefaultUserPhotoEmpathy', url: UserPhotoEmpathy, position: '50% 50%' },
  { name: 'DefaultUserPhotoTrust', url: UserPhotoTrust, position: '50% 50%' },
];
