import React, { useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import moment from 'moment';
import { DocumentTextIcon, TrashIcon, VideoCameraIcon } from '@heroicons/react/outline';
import { Loader } from '../Components';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Button from '../Components/Button';
import EmptyState from '../Components/EmptyState';
import { useSegment } from 'react-segment-hooks';
import { useFetchParticipant, useDeleteParticipant } from '../Hooks/useParticipants';
import styled from 'styled-components';
import ParticipantEditor from '../Components/ParticipantEditor';
import {
  HeaderCell,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '../Components/TableWithBorders';
import Avatar from '../Components/Avatar';

const MainContent = styled.div`
  max-width: 800px;
  margin: auto;
  margin-top: 32px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

const TabHeader = styled.h2`
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  margin-top: 24px;
`;

const TableContainer = styled.div`
  margin-top: 24px;
`;

const ItemName = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const UserName = styled.span`
  margin-left: 8px;
`;

function Participant(): JSX.Element {
  const history = useHistory();

  const { participantId } = useParams<{ participantId: string }>();

  const analytics = useSegment();

  useEffect(() => {
    analytics.page({
      name: 'Participant',
      properties: {
        id: participantId,
      },
    });
  }, []);

  const [loading, participant] = useFetchParticipant(participantId);
  const [deleteParticipant] = useDeleteParticipant();

  if (loading || !participant) {
    return <Loader />;
  }

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete "${participant.name}"? This cannot be undone.`)) {
      return;
    }
    await deleteParticipant(participantId);

    analytics.track({
      event: 'DeleteParticipant',
      properties: {
        id: participantId,
      },
    });
    history.push('/participants');
  }

  const transcriptItems = participant.transcriptions?.map((x: any) => ({
    key: 'transcript' + x.id,
    icon: <VideoCameraIcon className={'w-6 h-6'} />,
    name: x.name,
    path: `/projects/${x.dashboardId}/data/${x.id}`,
    createdBy: x.userByCreatedBy,
    updatedAt: x.updatedAt,
  }));

  const documentItems = participant.documents?.map((x: any) => ({
    key: 'document' + x.id,
    icon: <DocumentTextIcon className={'w-6 h-6'} />,
    name: x.name,
    path: `/projects/${x.dashboardId}/data/docs/${x.id}`,
    createdBy: x.userByCreatedBy,
    updatedAt: x.updatedAt,
  }));

  const allItems = [...transcriptItems, ...documentItems].sort((x, y) =>
    x.createdAt >= y.createdAt ? -1 : 1
  );

  return (
    <div className="flex-col h-full overflow-hidden">
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>
              <Link to={`/participants`}>Participants</Link> /{' '}
              {participant.name || 'New participant'}
            </h1>
          </div>
          <div className={'flex'}>
            <Button
              className={'mr-2 flex-inline'}
              onClick={() => handleDelete()}
              type={'secondary'}
            >
              <TrashIcon className={'w-4 h-4 mr-1'} />
              Delete participant
            </Button>
          </div>
        </div>
      </SecondaryToolbar>
      <MainContent>
        <ParticipantEditor participant={participant} />

        <TabHeader>Data</TabHeader>

        {!allItems.length && (
          <EmptyState>
            <div className={'text-center'}>
              There’s no data for this person, yet.
              <br />
              Data associated with this person will show up here.
            </div>
          </EmptyState>
        )}

        {!!allItems.length && (
          <TableContainer>
            <Table className="min-w-full divide-y divide-gray-200">
              <TableHeader>
                <tr>
                  <HeaderCell scope="col" className="px-6 py-3 text-left">
                    Name
                  </HeaderCell>
                  <HeaderCell scope="col" className="px-6 py-3 text-left">
                    Last Updated
                  </HeaderCell>
                  <HeaderCell scope="col" className="px-6 py-3 text-left">
                    Created By
                  </HeaderCell>
                </tr>
              </TableHeader>
              <tbody className="bg-white divide-y divide-gray-200">
                {allItems.map((item: any) => (
                  <TableRow
                    key={item.key}
                    onClick={() => {
                      history.push(item.path);
                    }}
                  >
                    <TableCell className="px-6 py-4 whitespace-nowrap">
                      <ItemName>
                        <IconContainer>{item.icon}</IconContainer>
                        {item.name || 'Untitled'}
                      </ItemName>
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap">
                      {item.updatedAt ? moment(item.updatedAt).format('MM/DD/YYYY') : ''}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Avatar user={item?.createdBy} />
                        <UserName>{item?.createdBy?.name}</UserName>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </MainContent>
    </div>
  );
}

export default Participant;
