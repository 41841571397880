import * as React from 'react';

export default function CollapseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2929 18.3639H5.63603M5.63603 18.3639V12.707M5.63603 18.3639L18.364 5.63593"
        stroke="#382152"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
