import React, { PropsWithChildren } from 'react';

export default function EmptyState({ children }: PropsWithChildren<any>) {
  return (
    <div className="flex flex-col items-center mt-10">
      <div className="empty-state " />
      <div className="text-gray-500 font-md">{children}</div>
    </div>
  );
}
