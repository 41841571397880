import React from 'react';

export default function HighlightIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9697 17.7162H1.59766L3.97903 15.3403"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3574 1.23047L18.0789 4.95197L7.88645 15.1444L4.16496 15.1444L4.16496 11.4229L14.3574 1.23047Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M4.0127 11.3506C4.0127 11.3506 5.41377 11.3505 6.51987 12.454C7.62597 13.5575 7.62597 14.9552 7.62597 14.9552"
        stroke="#3B2651"
        strokeWidth="1.5"
      />
    </svg>
  );
}
