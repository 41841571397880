import React, { ChangeEvent, MouseEvent, ReactText, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@apollo/client';
import { REGISTER } from '../GraphQL/mutations';
import { toast } from 'react-toastify';
import NotablyLogo from '../Icons/NotablyLogo';
import BackgroundImage from '../assets/onboarding/BackgroundImage.png';
import { CoverProps } from '../Models';
import useFiles from '../Hooks/useFiles';
import axios from 'axios';
import OnboardingStep1 from '../Components/OnboardingStep1';
import OnboardingStep2 from '../Components/OnboardingStep2';
import { useHistory } from 'react-router';
import useUsers from '../Hooks/useUsers';
import { useUpdateOrganization } from '../Hooks/useOrganization';

function generateWorkspaceName(s?: string) {
  return (
    s
      ?.toLowerCase()
      .replace(/[^a-z0-9]+/gi, '-')
      .replace(/[-]+$/, '')
      .replace(/^[-]+/, '') ?? ''
  );
}

export default function Onboarding(): JSX.Element | null {
  const { user: auth0user, loginWithRedirect } = useAuth0();
  const history = useHistory();
  const { currentUser, updateUser } = useUsers();
  const [, user] = currentUser();
  const [updateOrg] = useUpdateOrganization();

  const userInvited = auth0user?.['https://notably.ai/claims/user_invited'] === true;

  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('');

  const onboardingStatus = localStorage.getItem('onboardingStatus');

  const stepNumber = onboardingStatus === 'pending_step_2' ? 2 : 1;

  const [registerMutation, { loading, error }] = useMutation(REGISTER);
  const [selectedImage, setSelectedImage] = useState<null | CoverProps>(null);
  const [selectedLogo, setSelectedLogo] = useState<null | string>(null);
  const [uploading, setUploading] = useState(false);
  const toastRef = useRef<ReactText | null>(null);

  const { uploadFileToS3 } = useFiles();
  const cancel = axios.CancelToken.source();

  const onImageClick = (image: CoverProps) => () => {
    updateUser(user.id, { picture: image.name });
    setSelectedImage(image);
  };

  const onLogoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (uploading) {
      return;
    }

    if (e.target.files == null || e.target.files.length == 0) {
      return;
    }

    setUploading(true);

    const file = e.target.files[0];
    if (!file.name.endsWith('.png') && !file.name.endsWith('.gif')) {
      toastRef.current = toast(<>Only .png and .gif files are supported!</>, {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    try {
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        isPublic: true,
      });

      setSelectedLogo(result.url);
      updateOrg({ logo: result.url });

      if (!result.ok) {
        throw new Error('Error uploading file');
      }
    } catch {
      if (axios.isCancel(e)) {
        return;
      }

      toastRef.current = toast(<>Unexpected error while uploading file.</>, {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    setUploading(false);
  };

  async function handleRegister(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    try {
      const result = await registerMutation({
        variables: {
          input: {
            companyName: userInvited ? 'whatever' : companyName,
            fullName: fullName,
            slug: generateWorkspaceName(auth0user?.email),
          },
        },
      });

      if (result && result.data && result.data.register.success) {
        await loginWithRedirect();
        localStorage.setItem('onboardingStatus', 'step_1_complete');
      } else {
        toast.error('Unable to register please try again.', {
          autoClose: 5000,
        });
      }
    } finally {
    }
  }

  const updateCurrentPage = (e: MouseEvent) => {
    if (stepNumber === 1) {
      handleRegister(e);
    } else {
      localStorage.removeItem('onboardingStatus');
      history.push('/');
    }
  };

  if (error) {
    toast.error(error);
  }

  const shouldSkipStepTwo = stepNumber === 2 && userInvited;

  if (shouldSkipStepTwo) {
    return null;
  }

  return (
    <div
      className="min-h-screen"
      style={{
        backgroundColor: '#FEFBF6',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="absolute top-10 left-10">
        <NotablyLogo />
      </div>
      <div className="absolute top-[175px] left-[210px] flex flex-col justify-center">
        <div className="w-full sm:w-500">
          <div className="bg-white py-10 px-4 shadow sm:rounded-lg">
            {stepNumber === 1 && (
              <OnboardingStep1
                loading={loading}
                setCompanyName={setCompanyName}
                setFullName={setFullName}
                updateCurrentPage={updateCurrentPage}
              />
            )}
            {stepNumber === 2 && (
              <OnboardingStep2
                loading={false}
                onImageClick={onImageClick}
                onLogoUpload={onLogoUpload}
                selectedImage={selectedImage}
                selectedLogo={selectedLogo}
                updateCurrentPage={updateCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
