import { DEFAULT_STICKY_COLOR } from '../Models';

export const STICKY_COLORS = [
  '#A3E4FF',
  '#A0E4CA',
  '#D0F9B0',
  '#C4F6E4',
  '#A3BDFF',
  '#D1DEFF',
  '#D8C0F8',
  '#FCC8F7',
  '#FAB6B6',
  '#FECC9E',
  '#C4C4C4',
  '#E5E5E5',
  '#FAF3CD',
];

export const GROUPING_COLORS = {
  themeColor: '#E5E5E5',
  default: '#fff' || DEFAULT_STICKY_COLOR,
  sentimentScore: {
    neutral: '#E5E5E5',
    positive: '#A0E4CA',
    negative: '#FAB6B6',
  },
  tag: '#D4C2F4',
  participant: '#D4C2F4',
};
