import * as React from 'react';

export default function SheetIcon({ ...p }) {
  return (
    <svg
      width="26"
      height="26"
      {...p}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16455 0.914551H15.8355C16.5258 0.914551 17.0855 1.47419 17.0855 2.16455V15.8355C17.0855 16.5258 16.5258 17.0855 15.8355 17.0855H2.16455C1.47419 17.0855 0.914551 16.5258 0.914551 15.8355V2.16455C0.914551 1.47419 1.47419 0.914551 2.16455 0.914551Z"
        fill="white"
        stroke="#382152"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M0.164551 7.10767H17.8355"
        stroke="#382152"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M0.164551 12.3071H17.8355"
        stroke="#382152"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M5.36377 0.164551V17.8355"
        stroke="#382152"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
