import * as React from 'react';
import { useEffect, useState } from 'react';
import { RawDraftContentState } from 'draft-js';
import styled from 'styled-components';
import { flatten, uniq } from 'lodash';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from 'draftail';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useNotes from '../Hooks/useNotes';
import { useHistory } from 'react-router';
import { useDeleteDocument, useFetchDocument, useUpdateDocument } from '../Hooks/useDocuments';
import { Loader } from '../Components';
import Button from '../Components/Button';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Well from '../Components/Well';
import { Sticky } from '../Models';
import { toast } from 'react-toastify';
import Avatar from '../Components/Avatar';
import { ItalicIcon, OrderedListIcon, UnorderedListIcon } from '../Icons/EditorIcons';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import { useEffectOnce } from 'usehooks-ts';

import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import { CalendarIcon, TrashIcon, UserIcon, TagIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useSegment } from 'react-segment-hooks';
import TextareaAutosize from 'react-textarea-autosize';
import ParticipantPicker from '../Components/ParticipantPicker';
import TagsPicker from '../Components/TagsPicker';
import InlineToolbarButtons from '../Components/InlineToolbarButtons';
import Highlight from '../Components/Highlight';
import TagsPanel from '../Components/TagsPanel';

const plugin = createInlineToolbarPlugin();
const { InlineToolbar } = plugin;

const inlineStyles = [
  { type: INLINE_STYLE.BOLD },
  { type: INLINE_STYLE.ITALIC, icon: <ItalicIcon /> },
];

const highlightEntity = {
  type: 'HIGHLIGHT',
  decorator: Highlight,
};

export default function Document(): JSX.Element {
  const { dashboardId, documentId } = useParams<{ dashboardId: string; documentId: string }>();

  const [editorState, setEditorState] = useState<RawDraftContentState | null>(null);

  const [editorInitialized, setEditorInitialized] = useState<boolean>(false);
  const [loading, document] = useFetchDocument(documentId);

  const [updateDoc] = useUpdateDocument();
  const [deleteDoc] = useDeleteDocument();

  const { createNotesFromDraftState, deleteNote } = useNotes(dashboardId);
  const history = useHistory();

  const analytics = useSegment();

  useEffectOnce(() => {
    localStorage.removeItem('tagsPanel');
  });

  useEffect(() => {
    analytics.page({
      name: 'Document',
      properties: {
        id: documentId,
        projectId: dashboardId,
      },
    });
  }, []);

  const onSave = async (state: RawDraftContentState) => {
    setEditorState(state);

    await updateDoc(documentId, {
      content: JSON.stringify(state),
    });
  };

  useEffect(() => {
    if (editorState) {
      return;
    }
    if (!loading && document) {
      const rawState = JSON.parse(document.content);
      setEditorState(rawState);
      setEditorInitialized(true);
    }
  }, [loading]);

  async function createNotes() {
    if (editorState) {
      const toastId = toast.loading('Creating notes from highlighted text...');
      const notesCount = document.notesConnection.totalCount;
      if (
        notesCount > 0 &&
        !confirm(
          `Do you want to overwrite ${notesCount} notes created previously from this document?`
        )
      ) {
        toast.update(toastId, {
          render: 'Creation cancelled',
          isLoading: false,
          type: 'info',
          autoClose: 1000,
        });
        return;
      }

      await Promise.all(document.notes.map((x: Sticky) => deleteNote(x.id)));

      await createNotesFromDraftState(editorState, {
        documentId,
        participantId: document.participantId,
        tags: document.tags,
      });

      toast.update(toastId, {
        render: 'Successfully created notes from document!',
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      });

      history.push('/projects/' + dashboardId + '/notes');
    }
  }

  async function updateName(newName: string) {
    updateDoc(documentId, {
      name: newName,
    });
  }

  async function updateParticipant(participantId: string | null) {
    updateDoc(documentId, {
      participantId,
    });
  }
  async function updateTags(tags: Array<string> | null) {
    updateDoc(documentId, {
      tags,
    });
  }

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete "${document.name}"? This cannot be undone.`)) {
      return;
    }
    await deleteDoc(dashboardId, documentId);

    analytics.track({
      event: 'DeleteDocument',
      properties: {
        id: documentId,
      },
    });
    history.push('/projects/' + dashboardId + '/data');
  }

  if (loading || !editorInitialized) {
    return <Loader />;
  }

  const createEnabled =
    editorState?.blocks && editorState?.blocks.findIndex((x) => x.entityRanges?.length > 0) != -1;

  const inlineTags: string[] = editorState
    ? uniq(
        flatten(
          Object.keys(editorState.entityMap).map(
            (entityKey) => editorState.entityMap[entityKey].data.tags || []
          )
        )
      )
    : [];
  return (
    <div className={'flex-col'}>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between border-b border-secondary-purple-light">
          <div>
            <h1 className={'text-l font-medium mt-1'}>
              <Link to={`/projects/${dashboardId}/data`}>Data</Link> /{' '}
              {document.name ?? 'Untitled document'}
            </h1>
          </div>
          <div className={'flex'}>
            <Button
              className={'mr-2 flex-inline'}
              onClick={() => handleDelete()}
              type={'secondary'}
            >
              <TrashIcon className={'w-4 h-4 mr-1'} />
              Delete
            </Button>
            <Button
              className={createEnabled ? '' : 'pointer-events-none opacity-75'}
              onClick={createNotes}
            >
              Add highlights as notes
            </Button>
          </div>
        </div>
      </SecondaryToolbar>
      <EditorContainer className={'document-editor h-full'}>
        <EditorLeftPanel>
          <Well wellKey="document-record-well">
            Use documents to take notes, then highlight into smaller snippets for analysis or as a
            place to add content related to this research project such as an interview guide, a
            script, or whatever creative space you need to keep your data organized.
          </Well>
          <Details className="pb-4">
            <NameInput
              defaultValue={document?.name}
              onBlur={(e) => updateName(e.target.value)}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              placeholder="Untitled document"
              className={'text-3xl my-6 w-full'}
              autoFocus={!document?.name}
            />
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Created by</Label>
              </LabelWithIcon>
              <div className="flex items-center">
                <Avatar user={document?.userByCreatedBy} />
                <UserName>{document?.userByCreatedBy.name}</UserName>
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Participant</Label>
              </LabelWithIcon>
              <div>
                <ParticipantPicker
                  participant={document.participant}
                  onChange={updateParticipant}
                />
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <CalendarIconI />
                <Label>Date</Label>
              </LabelWithIcon>
              <div>
                <div>{moment(document?.createdAt).format('MM/DD/YYYY')}</div>
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <TagIconI />
                <Label>Tags</Label>
              </LabelWithIcon>
              <div>
                <TagsPicker tags={document.tags} onChange={updateTags} dashboardId={dashboardId} />
              </div>
            </DetailsRow>
          </Details>

          <div className={'prose-sm'}>
            <DraftailEditor
              className={'prose'}
              editorKey="CustomInlineToolbarEditor"
              entityTypes={[{ ...highlightEntity }]}
              rawContentState={editorState || null}
              onSave={onSave}
              inlineStyles={inlineStyles}
              plugins={[plugin]}
              placeholder="Start typing or paste content here..."
              blockTypes={[
                { type: BLOCK_TYPE.HEADER_ONE },
                { type: BLOCK_TYPE.HEADER_TWO },
                { type: BLOCK_TYPE.HEADER_THREE },
                { type: BLOCK_TYPE.ORDERED_LIST_ITEM, icon: <OrderedListIcon /> },
                { type: BLOCK_TYPE.UNORDERED_LIST_ITEM, icon: <UnorderedListIcon /> },
              ]}
            />
          </div>
          <InlineToolbar>
            {(props) => (
              <InlineToolbarButtons dashboardId={dashboardId} inlineTags={inlineTags} {...props} />
            )}
          </InlineToolbar>
        </EditorLeftPanel>
        <EditorRightPanel>
          <TagsPanel entityMap={editorState?.entityMap} />
        </EditorRightPanel>
      </EditorContainer>
    </div>
  );
}

const EditorContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const EditorLeftPanel = styled.div`
  margin-top: 30px;
  width: 800px;
  margin-left: 48px;
`;
const EditorRightPanel = styled.div`
  border-left: 1px solid #e5e5e5;
  min-width: 200px;
  min-height: calc(100vh - 108px);
  margin-left: 48px;
`;

const NameInput = styled(TextareaAutosize)`
  outline: none;
  transition: 0.3s ease 0s;
`;

const Details = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;

const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
`;

const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

const UserIconI = styled(UserIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;
const TagIconI = styled(TagIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;
const CalendarIconI = styled(CalendarIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

const UserName = styled.span`
  margin-left: 8px;
`;
