import * as React from 'react';
import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from 'draftail';
import { useMemo } from 'react';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { RawDraftContentState } from 'draft-js';
import { ItalicIcon, OrderedListIcon, UnorderedListIcon } from '../Icons/EditorIcons';

type Props = {
  width?: number;
  onSave: (content: string) => void;
  className?: string;
  content?: string;
  placeholder?: string;
};

export default function Editor({
  className,
  onSave,
  content = '',
  placeholder,
}: Props): JSX.Element {
  const memoEditor = useMemo(() => {
    return (
      <DraftailEditor
        rawContentState={markdownToDraft(content, { preserveNewlines: true }) || null}
        onSave={(newContent: RawDraftContentState) => {
          onSave(newContent ? draftToMarkdown(newContent, { preserveNewlines: true }) : '');
        }}
        placeholder={placeholder}
        blockTypes={[
          { type: BLOCK_TYPE.HEADER_ONE },
          { type: BLOCK_TYPE.HEADER_TWO },
          { type: BLOCK_TYPE.HEADER_THREE },
          { type: BLOCK_TYPE.ORDERED_LIST_ITEM, icon: <OrderedListIcon /> },
          { type: BLOCK_TYPE.UNORDERED_LIST_ITEM, icon: <UnorderedListIcon /> },
        ]}
        className={'prose'}
        inlineStyles={[
          { type: INLINE_STYLE.BOLD },
          { type: INLINE_STYLE.ITALIC, icon: <ItalicIcon /> },
        ]}
      />
    );
  }, [content, onSave, placeholder]);

  return <div className={className}>{memoEditor}</div>;
}
