export const isAudio = (src: string): boolean => {
  return src.endsWith('.mp3') || src.endsWith('.m4a');
};
export const isVideo = (src: string): boolean => {
  return src.endsWith('.mp4');
};

export const isVideoOrAudio = (src: string): boolean => {
  return isAudio(src) || isVideo(src);
};

export const getFileType = (src: string): string => {
  if (isAudio(src)) return 'audio';
  if (isVideo(src)) return 'video';
  return 'unknown';
};
