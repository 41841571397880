import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import colors from '../Consts/colors';
import ReactTooltip, { Place } from 'react-tooltip';
import styled from 'styled-components'

interface Props {
  place: Place;
  arrowColor?: string;
  isOpen: boolean;
  content: React.ReactNode;
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(59, 38, 81, 0.2);
  z-index: 900;
`

const Spotlight = styled.div`
  z-index: 999;
  position: relative;
`

const FocusToolTip = ({ children, place, arrowColor, isOpen, content }: PropsWithChildren<Props>): JSX.Element => {
  const [ isActuallyOpen, setIsActuallyOpen ] = useState(false)

  useEffect(() => {
    // this is a hack to give the intercom banner time to pop up without screwing up the positioning of the tooltip
    setTimeout(() => setIsActuallyOpen(isOpen), 750)
  }, [isOpen])

  const containerRef = useCallback(container => {
    if (container !== null) {
      ReactTooltip.show(container);
      const tooltip = document.getElementsByClassName("__react_component_tooltip")[0]
      tooltip.setAttribute("style", "padding: 0; border: 0; opacity: 1;")
    }
  }, []);

  return isActuallyOpen ? <>
    <Overlay />
    <Spotlight data-tip="" data-place={place} ref={containerRef}>
      {children}
      <ReactTooltip arrowColor={arrowColor} place={place} scrollHide={false} resizeHide={false} clickable event='none' eventOff='none' effect='solid' border={false} backgroundColor='white' textColor={colors.primary.purple} getContent={() => content} />
    </Spotlight>
  </> :
  <>{children}</>
}

export default FocusToolTip
