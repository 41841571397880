import { Reference, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_DASHBOARD, DELETE_DASHBOARD, UPDATE_DASHBOARD } from '../GraphQL/mutations';
import { getDashboards, getDashboards_dashboards } from '../GraphQL/__generated__/getDashboards';
import { CURRENT_ORG, FETCH_DASHBOARD, FETCH_DASHBOARDS } from '../GraphQL/queries';
import { useEffect, useState } from 'react';
import { ID, Sticky, Tag } from '../Models';
import * as _ from 'lodash';
import useNotes from './useNotes';
import useThemes from './useThemes';
import useProjectInfo from './useProjectInfo';
import useTranscripts from './useTranscripts';
import useFiles from './useFiles';

type FetchBoardsTuple = [boolean, getDashboards_dashboards[] | null];
type FetchBoardTuple = [boolean, getDashboards_dashboards | null];

type UseBoardsType = {
  fetchBoards: () => FetchBoardsTuple;
  fetchBoard: (id: number) => FetchBoardTuple;
  updateBoard: (id: number, input: Partial<getDashboards_dashboards>) => Promise<void>;
  createBoard: (name: string) => Promise<getDashboards_dashboards>;
  deleteBoard: (id: number) => Promise<void>;
  copyBoard: (board: getDashboards_dashboards) => Promise<getDashboards_dashboards>;
};

export default function useBoards(): UseBoardsType {
  const [createDashboardMutation] = useMutation(CREATE_DASHBOARD);
  const [deleteDashboardMutation] = useMutation(DELETE_DASHBOARD);
  const [updateDashboardMutation] = useMutation(UPDATE_DASHBOARD);
  const [
    fetchBoardsQuery,
    {
      called: fetchBoardsQueryCalled,
      loading: fetchBoardsQueryLoading,
      data: fetchBoardsQueryData,
    },
  ] = useLazyQuery<getDashboards>(FETCH_DASHBOARDS);

  const [dashboards, setDashboards] = useState<getDashboards_dashboards[]>([]);
  const [dashboard, setDashboard] = useState<getDashboards_dashboards | null>(null);

  const { createNote } = useNotes(1);
  const { createTheme } = useThemes(1);
  const { createProjectInfo } = useProjectInfo(1);
  const { createTranscript } = useTranscripts();
  const { createFile } = useFiles();

  const { loading, data, refetch } = useQuery(FETCH_DASHBOARD, { variables: { dashboardId: 0 } });

  async function createBoard(name: string, rest?: any) {
    const {
      data: {
        createDashboard: { dashboard },
      },
    } = await createDashboardMutation({
      variables: {
        input: { name, ...(rest ?? {}) },
      },
      optimisticResponse: {
        createDashboard: {
          dashboard: { name, ...(rest ?? {}) },
        },
      },
      update(
        cache,
        {
          data: {
            createDashboard: { dashboard },
          },
        }
      ) {
        cache.modify({
          fields: {
            dashboards(dashboards) {
              return [...dashboards, dashboard];
            },
          },
        });
      },
      refetchQueries: [
        {
          query: CURRENT_ORG,
        },
      ],
    });

    return dashboard;
  }

  async function deleteBoard(dashboardId: ID) {
    await deleteDashboardMutation({
      variables: {
        id: dashboardId,
      },
      update(cache) {
        cache.modify({
          fields: {
            dashboards(existingDashboardRefs, { readField }) {
              return existingDashboardRefs.filter(
                (dashboardRef: Reference) => dashboardId !== readField('id', dashboardRef)
              );
            },
          },
        });
      },
    });
  }

  useEffect(() => {
    if (!fetchBoardsQueryLoading && fetchBoardsQueryData && fetchBoardsQueryData.dashboards) {
      setDashboards(fetchBoardsQueryData.dashboards);
    }
  }, [fetchBoardsQueryLoading, fetchBoardsQueryData]);

  useEffect(() => {
    if (!loading && data && data.dashboard) {
      setDashboard(data.dashboard);
    }
  }, [loading, data]);

  function fetchBoards() {
    if (!fetchBoardsQueryCalled) {
      fetchBoardsQuery();
    }

    return [fetchBoardsQueryLoading, dashboards] as FetchBoardsTuple;
  }

  function fetchBoard(id: ID) {
    if (id && parseInt(dashboard?.id || 0) != id) {
      refetch({ dashboardId: parseInt(id as string) });
      return [true, dashboard] as FetchBoardTuple;
    }
    return [loading, dashboard] as FetchBoardTuple;
  }

  async function updateBoard(id: ID, input: Partial<getDashboards_dashboards>) {
    await updateDashboardMutation({
      variables: {
        id,
        input: input,
      },
      optimisticResponse: {
        updateDashboard: {
          dashboard: { ...data, ...input },
        },
      },
    });
  }

  // TODO: this is not safe, need to move to a transaction on the backend
  // If the code below fails it will produce unexpected results
  async function copyBoard(boardToCopy: getDashboards_dashboards) {
    const newBoard = await createBoard('Copy of ' + boardToCopy.name, {
      insights: boardToCopy.insights,
    });
    const notes = boardToCopy.notes;
    if (loading) {
      return null;
    }
    const sortedNotes = _.sortBy<Sticky>(notes, 'themeId');

    let currentThemeId: ID | null = null;
    let newThemeId: ID | null = null;

    const tasks: Promise<any>[] = [];

    for (const note of sortedNotes) {
      if (note.theme) {
        if (note.theme.id != currentThemeId) {
          currentThemeId = note.theme.id;
          const newTheme = await createTheme(
            newBoard.id,
            {
              name: note.theme.name,
              x: note.theme.x,
              y: note.theme.y,
              color: note.theme.color,
            },
            []
          );
          newThemeId = newTheme.id;
        }
      }

      tasks.push(
        createNote(newBoard.id, {
          x: note.x,
          y: note.y,
          color: note.color,
          text: note.text,
          sentimentScore: note.sentimentScore,
          themeId: note.theme ? newThemeId : null,
          tags: {
            create: note.tagsByItemId?.map((x: Tag) => ({
              itemType: 'note',
              name: x.name,
            })),
          },
        })
      );
    }

    await Promise.all(tasks);

    const copiedTranscripts: string[] = [];

    for (const file of boardToCopy.files ?? []) {
      if (file.transcription) {
        copiedTranscripts.push(file.transcription.id);
      }
      tasks.push(
        (async () => {
          const newFile = await createFile(newBoard.id, {
            name: file.name,
            type: file.type,
            s3VideoPath: file.s3VideoPath,
            s3AudioPath: file.s3AudioPath,
            status: file.status,
          });

          if (file.transcription) {
            await createTranscript(newBoard.id, {
              fileId: newFile.id,
              name: file.transcription.name,
              text: file.transcription.text,
            });
          }
        })()
      );
    }

    await Promise.all(tasks);

    for (const transcript of boardToCopy.transcriptions ?? []) {
      if (copiedTranscripts.includes(transcript.id)) {
        continue;
      }
      tasks.push(
        await createTranscript(newBoard.id, {
          name: transcript.name,
          text: transcript.text,
        })
      );
    }

    tasks.push(
      createProjectInfo({
        dashboardId: newBoard.id,
        name: boardToCopy.projectInfo?.name,
        description: boardToCopy.projectInfo?.description,
      })
    );

    await Promise.all(tasks);

    return newBoard;
  }

  return {
    copyBoard,
    createBoard,
    deleteBoard,
    fetchBoards,
    fetchBoard,
    updateBoard,
  };
}
