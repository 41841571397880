const colors = {
  primary: {
    purple: '#382152',
  },
  text: {
    purple: '#3B2651',
    placeholder: 'rgba(59, 38, 81, 0.6)',
    placeholder2: 'rgba(56, 33, 82, 0.4)',
  }
}

export default colors;
