import { gql, useMutation } from '@apollo/client';

const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($lookupKey: String!) {
    createCheckoutSession(input: { lookupKey: $lookupKey }) {
      checkoutUrl
    }
  }
`;

const CREATE_PORTAL_SESSION = gql`
  mutation createPortalSession {
    createPortalSession {
      portalUrl
    }
  }
`;

export function usePayments() {
  const [mutationCheckout] = useMutation(CREATE_CHECKOUT_SESSION);
  const [mutationPortal] = useMutation(CREATE_PORTAL_SESSION);

  async function createCheckoutSession(lookupKey: string) {
    const { data } = await mutationCheckout({
      variables: {
        lookupKey,
      },
    });

    return data.createCheckoutSession?.checkoutUrl;
  }

  async function createPortalSession() {
    const result = await mutationPortal();

    return result.data.createPortalSession?.portalUrl;
  }

  return {
    createCheckoutSession,
    createPortalSession,
  };
}
