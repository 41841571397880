import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Sticky } from '../Models';
import { uniqWith } from 'lodash';

import { FETCH_PARTICIPANTS, FETCH_PARTICIPANT } from '../GraphQL/queries';
import { CREATE_PARTICIPANT, UPDATE_PARTICIPANT, DELETE_PARTICIPANT } from '../GraphQL/mutations';

export const useFetchParticipants = (): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_PARTICIPANTS);
  return [loading, data?.participants || []];
}

export const useFetchParticipantsLazy = (): [() => void, { loading: boolean, called: boolean }, any[]] => {
  const [fetchParticipants, { loading, called, data }] = useLazyQuery(FETCH_PARTICIPANTS);
  return [fetchParticipants, { loading, called }, data?.participants || []];
}

export const useFetchParticipant = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_PARTICIPANT, {
    variables: { id },
  });
  return [loading, data?.participant];
}

export const useCreateParticipant = (): [(participant?: any) => any] => {
  const [createMutation] = useMutation(CREATE_PARTICIPANT);

  return [async (participant?: any) => {
    const res = await createMutation({
      variables: {
        input: {
          ...participant,
        },
      },
      refetchQueries: [
        {
          query: FETCH_PARTICIPANTS,
        },
      ],
    });
    return res?.data?.createParticipant?.participant;
  }];
}

export const useUpdateParticipant = (): [(id: string, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_PARTICIPANT);

  return [(id: string, input: any) => {
    return updateMutation({
      variables: {
        id,
        input: { ...input, updatedAt: new Date().toISOString() },
      },
      refetchQueries: [
        {
          query: FETCH_PARTICIPANT,
          variables: { id },
        },
      ]
    })
  }];
}

export const useDeleteParticipant = (): [(id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_PARTICIPANT);

  return [(id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: FETCH_PARTICIPANTS,
        },
      ]
    })
  }];
}

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;
function truthy<T>(value: T): value is Truthy<T> {
  return Boolean(value);
}

export const getFromStickies = (stickies: Sticky[]): { id: string, name: string }[] => {
  return uniqWith(stickies.map(sticky => sticky.participant).filter(truthy), (a, b) => a.id === b.id);
}


export default {
  useFetchParticipants,
  useFetchParticipant,
  useCreateParticipant,
  useUpdateParticipant,
  useDeleteParticipant,
  getFromStickies,
}

