import { useQuery } from '@apollo/client';
import { FETCH_ZOOM_CLOUD_RECORDINGS } from '../GraphQL/queries';
import { uniqBy } from 'lodash';

export type Meeting = {
  topic: string;
  start_time: string;
};

export type Response = {
  next_page_token: string;
  total_records: number;
  meetings: Meeting[];
};

export function useCloudRecordings(from: string, to: string) {
  const { data, loading, fetchMore } = useQuery(FETCH_ZOOM_CLOUD_RECORDINGS, {
    variables: { pageSize: 4, from, to },
  });

  async function fetchMoreCustom(nextPageToken: string) {
    if (!nextPageToken) {
      return '';
    }
    await fetchMore({
      variables: {
        continuationToken: nextPageToken,
      },
      updateQuery(prev: any, { fetchMoreResult }) {
        if (!prev) {
          return;
        }
        if (!fetchMoreResult) {
          return;
        }
        const r1 = prev?.zoomCloudRecordings;
        const r2 = fetchMoreResult?.zoomCloudRecordings;
        return {
          zoomCloudRecordings: {
            ...r1,
            ...r2,
            recordings: uniqBy([...r1.recordings, ...r2.recordings], 'shareUrl'),
          },
        };
      },
    });
  }

  return [loading, data?.zoomCloudRecordings, fetchMoreCustom];
}
