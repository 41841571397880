import React, { MouseEventHandler } from 'react';
import { Popover } from '@headlessui/react';
import { PhotographIcon } from '@heroicons/react/outline';

import Button from '../Button';
import { defaultCoverImages } from '../../Consts/coverImages';
import { CoverProps } from '../../Models';

type CoverPhotoPopoverProps = {
  classNames?: string;
  title: string;
  position: string;
  onImageClick: (image: CoverProps) => MouseEventHandler<HTMLDivElement>;
};

const CoverPhotoPopover = ({
  classNames,
  title,
  position,
  onImageClick,
}: CoverPhotoPopoverProps): JSX.Element => {
  return (
    <Popover className="relative">
      <Popover.Button className="">
        <Button className={classNames} type="secondary">
          <PhotographIcon className="w-4 h-4 mr-1" />
          {title}
        </Button>
      </Popover.Button>
      <Popover.Panel
        className={
          'absolute bg-white rounded-sm-md p-3' + (position === 'right' ? ' right-0' : ' left-0')
        }
        style={{
          width: '275px',
          boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #D7D3DC',
        }}
      >
        <div className="grid grid-cols-4 gap-2">
          {defaultCoverImages.map((image, index) => {
            return (
              <div
                key={`cover-image${index}`}
                className="hover:cursor-pointer"
                onClick={onImageClick(image)}
              >
                <img
                  src={image.url}
                  alt="cover image"
                  style={{
                    width: 56,
                    height: 56,
                    objectFit: 'cover',
                  }}
                />
              </div>
            );
          })}
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default CoverPhotoPopover;
