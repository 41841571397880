import React from 'react';
import Modal from './Modal';
import VideoPlayer from './VideoPlayer';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CalendarIcon, UserIcon, TagIcon, EmojiHappyIcon } from '@heroicons/react/outline';
import moment from 'moment';
import Avatar from './Avatar';
import Loader from './Loader';
import { Sticky, ID, Theme } from '../Models';
import { NoteInput } from '../GraphQL/__generated__/globalTypes';
import { useFetchFile } from '../Hooks/useFiles';
import ParticipantPicker from './ParticipantPicker';
import TagsPicker from '../Components/TagsPicker';
import { ThemeIcon } from '../Icons';
import SentimentPicker from './SentimentPicker';
import ThemePicker from './ThemePicker';

// interface Props extends ReactModal.Props {
//   title: string;
//   onClose?: () => void;
// }

const EditorDetailsRow = ({
  IconComponent,
  label,
  children,
}: React.PropsWithChildren<{
  IconComponent: React.ReactNode;
  label: string;
}>): JSX.Element => {
  return (
    <DetailsRow>
      <LabelWithIcon>
        <IconContainer>{IconComponent}</IconContainer>
        <Label>{label}</Label>
      </LabelWithIcon>
      {children}
    </DetailsRow>
  );
};

const FileViewer = ({
  fileId,
  timeOffset,
}: {
  fileId: string;
  timeOffset: number;
}): JSX.Element => {
  const [loading, file] = useFetchFile(fileId);
  if (loading) return <Loader />;
  return <VideoPlayer src={file?.signedVideoUrl} timeOffset={timeOffset} />;
};

export default function StickyModal({
  sticky,
  onClose,
  updateNote,
  updateTags,
  themes,
}: {
  sticky: Sticky;
  onClose: () => void;
  updateNote: (stickyId: ID, input: NoteInput) => Promise<Sticky>;
  updateTags: (stickyId: ID, tags: string[] | null) => Promise<void>;
  themes: Theme[];
}): JSX.Element {
  const options = sticky.options ? JSON.parse(sticky.options) : {};
  return (
    <Modal
      title={''}
      isOpen={true}
      style={{ overlay: { zIndex: 1000 }, content: { maxWidth: '700px' } }}
      onClose={onClose}
    >
      {sticky.transcription?.file?.id && (
        <div className="mb-4">
          <FileViewer
            fileId={sticky.transcription?.file?.id}
            timeOffset={options?.timeOffset || 0}
          />
        </div>
      )}
      <Details>
        <TitleInput
          defaultValue={sticky.text}
          onBlur={async (e) => await updateNote(sticky.id, { text: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
          placeholder="Add text"
          className={'text-3xl mb-2 w-full font-medium'}
          autoFocus={false}
        />

        <EditorDetailsRow label="Theme" IconComponent={<ThemeIcon height="24" width="24" />}>
          <ThemePicker
            themes={themes}
            value={sticky.theme}
            onChange={async (value) => {
              await updateNote(sticky.id, { themeId: value });
            }}
            sticky={sticky}
          />
        </EditorDetailsRow>
        <EditorDetailsRow label="Tags" IconComponent={<TagIcon />}>
          <Placeholder>
            <TagsPicker
              tags={sticky.tagsByItemId.map((tag: { name: string }) => tag.name)}
              onChange={async (tags) => {
                await updateTags(sticky.id, tags);
              }}
              dashboardId={sticky.dashboardId}
            />
          </Placeholder>
        </EditorDetailsRow>
        <EditorDetailsRow label="Participant" IconComponent={<UserIcon />}>
          <ParticipantPicker
            participant={sticky.participant}
            onChange={async (participantId: string | null) =>
              await updateNote(sticky.id, { participantId })
            }
          />
        </EditorDetailsRow>
        <EditorDetailsRow label="Sentiment" IconComponent={<EmojiHappyIcon />}>
          <SentimentPicker
            sentimentScore={sticky.sentimentScore}
            onChange={async (score) => {
              await updateNote(sticky.id, { sentimentScore: score });
            }}
          />
        </EditorDetailsRow>
        <EditorDetailsRow label="Date" IconComponent={<CalendarIcon />}>
          <div>
            <div>{moment(sticky.createdAt).format('MM/DD/YYYY')}</div>
          </div>
        </EditorDetailsRow>
        <EditorDetailsRow label="Created by" IconComponent={<UserIcon />}>
          <div>
            <Avatar user={sticky.userByCreatedBy} />
            <UserName>{sticky.userByCreatedBy?.name}</UserName>
          </div>
        </EditorDetailsRow>
      </Details>
    </Modal>
  );
}

const TitleInput = styled(TextareaAutosize)`
  font-size: 24px;
  outline: none;
  transition: 0.3s ease 0s;
`;
interface DetailsProps {
  readonly maxWidth?: number;
}
const Details = styled.div<DetailsProps>`
  padding-bottom: 16px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + 'px' : '800px')};
  margin: auto;
`;

const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
`;

const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  margin: 4px 8px 4px 0;
`;

const UserName = styled.span`
  margin-left: 8px;
`;

const Placeholder = styled.div`
  color: ${(props) => props.theme.colors.text.placeholder2};
  cursor: pointer;
`;
