import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';
import { TrashIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import SecondaryToolbar from '../Components/SecondaryToolbar';
import InsightCoverPhoto from '../Components/InsightCoverPhoto';
import InsightEditor from '../Components/InsightEditor';
import Loader from '../Components/Loader';
import Button from '../Components/Button';
import {
  useFetchInsightByClientId,
  useUpdateInsightByClientId,
  useDeleteInsight,
} from '../Hooks/useInsights';

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const Delimiter = styled.div`
  margin: 0 8px;
`;

const InsightTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 336px;
  user-select: none;
`;

const Actions = styled.div`
  display: flex;
`;

export default function Insight(): JSX.Element {
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const { clientId } = useParams<{ clientId: string }>();

  const analytics = useSegment();
  const history = useHistory();

  useEffect(() => {
    analytics.page({
      name: 'Insight',
      properties: {
        projectId: dashboardId,
        insightClientId: clientId,
      },
    });
  }, []);

  const { loading, data } = useFetchInsightByClientId(clientId);

  const [updateInsightMutation] = useUpdateInsightByClientId();

  const [deleteInsightMutation] = useDeleteInsight();

  const updateInsight = async (input: any) => {
    await updateInsightMutation(clientId, input);
  };

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete the insight? This cannot be undone.`)) {
      return;
    }

    await deleteInsightMutation(dashboardId, clientId);

    analytics.track({
      event: 'DeleteInsight',
      properties: {
        projectId: dashboardId,
        insightClientId: clientId,
      },
    });
    history.push(`/projects/${dashboardId}/insights`);
  }

  if (loading) return <Loader />;

  return (
    <div>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <Breadcrumbs>
            <Link to={`/projects/${dashboardId}/insights`}>Insights</Link>
            <Delimiter>/</Delimiter>
            <InsightTitle>{data?.title || 'Untitled Insight'}</InsightTitle>
          </Breadcrumbs>
          <Actions>
            <Button onClick={() => handleDelete()} type={'secondary'}>
              <TrashIcon className={'w-4 h-4 mr-1'} />
              Delete
            </Button>
          </Actions>
        </div>
      </SecondaryToolbar>
      {!loading && (
        <InsightCoverPhoto data={data} isInsightsPanel={false} updateInsight={updateInsight} />
      )}
      <InsightEditor insight={data} onSave={updateInsight} isInsightsPanel={false} />
    </div>
  );
}
