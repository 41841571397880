import React from 'react'
import styled from 'styled-components';
import dataEmptyImage from '../assets/ribbon.png';
import Button from './Button';
import colors from '../Consts/colors';
import XIcon from '../Icons/XIcon';

const RibbonImage = styled.div<{ src: string; }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 40px;
  width: 360px;
`;

const Container = styled.div`
  padding: 10px;
  width: 360px;
`

const CloseContainer = styled.div`
  position: absolute;
  right: 4px;
  top: 9px;
  &:hover {
    cursor: pointer;
  };
`

const SuperHeader = styled.p`
  font-size: 14px;
  line-height: 18px;
`

const Header = styled.p`
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 10px;
`

const NewProjectButton = styled(Button)`
  width: 100%;
  font-size: 16px;
  margin-top: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  justify-content: center;
`

const ExploreLaterButton = styled(NewProjectButton)`
  position: absolute;
  margin-top: 60px;
  left: 0;
  color: ${colors.text.purple};
  background-color: unset;
  box-shadow: none;
`

interface Props {
  onCreate: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
}

const CreateProjectToolTip = ({ onCreate, onClose }: Props): JSX.Element => {
  return <div style={{ borderRadius: "6px" }}>
    <RibbonImage src={dataEmptyImage} />
    <CloseContainer onClick={onClose}>
      <XIcon stroke="#FFFDFB" />
    </CloseContainer>
    <Container>
      <SuperHeader>Tip 2 of 2</SuperHeader>
      <Header>Projects in Notably</Header>
      <iframe width="100%" src="https://www.youtube.com/embed/FqAlb58-KMc" allowFullScreen />
      <NewProjectButton onClick={onCreate}>Create a new project</NewProjectButton>
      <ExploreLaterButton onClick={onClose}>I’ll explore on my own</ExploreLaterButton>
    </Container>
  </div>
}

export default CreateProjectToolTip
