import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import {
  CogIcon,
  CurrencyDollarIcon,
  PuzzleIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import useFeatureFlags from '../Hooks/useFeatureFlags';

const SettingsSidebar = (): JSX.Element => {
  const match = useRouteMatch({
    path: '/settings',
    exact: false,
  });

  const { isFeatureEnabled } = useFeatureFlags();

  const navigation = [
    {
      name: 'General',
      href: '/general',
      icon: <CogIcon className={'w-6 h-6'} />,
    },
    {
      name: 'Profile',
      href: '/profile',
      icon: <UserIcon className={'w-6 h-6'} />,
    },
    {
      name: 'Plan and Billing',
      href: '/plans',
      icon: <CurrencyDollarIcon className={'w-6 h-6'} />,
    },
    { name: 'Team', href: '/users', icon: <UsersIcon className={'w-6 h-6'} /> },
  ];

  if (isFeatureEnabled('zoom')) {
    navigation.push({
      name: 'Integrations',
      href: '/integrations',
      icon: <PuzzleIcon className={'w-6 h-6'} />,
    });
  }

  return (
    <div className="flex flex-col w-56 border-r border-gray-200 nav-sidebar">
      <div className="flex-1 flex flex-col">
        <div className="p-3 text-lg font-medium">Settings</div>
        <nav className="flex-1 px-2 bg-white space-y-1">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              activeClassName="bg-secondary-purple-light"
              to={`${match?.url}${item.href}`}
              className={
                'hover:bg-secondary-purple-light group flex items-center px-2 py-2 text-sm font-medium rounded-md'
              }
            >
              <span className={'inline-flex pr-3'}>{item.icon}</span>
              {item.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default SettingsSidebar;
