import React, { ChangeEvent, Dispatch, MouseEvent, SetStateAction } from 'react';
import { PhotographIcon, TrashIcon } from '@heroicons/react/outline';

import Button from './Button';
import NotablyIcon from '../Icons/NotablyIcon';

type WorkspaceSettingsFormProps = {
  companyName?: string;
  currentPage: {
    header: string;
    id: string;
    inputLabel: string;
    placeholder: string;
    setValue: Dispatch<SetStateAction<string>>;
    stepNumber: number;
  };
  handleUpdateCompanyName?: (name: string) => void;
  loading: boolean;
  onLogoUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  onRemoveLogo: () => void;
  selectedLogo: string | null;
  updateCurrentPage?: (e: MouseEvent<HTMLElement>) => void;
};

const WorkspaceSettingsForm = ({
  companyName,
  currentPage,
  handleUpdateCompanyName,
  onLogoUpload,
  onRemoveLogo,
  selectedLogo,
}: WorkspaceSettingsFormProps): JSX.Element => {
  const { id, inputLabel, placeholder, setValue } = currentPage;
  return (
    <div>
      <div className="text-xl font-medium py-2">{'Workspace details'}</div>
      <div className="flex flex-col items-center">
        {!selectedLogo && (
          <div className="w-16 h-16 my-4 flex items-center justify-center bg-primary-purple/10">
            <NotablyIcon />
          </div>
        )}
        {selectedLogo && (
          <div className="my-4">
            <img
              src={selectedLogo}
              alt="cover image"
              style={{
                width: 64,
                height: 64,
                objectFit: 'cover',
              }}
            />
          </div>
        )}
        <div className="flex flex-row">
          <label
            className="bg-white text-secondary-purple-dark focus:ring-secondary-purple-dark border-gray-200 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2"
            htmlFor={'logo_upload'}
          >
            <PhotographIcon className="w-5 h-5 mr-1" />
            Change
          </label>
          <Button className="ml-5" type="secondary" onClick={onRemoveLogo}>
            <TrashIcon className="w-5 h-5 mr-1" />
            Remove
          </Button>
        </div>
        <input
          id={'logo_upload'}
          name={'logo_upload'}
          className="hidden"
          type="file"
          accept={'image/png, image/gif'}
          onChange={onLogoUpload}
        />
        <div className="text-xs py-3 text-primary-purple/40">{'square .gif, .png 3mb max'}</div>
      </div>

      <div className="pb-4">
        <label htmlFor="name" className="block text-sm font-medium">
          {inputLabel}
        </label>
        <div className="mt-1">
          <input
            id={id}
            name={id}
            defaultValue={companyName || ''}
            placeholder={placeholder}
            required
            onBlur={
              handleUpdateCompanyName ? (e) => handleUpdateCompanyName(e.target.value) : undefined
            }
            onChange={(e) => setValue(e.target.value)}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettingsForm;
