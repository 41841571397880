import React, { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Header2 from '../Components/Header2';
import { useOrganization } from '../Hooks/useOrganization';
import { StarIcon } from '@heroicons/react/solid';
import { useRouteMatch } from 'react-router-dom';
import { humanFileSize } from '../Utils/formatting';

type DefaultLayoutProps = {
  children: ReactNode;
};

function Banner({ children }: PropsWithChildren<any>) {
  return (
    <div className="bg-secondary-purple-dark">
      <div className="max-w-7xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-1">
              <StarIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p className="ml-3 text-white truncate">
              <span className="md:inline">{children}</span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <Link
              to="/settings/plans"
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-secondary-purple-dark bg-white hover:bg-indigo-50"
            >
              Upgrade plan
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function DefaultLayout({ children }: DefaultLayoutProps): JSX.Element {
  const [loading, organization] = useOrganization();

  const match = useRouteMatch<{ dashboardId: string }>('/projects/:dashboardId/notes');

  const paymentsEnabled = !match;
  const plan = organization?.plan || '';
  const upgradeAvailable = plan.toLowerCase().includes('free');
  if (loading) {
    return <></>;
  }

  let bannerMessage;
  if (organization && organization.id != 1) {
    if (
      organization.projectsLimitExceeded ||
      parseInt(organization.projectsLimitTotal) == parseInt(organization.projectsCount)
    ) {
      bannerMessage = `You've reached your limit of ${organization.projectsLimitTotal} projects in this workspace. Upgrade your plan for unlimited projects.`;
    }

    if (organization.durationLimitExceeded) {
      bannerMessage = `You've reached your limit of ${
        parseInt(organization.durationLimitTotal) / 60 / 60 ||
        parseInt(organization.durationLimitMonthly) / 60 / 50
      } transcription hours in this workspace. Upgrade your plan to get more transcription hours.`;
    }

    if (organization.fileLimitExceeded) {
      bannerMessage = `You've reached your limit of ${humanFileSize(
        organization.fileSizeLimitTotal,
        true
      )} file storage in this workspace. Upgrade your plan to get more storage.`;
    }
  }

  return (
    <div className={'min-h-full bg-white w-full flex flex-col'}>
      {paymentsEnabled && bannerMessage && <Banner>{bannerMessage}</Banner>}
      <Header2 showUpgradeBtn={upgradeAvailable} />
      {children}
    </div>
  );
}

export default DefaultLayout;
