import { CheckIcon, MinusIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import styled from 'styled-components';
import dataEmptyImage from '../assets/checklistribbon.png';
import colors from '../Consts/colors';
import { ExpandArrowIcon } from '../Icons';
import Button from './Button';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
} from '@chakra-ui/react';
import { OnboardingProgress } from '../Hooks/useOnboardingProgress';
import { useHistory } from 'react-router-dom';
import useBoards from '../Hooks/useBoards';
import { checkOrganizationLimits } from '../Hooks/useOrganization';
import { useSegment } from 'react-segment-hooks';
import { getDashboards_dashboards } from '../GraphQL/__generated__/getDashboards';

const ChecklistContainer = styled.div<{ minimized: boolean }>`
  position: fixed;
  right: 72px;
  bottom: 22px;
  width: 300px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #e5e5e5, 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 100;
  overflow: hidden;
  height: ${(props) => (props.minimized ? 137 : 452)}px;
  transition: height 0.3s;
`;

const ContentContainer = styled.div`
  padding: 10px;
`;

const RibbonImage = styled.div<{ src: string; height: number }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  height: ${(props) => props.height}px;
  width: 300px;
`;

const Header = styled.p`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: ${colors.text.purple};
  margin-top: 15px;
  margin-bottom: 10px;
`;

const SubHeader = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.text.purple};
`;

const AccordionHeader = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${colors.text.purple};
  padding-bottom: 10px;
  padding-top: 10px;
`;

const ProgressBarContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ProgressBarBackground = styled.div`
  width: 100%;
  height: 3px;
  background-color: #d7d3dc;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: calc(100% * ${(props) => props.progress});
  height: 3px;
  background-color: ${colors.text.purple};
  margin-top: -3px;
`;

const MinimizeContainer = styled.div`
  position: absolute;
  right: 4px;
  top: 9px;
  &:hover {
    cursor: pointer;
  }
`;

const ProgressIconContainer = styled.div`
  margin-right: 10px;
`;

const EmptyCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #d7d3dc;
`;

const ChecklistItemDescriptionContainer = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

interface ChecklistItem {
  header: string;
  description: string;
  cta: string;
  onCta: () => void;
  completed: boolean;
}

interface Props {
  org: any;
  dashboards: getDashboards_dashboards[];
  onboardingProgress: OnboardingProgress;
}

const AccountChecklist = ({ org, dashboards, onboardingProgress }: Props): JSX.Element => {
  // todo move all these data hooks to props to prevent duplicate fetching
  const history = useHistory();
  const analytics = useSegment();
  const [minimized, setMinimized] = useState(false);

  const { updateBoard, createBoard } = useBoards();
  const sampleDashboard = dashboards?.find((dashboard) => dashboard.isSample);

  const {
    hasOpenedSampleProject,
    hasCreatedProject,
    hasCustomLogo,
    hasInvitedTeammates,
  } = onboardingProgress;

  if (hasOpenedSampleProject && hasCreatedProject && hasCustomLogo && hasInvitedTeammates) {
    return <></>;
  }

  const checklistItems: ChecklistItem[] = [
    {
      header: 'View sample project',
      description: 'Get a feel for how Notably works with some sample data',
      cta: 'View project',
      onCta: async () => {
        if (sampleDashboard) {
          history.push(`/projects/${sampleDashboard.id}/info`);
          await updateBoard(sampleDashboard.id, {
            isVisited: true,
          });
        }
      },
      completed: hasOpenedSampleProject,
    },
    {
      header: 'Create your first project',
      description: 'Create a new project of your own',
      cta: 'Create project',
      onCta: async () => {
        if (!checkOrganizationLimits(org, history, ['projects'])) {
          return false;
        }

        const dashboard = await createBoard('');

        analytics.track({
          event: 'CreateProject',
          properties: {
            id: dashboard.id,
          },
        });

        history.push(`/projects/${dashboard.id}/info`);
      },
      completed: hasCreatedProject,
    },
    {
      header: 'Customize workspace',
      description: 'Upload your company logo to make the space your own',
      cta: 'Workspace details',
      onCta: () => history.push('/settings/general'),
      completed: hasCustomLogo,
    },
    {
      header: 'Invite teammates',
      description: 'Add your team to share your findings',
      cta: 'Invite',
      onCta: () => history.push('/settings/users'),
      completed: hasInvitedTeammates,
    },
  ];

  return (
    <ChecklistContainer minimized={minimized}>
      <RibbonImage
        src={dataEmptyImage}
        height={minimized ? 36 : 80}
        style={{ transition: 'height 0.3s' }}
      />
      <MinimizeContainer onClick={() => setMinimized(!minimized)}>
        {minimized ? <ExpandArrowIcon /> : <MinusIcon fill="white" width="20px" height="20px" />}
      </MinimizeContainer>
      <ContentContainer>
        <Header>Getting started checklist</Header>
        <SubHeader>
          {
            [hasOpenedSampleProject, hasCreatedProject, hasCustomLogo, hasInvitedTeammates].filter(
              (val) => val
            ).length
          }
          /4
        </SubHeader>{' '}
        {/*todo*/}
        <ProgressBarContainer>
          <ProgressBarBackground />
          <ProgressBar
            progress={
              [
                hasOpenedSampleProject,
                hasCreatedProject,
                hasCustomLogo,
                hasInvitedTeammates,
              ].filter((val) => val).length / 4
            }
          />
        </ProgressBarContainer>
        <Accordion
          defaultIndex={
            [
              hasOpenedSampleProject,
              hasCreatedProject,
              hasCustomLogo,
              hasInvitedTeammates,
            ].findIndex((val) => !val) ?? 0
          }
        >
          {checklistItems.map((item) => {
            return (
              <AccordionItem key={item.header} borderBottom="1px solid #D7D3DC">
                <AccordionHeader>
                  <AccordionButton w="100%">
                    <ProgressIconContainer>
                      {item.completed ? <CheckIcon width={24} /> : <EmptyCheckbox />}
                    </ProgressIconContainer>
                    {item.header}
                    <Spacer />
                    <AccordionIcon w={24} />
                  </AccordionButton>
                </AccordionHeader>
                <AccordionPanel pb={11} ml={34}>
                  <ChecklistItemDescriptionContainer>
                    {item.description}
                  </ChecklistItemDescriptionContainer>
                  <Button onClick={item.onCta} type="secondary">
                    {item.cta}
                  </Button>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </ContentContainer>
    </ChecklistContainer>
  );
};

export default AccountChecklist;
