import { gql } from '@apollo/client/core';
import {
  BOARD_CORE_FIELDS,
  FILE_CORE_FIELDS,
  NOTE_CORE_FIELDS,
  THEME_CORE_FIELDS,
  TRANSCRIPT_CORE_FIELDS,
  PROJECT_INFO_CORE_FIELDS,
  PROJECT_INSIGHT_CORE_FIELDS,
  DOCUMENT_CORE_FIELDS,
  PARTICIPANT_CORE_FIELDS,
  USER_CORE_FIELDS,
} from './fragments';

const RUN_SENTIMENT_ANALYSIS = gql`
  mutation runSentimentAnalysis($noteId: BigInt!) {
    runSentimentAnalysis(input: { noteId: $noteId }) {
      score
    }
  }
`;

const CREATE_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  mutation createDashboard($input: DashboardInput!) {
    createDashboard(input: { dashboard: $input }) {
      dashboard {
        ...BoardCoreFields
      }
    }
  }
`;

const UPDATE_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  mutation updateDashboard($id: BigInt!, $input: DashboardPatch!) {
    updateDashboard(input: { id: $id, patch: $input }) {
      dashboard {
        ...BoardCoreFields
      }
    }
  }
`;

const DELETE_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  mutation deleteDashboard($id: BigInt!) {
    deleteDashboard(input: { id: $id }) {
      dashboard {
        ...BoardCoreFields
      }
    }
  }
`;

const CREATE_NOTE = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation createNote($input: NoteInput!) {
    createNote(input: { note: $input }) {
      note {
        ...NoteCoreFields
        theme {
          ...ThemeCoreFields
          notes {
            ...NoteCoreFields
          }
        }
      }
    }
  }
`;

const DELETE_NOTE = gql`
  ${NOTE_CORE_FIELDS}
  mutation deleteNote($id: BigInt!) {
    deleteNote(input: { id: $id }) {
      note {
        ...NoteCoreFields
      }
    }
  }
`;

const DELETE_THEME = gql`
  mutation deleteTheme($id: BigInt!) {
    deleteTheme(input: { id: $id }) {
      theme {
        id
      }
    }
  }
`;

const UPDATE_NOTE = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation updateNote($id: BigInt!, $input: NotePatch!) {
    updateNote(input: { id: $id, patch: $input }) {
      note {
        ...NoteCoreFields
        theme {
          ...ThemeCoreFields
        }
      }
    }
  }
`;

const CREATE_THEME = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation createTheme($input: ThemeInput!) {
    createTheme(input: { theme: $input }) {
      theme {
        ...ThemeCoreFields
        notes {
          ...NoteCoreFields
        }
      }
    }
  }
`;

const UPDATE_THEME = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation updateTheme($id: BigInt!, $input: ThemePatch!) {
    updateTheme(input: { id: $id, patch: $input }) {
      theme {
        ...ThemeCoreFields
        notes {
          ...NoteCoreFields
        }
      }
    }
  }
`;

const CREATE_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  mutation createTranscription($input: TranscriptionInput!) {
    createTranscription(input: { transcription: $input }) {
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const UPDATE_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  mutation updateTranscript($id: BigInt!, $input: TranscriptionPatch!) {
    updateTranscription(input: { id: $id, patch: $input }) {
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const DELETE_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  mutation deleteTranscription($id: BigInt!) {
    deleteTranscription(input: { id: $id }) {
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const CREATE_SIGNED_S3_URL = gql`
  mutation createSignedS3Url($fileType: String!, $fileName: String!, $isPublic: Boolean) {
    createSignedS3Url(input: { fileType: $fileType, fileName: $fileName, isPublic: $isPublic }) {
      uploadUrl
    }
  }
`;

const CREATE_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation createFile($input: FileInput!) {
    createFile(input: { file: $input }) {
      file {
        ...FileCoreFields
      }
    }
  }
`;

const IMPORT_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation importFile($dashboardId: BigInt!, $name: String!, $importUrl: String!, $status: String) {
    importFile(
      input: {
        dashboardId: $dashboardId
        name: $name
        importUrl: $importUrl
        type: "mp4"
        status: $status
      }
    ) {
      file {
        ...FileCoreFields
      }
      created
    }
  }
`;

const UPDATE_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation updateFile($id: BigInt!, $input: FilePatch!) {
    updateFile(input: { id: $id, patch: $input }) {
      file {
        ...FileCoreFields
      }
    }
  }
`;

const DELETE_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation deleteFile($id: BigInt!) {
    deleteFile(input: { id: $id }) {
      file {
        ...FileCoreFields
      }
    }
  }
`;

const CREATE_PROJECT_INFO = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  mutation createProjectInfo($input: ProjectInfoInput!) {
    createProjectInfo(input: { projectInfo: $input }) {
      projectInfo {
        ...ProjectInfoCoreFields
      }
    }
  }
`;

const UPDATE_PROJECT_INFO = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  mutation updateProjectInfo($id: BigInt!, $input: ProjectInfoPatch!) {
    updateProjectInfo(input: { id: $id, patch: $input }) {
      projectInfo {
        ...ProjectInfoCoreFields
      }
    }
  }
`;

const REGISTER = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      success
    }
  }
`;

const CREATE_PROJECT_INSIGHT = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  mutation createProjectInsight($input: ProjectInsightInput!) {
    createProjectInsight(input: { projectInsight: $input }) {
      projectInsight {
        ...ProjectInsightCoreFields
      }
    }
  }
`;

const UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  mutation updateProjectInsightByClientId($clientId: String!, $input: ProjectInsightPatch!) {
    updateProjectInsightByClientId(input: { clientId: $clientId, patch: $input }) {
      projectInsight {
        ...ProjectInsightCoreFields
      }
    }
  }
`;

const DELETE_INSIGHT_BY_CLIENT_ID = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  mutation deleteProjectInsightByClientId($clientId: String!) {
    deleteProjectInsightByClientId(input: { clientId: $clientId }) {
      projectInsight {
        ...ProjectInsightCoreFields
      }
    }
  }
`;

const AUTHORIZE_ZOOM = gql`
  mutation authorizeZoom($code: String!, $redirectUri: String!) {
    authorizeZoom(input: { code: $code, redirectUri: $redirectUri }) {
      success
    }
  }
`;

const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($id: BigInt!) {
    deleteIntegration(input: { id: $id }) {
      integration {
        id
      }
    }
  }
`;

const CREATE_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  mutation createDocument($input: DocumentInput!) {
    createDocument(input: { document: $input }) {
      document {
        ...DocumentCoreFields
      }
    }
  }
`;

const UPDATE_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  mutation updateDocument($id: BigInt!, $input: DocumentPatch!) {
    updateDocument(input: { id: $id, patch: $input }) {
      document {
        ...DocumentCoreFields
      }
    }
  }
`;

const DELETE_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  mutation deleteDocument($id: BigInt!) {
    deleteDocument(input: { id: $id }) {
      document {
        ...DocumentCoreFields
      }
    }
  }
`;

const CREATE_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  mutation createParticipant($input: ParticipantInput!) {
    createParticipant(input: { participant: $input }) {
      participant {
        ...ParticipantCoreFields
      }
    }
  }
`;

const UPDATE_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  mutation updateParticipant($id: BigInt!, $input: ParticipantPatch!) {
    updateParticipant(input: { id: $id, patch: $input }) {
      participant {
        ...ParticipantCoreFields
      }
    }
  }
`;

const DELETE_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  mutation deleteParticipant($id: BigInt!) {
    deleteParticipant(input: { id: $id }) {
      participant {
        ...ParticipantCoreFields
      }
    }
  }
`;

const INVITE_USER = gql`
  mutation inviteUser($email: String!) {
    invite(input: { email: $email }) {
      success
    }
  }
`;

const UPDATE_USER = gql`
  ${USER_CORE_FIELDS}
  mutation updateUser($id: BigInt!, $input: UserPatch!) {
    updateUser(input: { id: $id, patch: $input }) {
      user {
        ...UserCoreFields
      }
    }
  }
`;

const DELETE_USER = gql`
  ${USER_CORE_FIELDS}
  mutation deleteUser($id: BigInt!) {
    deleteUser(input: { id: $id }) {
      user {
        ...UserCoreFields
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      success
    }
  }
`;

export {
  UPDATE_USER,
  DELETE_USER,
  INVITE_USER,
  AUTHORIZE_ZOOM,
  DELETE_INTEGRATION,
  CREATE_SIGNED_S3_URL,
  CREATE_TRANSCRIPT,
  UPDATE_TRANSCRIPT,
  CREATE_NOTE,
  UPDATE_NOTE,
  CREATE_THEME,
  UPDATE_THEME,
  CREATE_DASHBOARD,
  UPDATE_DASHBOARD,
  DELETE_DASHBOARD,
  DELETE_NOTE,
  DELETE_THEME,
  RUN_SENTIMENT_ANALYSIS,
  CREATE_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  DELETE_TRANSCRIPT,
  CREATE_PROJECT_INFO,
  UPDATE_PROJECT_INFO,
  REGISTER,
  CREATE_PROJECT_INSIGHT,
  UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID,
  DELETE_INSIGHT_BY_CLIENT_ID,
  IMPORT_FILE,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  CREATE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  DELETE_PARTICIPANT,
  UPDATE_ORGANIZATION,
};
