import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { useInterval, useEffectOnce } from 'usehooks-ts';
import { flatten, uniq } from 'lodash';
import { Link } from 'react-router-dom';

import { EditorBlock, RawDraftContentState, ContentBlock, ContentState } from 'draft-js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DraftailEditor } from 'draftail';
import { formatTimestamp } from 'subtitle';
import { useParams } from 'react-router-dom';
import useNotes from '../Hooks/useNotes';
import { useHistory } from 'react-router';
import useTranscripts from '../Hooks/useTranscripts';
import { Loader, Modal } from '../Components';
import Button from '../Components/Button';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Well from '../Components/Well';
import { Sticky } from '../Models';
import { toast } from 'react-toastify';
import Avatar from '../Components/Avatar';
import HighlightIcon from '../Icons/HighlightIcon';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import InlineToolbarButtons from '../Components/InlineToolbarButtons';
import Highlight from '../Components/Highlight';
import TagsPanel from '../Components/TagsPanel';

import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import {
  CalendarIcon,
  TagIcon,
  TrashIcon,
  UserIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import useFiles from '../Hooks/useFiles';
import { useSegment } from 'react-segment-hooks';
import TextareaAutosize from 'react-textarea-autosize';
import Input from '../Components/Input';
import ParticipantPicker from '../Components/ParticipantPicker';
import VideoPlayer from '../Components/VideoPlayer';
import AudioPlayer from '../Components/AudioPlayer';
import TagsPicker from '../Components/TagsPicker';

const plugin = createInlineToolbarPlugin();
const { InlineToolbar } = plugin;

const beautifyTimestamp = (timestamp: number): string => {
  return formatTimestamp(timestamp).split(',')[0];
};

const highlightEntity = {
  type: 'HIGHLIGHT',
  decorator: Highlight,
};

const inlineStyles = [
  {
    // label: 'Highlight note',
    type: 'NOTE',
    icon: (
      <div className={'leading-5 flex'}>
        <div className={'-bm-2 mr-2'}>
          <HighlightIcon />
        </div>
        Highlight note
      </div>
    ),
    style: {
      padding: '1px',
      borderRadius: '2px',
      backgroundColor: 'rgba(10, 95, 168, 0.2)',
    },
  },
];

function CustomBlock(props: any) {
  const data = props.block.getData();

  function openModal(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    e.stopPropagation();
    startEditing();
  }

  function stopEditing() {
    setEditing(false);
    props.blockProps?.onStopEditing?.();
  }

  function startEditing() {
    setEditing(true);
    props.blockProps?.onStartEditing?.();
  }

  async function updateName() {
    if (name != data.get('participantName')) {
      await props.blockProps?.onNameChange?.(data.get('participantName'), name);
    }
    stopEditing();
    // Fix me when I figure out what is going on.
    window.location.reload();
  }

  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(data.get('participantName'));

  return (
    <div className={'py-4'}>
      <Modal
        onClose={() => {
          stopEditing();
        }}
        title="Update Participants Name"
        isOpen={editing}
      >
        <div className={'py-3'}>
          <Input
            onChange={(e) => setName(e.target?.value ?? '')}
            type={'text'}
            defaultValue={data.get('participantName')}
          />
        </div>
        <Button onClick={updateName}>Save</Button>
      </Modal>
      <div className={'text-sm pb-2 flex items-center'}>
        <a
          href={'#'}
          onClick={openModal}
          className={'text-secondary-purple-dark font-bold flex items-center'}
        >
          <Avatar user={{ picture: data.get('participantName') }} />
          <div className="px-1">{data.get('participantName')}</div>
        </a>
        <time
          className={'pl-1 text-gray-400 select-none cursor-pointer'}
          onClick={() => {
            const start = Math.floor(data.get('start') / 1000);

            if (!Number.isInteger(start)) return;
            props.blockProps?.setTimeOffset(start);
          }}
        >
          {beautifyTimestamp(data.get('start'))} &mdash; {beautifyTimestamp(data.get('end'))}
        </time>
      </div>
      <div className={'leading-6'}>
        <EditorBlock {...props} />
      </div>
    </div>
  );
}

export default function Transcript(): JSX.Element {
  const { dashboardId, transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();

  const [editorState, setEditorState] = useState<RawDraftContentState | null>(null);

  const [readOnly, setReadOnly] = useState(false);

  const { fetchTranscript, updateTranscript, deleteTranscript } = useTranscripts();

  const { updateFile, deleteFile } = useFiles();

  const { createNotesFromDraftState, fetchNotesByTranscriptId, deleteNote } = useNotes(dashboardId);
  const history = useHistory();

  const analytics = useSegment();

  const [timeOffset, setTimeOffset] = useState<number | null>(null);

  useEffect(() => {
    analytics.page({
      name: 'Transcript',
      properties: {
        id: transcriptId,
        projectId: dashboardId,
      },
    });
  });

  useEffectOnce(() => {
    localStorage.removeItem('tagsPanel');
  });

  const onSave = async (state: RawDraftContentState) => {
    setEditorState(state);
    await updateTranscript(transcriptId, {
      text: JSON.stringify(state),
    });
  };

  async function updateTags(tags: Array<string> | null) {
    updateTranscript(transcriptId, {
      tags,
    });
  }

  const [loading, transcript, refetch] = fetchTranscript(transcriptId);

  useInterval(
    () => {
      if (loading || !transcript) {
        return;
      }
      if (transcript?.file?.status !== 'READY') {
        refetch();
      }
    },
    // Delay in milliseconds or null to stop it
    transcript?.file?.status === 'READY' ? null : 3000
  );

  useEffect(() => {
    if (editorState) {
      return;
    }
    if (transcript && transcript.text && !loading) {
      const rawState = JSON.parse(transcript.text);
      if (rawState.blocks?.length > 0) {
        setEditorState(rawState);
      }
    }
  }, [loading, transcript, editorState]);

  async function createNotes() {
    if (editorState) {
      const toastId = toast.loading('Creating notes from highlighted text...');

      const notes = await fetchNotesByTranscriptId(dashboardId, transcriptId);

      if (
        notes.length > 0 &&
        !confirm(
          `Do you want to overwrite ${notes.length} notes created previously from this transcript`
        )
      ) {
        toast.update(toastId, {
          render: 'Creation cancelled',
          isLoading: false,
          type: 'info',
          autoClose: 1000,
        });
        return;
      }

      await Promise.all(notes.map((x: Sticky) => deleteNote(x.id)));

      await createNotesFromDraftState(editorState, {
        transcriptId,
        participantId: transcript.participantId,
        tags: transcript.tags,
      });

      toast.update(toastId, {
        render: 'Successfully created notes from transcript!',
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      });

      history.push('/projects/' + dashboardId + '/notes');
    }
  }

  async function onNameChange(oldName: string, newName: string) {
    if (!editorState) {
      return;
    }
    const newBlocks = editorState?.blocks.map((x) => {
      return {
        ...x,
        data: {
          ...x.data,
          participantName: x.data?.participantName === oldName ? newName : x.data?.participantName,
        },
      };
    });
    await onSave({ ...editorState, blocks: newBlocks ?? [] });
  }

  function renderAtomicBlock(block: ContentBlock) {
    if (block.getType() == 'atomic') {
      return {
        component: CustomBlock,
        editable: true,
        props: {
          onNameChange,
          onStartEditing: () => setReadOnly(true),
          onStopEditing: () => setReadOnly(false),
          setTimeOffset,
        },
      };
    }
  }

  async function updateName(newName: string) {
    if (newName === transcript.name) {
      return;
    }
    await updateTranscript(transcriptId, {
      name: newName,
    });
    if (!transcript.fileId) {
      return;
    }
    await updateFile(transcript.fileId, {
      name: newName,
    });
  }

  async function updateParticipant(participantId: string | null) {
    await updateTranscript(transcriptId, {
      participantId,
    });
  }

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete "${transcript.name}"? This cannot be undone.`)) {
      return;
    }
    await deleteTranscript(transcriptId);
    if (transcript.fileId) {
      await deleteFile(transcript.fileId);
    }

    analytics.track({
      event: 'DeleteTranscript',
      properties: {
        id: transcriptId,
      },
    });

    history.goBack();
  }

  if (loading || !transcript) {
    return <Loader />;
  }

  const createEnabled =
    editorState?.blocks && editorState?.blocks.findIndex((x) => x.entityRanges?.length > 0) != -1;

  const inlineTags: string[] = editorState
    ? uniq(
        flatten(
          Object.keys(editorState.entityMap).map(
            (entityKey) => editorState.entityMap[entityKey].data.tags || []
          )
        )
      )
    : [];

  return (
    <div className={'flex-col h-full'}>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>
              <Link to={`/projects/${dashboardId}/data`}>Data</Link> /{' '}
              {transcript.name ?? 'Untitled Transcript'}
            </h1>
          </div>
          <div className={'flex'}>
            <Button
              className={'mr-2 flex-inline'}
              onClick={() => handleDelete()}
              type={'secondary'}
            >
              <TrashIcon className={'w-4 h-4 mr-1'} />
              Delete
            </Button>
            <Button
              className={createEnabled ? '' : 'pointer-events-none opacity-75'}
              onClick={createNotes}
            >
              Add highlights as notes
            </Button>
          </div>
        </div>
      </SecondaryToolbar>
      <EditorContainer>
        <EditorLeftPanel>
          <Well wellKey="transcript-record-well">
            Your data was added below. Click and drag on the text to highlight the content you want
            to turn into notes.
          </Well>
          {transcript.file?.signedVideoUrl && transcript.file.type === 'video' && (
            <VideoPlayer src={transcript.file?.signedVideoUrl} timeOffset={timeOffset} />
          )}
          {transcript.file?.signedVideoUrl && transcript.file.type === 'audio' && (
            <AudioPlayer src={transcript.file?.signedVideoUrl} srcType={transcript.file.mimeType} />
          )}

          <Details>
            <NameInput
              defaultValue={transcript?.name}
              onBlur={(e) => updateName(e.target.value)}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              placeholder="Untitled"
              className={'text-3xl my-6 w-full'}
              autoFocus={!transcript?.name}
            />
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Created by</Label>
              </LabelWithIcon>
              <div className="flex items-center">
                <Avatar user={transcript?.userByCreatedBy} />
                <UserName>{transcript?.userByCreatedBy.name}</UserName>
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Participant</Label>
              </LabelWithIcon>
              <div>
                <ParticipantPicker
                  participant={transcript.participant}
                  onChange={updateParticipant}
                />
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <CalendarIconI />
                <Label>Date</Label>
              </LabelWithIcon>
              <div>
                <div>{moment(transcript?.createdAt).format('MM/DD/YYYY')}</div>
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <TagIconI />
                <Label>Tags</Label>
              </LabelWithIcon>
              <div>
                <TagsPicker
                  tags={transcript.tags}
                  onChange={updateTags}
                  dashboardId={dashboardId}
                />
              </div>
            </DetailsRow>
          </Details>
          {transcript?.file?.status === 'PROCESSING' && (
            <TranscriptionStatus>
              <ReactLoading type={'spin'} color={'#382152'} height={'14px'} width={'14px'} />
              <TranscriptionStatusText>Transcribing...</TranscriptionStatusText>
            </TranscriptionStatus>
          )}
          {transcript?.file?.status === 'ERRORED' && (
            <TranscriptionStatus>
              <ExclamationCircleIcon className={'w-6 h-6 text-red-500'} />
              <TranscriptionStatusText>
                Unable to transcribe video. Re-upload the file to try again.
              </TranscriptionStatusText>
            </TranscriptionStatus>
          )}
          {editorState && (
            <>
              <DraftailEditor
                readOnly={readOnly}
                topToolbar={null}
                editorKey="CustomInlineToolbarEditor"
                rawContentState={editorState || null}
                onSave={onSave}
                inlineStyles={inlineStyles}
                entityTypes={[{ ...highlightEntity }]}
                plugins={[
                  {
                    blockRendererFn: renderAtomicBlock,
                  },
                  plugin,
                ]}
                placeholder="Paste your transcript here..."
              />
              <InlineToolbar>
                {(props) => (
                  <InlineToolbarButtons
                    dashboardId={dashboardId}
                    inlineTags={inlineTags}
                    {...props}
                  />
                )}
              </InlineToolbar>
            </>
          )}
        </EditorLeftPanel>
        <EditorRightPanel>
          <TagsPanel entityMap={editorState?.entityMap} />
        </EditorRightPanel>
      </EditorContainer>
    </div>
  );
}

const EditorContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const EditorLeftPanel = styled.div`
  margin-top: 30px;
  width: 800px;
  margin-left: 48px;
`;
const EditorRightPanel = styled.div`
  border-left: 1px solid #e5e5e5;
  min-width: 200px;
  min-height: calc(100vh - 108px);
  margin-left: 48px;
`;

const NameInput = styled(TextareaAutosize)`
  outline: none;
  transition: 0.3s ease 0s;
`;

const Details = styled.div`
  padding: 0 12px;
`;

const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
`;

const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

const UserIconI = styled(UserIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;
const CalendarIconI = styled(CalendarIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

const TagIconI = styled(TagIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

const UserName = styled.span`
  margin-left: 8px;
`;

const TranscriptionStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 16px;
`;

const TranscriptionStatusText = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(56, 33, 82, 0.8);
`;
