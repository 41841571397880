import React from 'react';

export function ItalicIcon(): JSX.Element {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9285 9H13.9285L11.7285 19H9.72852L11.9285 9ZM13.6085 5C13.4107 5 13.2174 5.05865 13.0529 5.16853C12.8885 5.27841 12.7603 5.43459 12.6846 5.61732C12.6089 5.80004 12.5891 6.00111 12.6277 6.19509C12.6663 6.38907 12.7616 6.56725 12.9014 6.70711C13.0413 6.84696 13.2194 6.9422 13.4134 6.98079C13.6074 7.01937 13.8085 6.99957 13.9912 6.92388C14.1739 6.84819 14.3301 6.72002 14.44 6.55557C14.5499 6.39112 14.6085 6.19778 14.6085 6C14.6085 5.73478 14.5032 5.48043 14.3156 5.29289C14.1281 5.10536 13.8737 5 13.6085 5Z" fill="#382152" />
    </svg>
  );
}

export function UnderlinedIcon(): JSX.Element {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.1689 20V22H5.16895V20H19.1689ZM16.1689 13.215C16.136 13.875 15.9401 14.5165 15.5987 15.0823C15.2573 15.6481 14.781 16.1205 14.2125 16.4572C13.6439 16.794 13.0008 16.9847 12.3406 17.0122C11.6803 17.0397 11.0236 16.9033 10.4289 16.615C9.74358 16.3185 9.16235 15.8241 8.75971 15.1952C8.35707 14.5663 8.15137 13.8315 8.16895 13.085V5.005H6.16895V13.215C6.20278 14.1564 6.4578 15.0766 6.91336 15.9012C7.36893 16.7257 8.01224 17.4314 8.79121 17.9611C9.57019 18.4908 10.4629 18.8296 11.3972 18.9502C12.3315 19.0707 13.281 18.9696 14.1689 18.655C15.3501 18.2613 16.3748 17.5012 17.0942 16.485C17.8136 15.4689 18.1901 14.2498 18.1689 13.005V5.005H16.1689V13.215ZM16.1689 5H18.1689H16.1689ZM8.16895 5H6.16895H8.16895Z" fill="#382152" />
    </svg>
  );
}

export function UnorderedListIcon(): JSX.Element {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.66895 6V8H21.6689V6H7.66895ZM7.66895 18H21.6689V16H7.66895V18ZM7.66895 13H21.6689V11H7.66895V13Z" fill="#382152" />
      <circle cx="4.25265" cy="7.01827" r="1.01827" fill="#382152" />
      <circle cx="4.25265" cy="12" r="1.01827" fill="#382152" />
      <circle cx="4.25265" cy="16.9814" r="1.01827" fill="#382152" />
    </svg>
  );
}

export function OrderedListIcon(): JSX.Element {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66895 16H4.66895V16.5H3.66895V17.5H4.66895V18H2.66895V19H5.66895V15H2.66895V16ZM3.66895 9H4.66895V5H2.66895V6H3.66895V9ZM2.66895 11H4.46895L2.66895 13.1V14H5.66895V13H3.86895L5.66895 10.9V10H2.66895V11ZM7.66895 6V8H21.6689V6H7.66895ZM7.66895 18H21.6689V16H7.66895V18ZM7.66895 13H21.6689V11H7.66895V13Z" fill="#382152" />
    </svg>
  );
}
